import { createSlice } from '@reduxjs/toolkit';
const queryParams = new URLSearchParams(window.location.search);
const urlcoords = queryParams.has('address')
  ? JSON.parse(queryParams.get('address'))
  : undefined;

// export const fetchCoords = createAsyncThunk('coords/fetchCoords', async (address) => {
//     const isAllDigits = /^\d+$/.test(address);
//     const url = isAllDigits
//         ? `https://nominatim.openstreetmap.org/search?format=json&postalcode=${address}&country=us`
//         : `https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&q=${address}`;

//     const response = await fetch(url);

//     if (!response.ok) {
//         throw new Error('Network response was not ok');
//     }

//     const data = await response.json();
//     if (data.length !== 0) {
//         if (address === data[0].display_name) {
//             return { lat: Number(data[0].lat), lng: Number(data[0].lon) };
//         }
//     }
// });

const coordsSlice = createSlice({
  name: 'coords',
  initialState: { coordinates: urlcoords },
  reducers: {
    logCoords: (state, action) => {
      state.coordinates = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //     builder.addCase(fetchCoords.fulfilled, (state, action) => {
  //         state.coordinates = action.payload;
  //         console.log('action.payload2: ', action.payload);
  //     });
  // },
});

export const coordsActions = coordsSlice.actions;
export default coordsSlice.reducer;
