import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';

export function LocationPin({ number, isActive, isYou }) {
  const [active, setActive] = useState(isActive);

  return (
    <div
      className="location-pin"
      onClick={() => {
        setActive(!active);
      }}
    >
      <div className={isYou ? 'location-pin__you' : 'location-pin__number'}>
        {number}
      </div>
      <IonIcon
        name="location"
        className={
          isYou
            ? 'location-pin__yourIcon'
            : `location-pin__icon ${active && 'location-pin__icon--active'}`
        }
      />
    </div>
  );
}

LocationPin.propTypes = {
  /**
   * What is the initial state of the icon, or what state would you like to pass in?
   */
  isActive: PropTypes.bool,
  /**
   * Which number pin is this?
   */
  number: PropTypes.number,

  isYou: PropTypes.bool,
};

LocationPin.defaultProps = {
  number: 1,
  isActive: false,
  isYou: false,
};
