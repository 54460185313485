import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import filters from './filters.json';
import { useDispatch, useSelector } from 'react-redux';
import { currentPageActions } from '../../store/currentPage-slice';
import { filterInputsActions } from '../../store/filterInput-slice';
import { useSearchParams } from 'react-router-dom';
import { SwipeableDrawer } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from 'react-responsive';
import { Button } from '..';
import Cookies from 'universal-cookie';
import { distanceActions } from '../../store/distance-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { coordsActions } from '../../store/coords-slice';
import axios from '../../apis/backend';
import { useTranslation } from 'react-i18next';

export function FilterPanel({
  setEditingFilters,
  editingFilters,
  toggleDrawer,
  setIsLoading,
  setIsSearching,
  setAndTags,
  andTags,
}) {
  // TOREVIEW: Need Cookies here?
  // const cookies = new Cookies();
  // const currentCoords = cookies.get('location');
  // const locationPermissionGranted = !!cookies.get('locationPermissionGranted');

  const locationCoords = useSelector((state) => state.coords.coordinates);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterInputs = useSelector((state) => state.filterInputs.filters);
  const [checked, setChecked] = useState({});
  const enteredTerm = useSelector((state) => state.enteredTerm.input);
  const enteredLocation = useSelector((state) => state.enteredLocation.input);
  const [allTags, setAllTags] = useState(andTags);

  const initialFilters = [];
  useEffect(() => {
    Object.keys(filters).forEach((key) => {
      filters[key].options.forEach((key) => {
        initialFilters.push(key.name);
      });
    });
    const initialFilterState = Object.fromEntries(
      initialFilters.map((prop) => [prop, false])
    );
    initialFilterState.Distance = 'any';
    // TODO: Does this not do anything?
    // if (searchParams.has('tag')) {
    //   const arr = searchParams.get('tag').split(',');
    // }

    FilterPanel.propTypes = {
      setEditingFilters: PropTypes.func.isRequired,
      editingFilters: PropTypes.bool.isRequired,
      toggleDrawer: PropTypes.func.isRequired,
      setIsLoading: PropTypes.func.isRequired,
      setIsSearching: PropTypes.func.isRequired,
      setAndTags: PropTypes.func.isRequired,
      andTags: PropTypes.bool.isRequired,
    };
    if (searchParams.has('distance')) {
      initialFilterState.Distance = searchParams.get('distance');
    }
    if (searchParams.has('verifier')) {
      initialFilterState.Verifier = searchParams.get('verifier').split(',');
    }

    setChecked(initialFilterState);
    getVerifiers();
  }, []);

  const handleChange = (event) => {
    const name = isMobile ? event.target.innerText : event.target.name;
    const newChecked = { ...checked, [name]: !checked[name] };
    setChecked(newChecked);
  };

  function clearFilters() {
    const updated = { ...filterInputs };
    Object.keys(updated).map((key) => (updated[key] = false));
    updated.Distance = 'any';
    updated.Distance = filterInputs.Distance;
    dispatch(filterInputsActions.handlefilters(updated));
    setChecked(updated);
    dispatch(currentPageActions.pageHandler(1));
    setAllTags(false);
    setAndTags(false);
    // setEditingFilters(false);
    const params = {
      page: 1,
      distance: updated.Distance,
      search: enteredTerm,
      address: enteredLocation,
    };
    if (!enteredTerm && !enteredLocation) {
      delete params.search;
      delete params.address;
    } else if (!enteredTerm) {
      delete params.search;
    } else if (!enteredLocation) {
      delete params.address;
    }

    setSearchParams(params);
  }

  const handleDistance = (event) => {
    if (event.target.value === 'any') {
      const newChecked = { ...checked, Distance: event.target.value };
      setChecked(newChecked);
      return;
    }
    if (locationCoords) {
      const newChecked = { ...checked, Distance: event.target.value };
      setChecked(newChecked);
    } else {
      const successCallback = (position) => {
        const cookies = new Cookies();
        cookies.set('locationPermissionGranted', 'true', { path: '/' });
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        cookies.set('location', JSON.stringify(location), { path: '/' });
        dispatch(
          enteredLocationActions.handleLocationInput('Current Location')
        );
        dispatch(coordsActions.logCoords(location));
        const newChecked = { ...checked, Distance: event.target.value };
        setChecked(newChecked);
        dispatch(distanceActions.toggle(true));
      };
      const errorCallback = (error) => {
        if (error.code === 1) {
          window.alert('Please allow your location access');
        }
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 5000,
      });
    }
  };

  // TOREVIEW: Needed anywhere?
  // const handleVerifier = (event) => {
  //   let newVerifier = checked?.Verifier || [];
  //   newVerifier = event.target.checked ? [...newVerifier, event.target.value] : newVerifier.filter((verifier) => verifier !== event.target.value);
  //   const newChecked = { ...checked, Verifier: newVerifier };
  //   setChecked(newChecked);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      JSON.stringify(filterInputs) === JSON.stringify(checked) &&
      allTags === andTags
    ) {
      setEditingFilters(false);
    } else {
      const newFilters = {};
      Object.keys(checked).forEach((item) => {
        if (checked[item]) {
          newFilters[item] = checked[item];
        }
      });
      newFilters.Distance = checked.Distance;
      newFilters.Verifier = checked.Verifier;
      dispatch(filterInputsActions.changefilters(newFilters));
      dispatch(currentPageActions.pageHandler(1));
      setAndTags(allTags);
      setIsLoading(true);
      setEditingFilters(false);
      setIsSearching(true);
    }
  };

  FormItem.propTypes = {
    name: PropTypes.string.isRequired,
  };
  function FormItem({ name }) {
    return (
      <div key={name} className="FilterPanel__FormItem">
        <label key={name}>
          {!isMobile ? (
            <input
              type="checkbox"
              name={t(name)}
              checked={!!checked[name]}
              onChange={handleChange}
            />
          ) : (
            <Button
              key={name}
              onClick={(event) => {
                handleChange(event);
              }}
              label={t(name)}
              variant={checked[name] ? 'primary' : 'secondary'}
            />
          )}
          {!isMobile && <span>{t(name)}</span>}
        </label>
      </div>
    );
  }

  FormSection.propTypes = {
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  };
  function FormSection({ type, options }) {
    return (
      <div key={type} className="FilterPanel__FormSection">
        <h1>{t(type)}</h1>

        {isMobile}
        {options.map(FormItem)}
      </div>
    );
  }
  const [verifiers, setVerifiers] = useState([]); // Use both state variable and setter function

  const getVerifiers = async () => {
    try {
      const response = await axios.get('/partner/all');
      setVerifiers(response?.data?.payload?.verifiers || []); // Safely handle undefined payload
    } catch (error) {
      console.error(
        'Error fetching verifiers:',
        error.response?.data || error.message
      ); // Error handling
    }
  };

  console.log(checked);
  const { t } = useTranslation();
  return (
    <>
      <SwipeableDrawer
        onOpen={toggleDrawer}
        open={editingFilters}
        onClose={() => {
          toggleDrawer();
        }}
        PaperProps={{
          sx: {
            minWidth: '100px',
            width: '100%',
            maxWidth: '450px',
          },
        }}
      >
        <form className="FilterForm" onSubmit={handleSubmit}>
          {isMobile ? (
            <div className="FilterForm__flexWrap">
              <Button
                className="btn-Cancel"
                label={t('Cancel')}
                onClick={() => {
                  setEditingFilters(false);
                }}
                size="small"
                variant="secondary"
              />
              <Button
                label={t('Apply Filters')}
                onClick={(event) => {
                  handleSubmit(event);
                }}
                size="small"
              />
            </div>
          ) : (
            <>
              <Typography
                className="filterResource"
                variant="h5"
                noWrap
                component="div"
              >
                {t('Filter resources')}
              </Typography>

              <div
                className="filterActions"
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <Typography
                  className="clearFilters"
                  variant="h5"
                  onClick={clearFilters}
                >
                  {t('Reset all preferences')}
                </Typography>
                <Button
                  label={t('Apply Filters')}
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                  size="medium"
                />
              </div>
            </>
          )}

          {filters.map(FormSection)}

          {!isMobile && (
            <>
              <div
                key="filter-distance"
                className="FilterPanel__FilterDistance"
              >
                <h1>{t('Distance')}</h1>
                <label key="0.5">
                  <input
                    type="radio"
                    name="Distance"
                    value="0.5"
                    checked={checked.Distance === '0.5'}
                    onChange={handleDistance}
                  />
                  <span>{t('Within 3 blocks')}</span>
                </label>
                <label key="1">
                  <input
                    type="radio"
                    name="Distance"
                    value="1"
                    checked={checked.Distance === '1'}
                    onChange={handleDistance}
                  />
                  <span>{t('Walking distance (up to 1 mile)')}</span>
                </label>
                <label key="3">
                  <input
                    type="radio"
                    name="Distance"
                    value="3"
                    checked={checked.Distance === '3'}
                    onChange={handleDistance}
                  />
                  <span>{t('Biking distance (up to 3 miles)')}</span>
                </label>
                <label key="5">
                  <input
                    type="radio"
                    name="Distance"
                    value="5"
                    checked={checked.Distance === '5'}
                    onChange={handleDistance}
                  />
                  <span>{t('Driving distance (up to 5 miles)')}</span>
                </label>
                <label key="any">
                  <input
                    type="radio"
                    name="Distance"
                    value="any"
                    checked={checked.Distance === 'any'}
                    onChange={handleDistance}
                  />
                  <span>{t('Driving distance (Any)')}</span>
                </label>
              </div>
            </>
          )}

          {!isMobile && (
            <>
              <div
                key="filter-verifier"
                className="FilterPanel__FilterVerifier"
              >
                {/* <h1>{t('Data Provider')}</h1>
                {verifiers.map((verifier) => {
                  return (
                    <label key={verifier._id}>
                      <input
                        type="checkbox"
                        name="Data Provider"
                        value={verifier._id}
                        checked={checked.Verifier?.includes(verifier._id)}
                        onChange={handleVerifier}
                      />
                      <span>{verifier.name}</span>
                    </label>
                  )
                })} */}
              </div>
            </>
          )}

          {!isMobile && (
            <div className="FilterForm__flexWrap">
              <Button
                label={t('Cancel')}
                onClick={() => {
                  setEditingFilters(false);
                }}
                size="small"
                variant="secondary"
              />
              {/* <Button
                label={t('Apply Filters')}
                onClick={(event) => {
                  handleSubmit(event);
                }}
                size="small"
              /> */}
            </div>
          )}
        </form>
      </SwipeableDrawer>
    </>
  );
}
