import React from 'react';

function ShelterGraphic() {
  return (
    <svg
      width="56"
      height="46"
      viewBox="0 0 56 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.1494 17.1646H23.627V46.0004H51.1494C52.254 46.0004 53.1494 45.1049 53.1494 44.0004V17.1646Z"
        fill="#689CEB"
      />
      <g filter="url(#filter0_i_624_3469)">
        <path
          d="M29.1194 28.4624C29.1194 27.9101 29.5671 27.4624 30.1194 27.4624H35.3853C35.9376 27.4624 36.3853 27.9101 36.3853 28.4624V34.7283H29.1194V28.4624Z"
          fill="white"
        />
        <path
          d="M29.1194 28.4624C29.1194 27.9101 29.5671 27.4624 30.1194 27.4624H35.3853C35.9376 27.4624 36.3853 27.9101 36.3853 28.4624V34.7283H29.1194V28.4624Z"
          fill="#FFBE71"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M29.1194 35.5352H37.5963C37.8192 35.5352 38 35.3544 38 35.1315C38 34.9086 37.8192 34.7278 37.5963 34.7278H29.1194V35.5352Z"
        fill="#5082CD"
      />
      <path
        opacity="0.3"
        d="M29.1194 36.1509C29.1194 36.7032 29.5671 37.1509 30.1194 37.1509H35.3853C35.9376 37.1509 36.3853 36.7032 36.3853 36.1509V35.5362H29.1194V36.1509Z"
        fill="#417DD9"
      />
      <g filter="url(#filter1_i_624_3469)">
        <path
          d="M41.1194 28.4624C41.1194 27.9101 41.5671 27.4624 42.1194 27.4624H47.3853C47.9376 27.4624 48.3853 27.9101 48.3853 28.4624V34.7283H41.1194V28.4624Z"
          fill="white"
        />
        <path
          d="M41.1194 28.4624C41.1194 27.9101 41.5671 27.4624 42.1194 27.4624H47.3853C47.9376 27.4624 48.3853 27.9101 48.3853 28.4624V34.7283H41.1194V28.4624Z"
          fill="#FFBE71"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M41.1194 35.5352H49.5963C49.8192 35.5352 50 35.3544 50 35.1315C50 34.9086 49.8192 34.7278 49.5963 34.7278H41.1194V35.5352Z"
        fill="#5082CD"
      />
      <path
        opacity="0.3"
        d="M41.1194 36.1509C41.1194 36.7032 41.5671 37.1509 42.1194 37.1509H47.3853C47.9376 37.1509 48.3853 36.7032 48.3853 36.1509V35.5362H41.1194V36.1509Z"
        fill="#417DD9"
      />
      <path
        d="M25 17.1643L13 6.00049L3.53814 16.5952C3.21079 16.9617 3.02985 17.436 3.02985 17.9274V44.0001C3.02985 45.1047 3.92528 46.0001 5.02985 46.0001H25V17.1643Z"
        fill="#A1C1F0"
      />
      <rect
        x="0.970215"
        y="17.8511"
        width="2.0597"
        height="2.74627"
        fill="#3367B7"
      />
      <path
        d="M23.2169 17.1643H55.209L44.0795 6.55298C43.7075 6.19833 43.2133 6.00049 42.6994 6.00049H13L23.2169 17.1643Z"
        fill="#3367B7"
      />
      <g filter="url(#filter2_i_624_3469)">
        <path
          d="M9.82397 22.0005C9.82397 21.4482 10.2717 21.0005 10.824 21.0005H14.4183C14.9706 21.0005 15.4183 21.4482 15.4183 22.0005V26.5948H9.82397V22.0005Z"
          fill="white"
        />
        <path
          d="M9.82397 22.0005C9.82397 21.4482 10.2717 21.0005 10.824 21.0005H14.4183C14.9706 21.0005 15.4183 21.4482 15.4183 22.0005V26.5948H9.82397V22.0005Z"
          fill="#FFBE71"
          fillOpacity="0.6"
        />
      </g>
      <path
        d="M8.96875 26.491C8.96875 26.6626 9.1079 26.8018 9.27955 26.8018H15.4213V26.1802H9.27955C9.1079 26.1802 8.96875 26.3193 8.96875 26.491Z"
        fill="#5082CD"
      />
      <path
        opacity="0.3"
        d="M9.79932 26.8025C9.79932 27.2948 10.1984 27.6938 10.6907 27.6938H14.5274C15.0197 27.6938 15.4188 27.2948 15.4188 26.8025H9.79932Z"
        fill="#417DD9"
      />
      <path
        d="M25 42.0005H3V44.0005C3 45.1051 3.89543 46.0005 5 46.0005H25V42.0005Z"
        fill="#89A8D6"
      />
      <path
        d="M13 43.941L13.7361 41.8813H17.4478V46.0008H10.5V43.941H13Z"
        fill="#1C4482"
      />
      <path
        d="M0.970215 44.9404C0.970215 44.3881 1.41793 43.9404 1.97021 43.9404H11.2687V46.0001H0.970215V44.9404Z"
        fill="#689CEB"
      />
      <path
        d="M4.40308 43C4.40308 42.4477 4.85079 42 5.40308 42H14.7016V43.9405H4.40308V43Z"
        fill="#79ABF8"
      />
      <path
        d="M9.20898 30.2095H16.4522C17.0028 30.2095 17.4498 30.6545 17.4522 31.205L17.5 42.0005H9.20898V30.2095Z"
        fill="#345B96"
      />
      <path
        d="M9.20898 30.2095H16.4478C17.0001 30.2095 17.4478 30.6572 17.4478 31.2095V31.5826H9.20898V30.2095Z"
        fill="#12428B"
      />
      <path
        d="M9.20898 30.2095H8.14928C7.597 30.2095 7.14928 30.6572 7.14928 31.2095V42.0005H9.20898V30.2095Z"
        fill="#4075C6"
      />
      <path
        d="M53 42.0005H25V46.0005H51C52.1046 46.0005 53 45.1051 53 44.0005V42.0005Z"
        fill="#5082CD"
      />
      <path
        d="M38.0449 2.06006H35.2987V7.591C35.2987 7.84059 35.392 8.08116 35.5603 8.26544L38.0449 10.9854V2.06006Z"
        fill="#689CEB"
      />
      <path
        d="M44.2239 1.06006C44.2239 1.61234 43.7762 2.06006 43.2239 2.06006H38.0448V0.000357151H43.2239C43.7762 0.000357151 44.2239 0.448072 44.2239 1.00036V1.06006Z"
        fill="#3367B7"
      />
      <path
        d="M38.0449 2.06006H35.2688C34.7165 2.06006 34.2688 1.61234 34.2688 1.06006V1.00036C34.2688 0.448073 34.7165 0.000357151 35.2688 0.000357151H38.0449V2.06006Z"
        fill="#689CEB"
      />
      <path
        d="M25.0001 19.9885C25.0001 20.2112 24.7308 20.3227 24.5733 20.1653L13.3284 8.9252L0.970215 20.5918V17.501C0.970215 17.2244 1.08476 16.9602 1.28662 16.7711L12.6221 6.15426C13.0159 5.78543 13.6312 5.7955 14.0128 6.17701L25.0001 17.1643V19.9885Z"
        fill="#86B7FF"
      />
      <path
        opacity="0.5"
        d="M25.0002 22.6573L13.3285 10.9856L3.03003 20.5976V18.6709L13.3285 8.94873L25.0002 20.5976V22.6573Z"
        fill="#4F86DA"
      />
      <path
        d="M25 17.1646H55.209V19.5974C55.209 20.1497 54.7612 20.5974 54.209 20.5974H25V17.1646Z"
        fill="#345B96"
      />
      <rect
        opacity="0.37"
        x="25"
        y="20.5967"
        width="28.1493"
        height="2.0597"
        fill="#345B96"
      />
      <path
        d="M38.0449 2.06006H42.8509V9.98543C42.8509 10.5377 42.4032 10.9854 41.8509 10.9854H38.0449V2.06006Z"
        fill="#345B96"
      />
      <rect
        x="38.0449"
        y="2.06006"
        width="4.80597"
        height="1.37313"
        fill="#154081"
      />
      <rect
        x="35.2986"
        y="2.06006"
        width="2.74627"
        height="1.37313"
        fill="#4981D5"
      />
      <defs>
        <filter
          id="filter0_i_624_3469"
          x="29.1194"
          y="27.4624"
          width="7.26587"
          height="7.26611"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.988235 0 0 0 0 0.588235 0 0 0 0 0.345098 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_624_3469"
          />
        </filter>
        <filter
          id="filter1_i_624_3469"
          x="41.1194"
          y="27.4624"
          width="7.26587"
          height="7.26611"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.988235 0 0 0 0 0.588235 0 0 0 0 0.345098 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_624_3469"
          />
        </filter>
        <filter
          id="filter2_i_624_3469"
          x="9.82397"
          y="21.0005"
          width="5.59424"
          height="5.59424"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.988235 0 0 0 0 0.588235 0 0 0 0 0.345098 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_624_3469"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ShelterGraphic;
