import { configureStore } from '@reduxjs/toolkit';
import coordsReducer from './coords-slice';
import filterInputsReducer from './filterInput-slice';
import distanceReducer from './distance-slice';
import currentPageReducer from './currentPage-slice';
import enteredTermReducer from './enteredTerm-slice';
import enteredLocationReducer from './enteredLocation-slice';
import authReducer from './auth-slice';
const store = configureStore({
  reducer: {
    coords: coordsReducer,
    filterInputs: filterInputsReducer,
    distance: distanceReducer,
    currentPage: currentPageReducer,
    enteredTerm: enteredTermReducer,
    enteredLocation: enteredLocationReducer,
    auth: authReducer,
  },
});
export default store;
