import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Reviews,
  Button,
  Footer,
  Header,
  Pills,
  Table,
  TagIcon,
  LoginPrompt,
} from '../../components';
import {
  Tooltip,
  IconButton,
  Typography,
  Rating,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from 'react-responsive';
import { axiosPrivate } from '../../apis/backend';
import { useSelector } from 'react-redux';
import axios from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';

export function Resource() {
  const user = useSelector((state) => state.auth.user);
  const authed = user?.roles?.includes('100001');
  const [login, setLogin] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const [searchParams] = useSearchParams();
  const distance = searchParams.get('distance');

  const [isReview, setIsReview] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [reviews, setReviews] = useState([]); // Not doing anything ATM
  const [reviewData, setReviewData] = useState(null);

  const [rating, setRating] = useState(0);
  const [averageStars, setAverageStars] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [showRatingWarning, setShowRatingWarning] = useState(false); // State for rating warning

  // Function to handle rating change
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    setShowRatingWarning(false); // Reset warning when a rating is selected
    console.log('Rating changed to:', newValue); // Debug log
  };
  const handleCloseAlert = () => {
    setShowRatingWarning(false);
  };

  // Material UI Rating Options
  const renderRatingOptions = () => (
    <Rating
      name="rating"
      value={rating}
      onChange={handleRatingChange}
      precision={1}
      max={5}
      sx={{ fontSize: '7.5rem' }}
    />
  );

  // Function to post a new review
  const handlePostReview = async () => {
    console.log('Current rating value:', rating); // Debug log
    setShowRatingWarning(false);

    if (rating === null || rating === 0) {
      setShowRatingWarning(true); // Show warning only if rating is not selected
      return;
    }
    // Check if the resource has an address country before proceeding
    if (!cardData?.address?.country) {
      console.error(
        'The resource does not have an address country. Cannot proceed with posting the review.'
      );
      setShowRatingWarning(true); // Optional: Show warning if needed
      return;
    }

    if (reviewText.trim()) {
      try {
        const response = await axiosPrivate.post(
          `/resource/review?resourceId=${id}`,
          {
            review: {
              stars: rating,
              reviewString: reviewText,
            },
          }
        );
        if (response.status === 200) {
          setReviewText(''); // Clear the textarea
          fetchReviewData(); // Refresh the list of reviews
          setOpenDialog(true); // Open the dialog after submission
        }
      } catch (error) {
        if (error.response) {
          console.error('Failed to post review:', error.response.data);
        } else {
          console.error('Error posting review:', error);
        }
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close dialog
    setLogin(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleWriteReviewClick = async () => {
    // Check if user is logged in
    if (!authed) {
      // Throw the login popup at em if not!
      setLogin(true);
      return;
    }

    setIsReview(true);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handleBackClick = () => {
    setIsReview(false);
  };

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    console.log('opened');
    console.log(distance); // Log the distance parameter to the console
  }, [distance]); // Add distance as a dependency

  const handleOpenInMaps = (address) => {
    // Construct the Google Maps URL with destination coordinates
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(`${address?.street}, ${address?.city}, ${address?.state}, ${address?.zipCode}`)}`;

    // Open the URL in a new tab
    window.open(mapsUrl, '_blank');
  };

  const boldText = {
    color: 'var(--FRM-Neutral-neutral-900, #2C2D30)',
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.2px',
    marginBottom: 20,
  };

  const regText = {
    color: 'var(--FRM-Neutral-neutral-700, #4B4C51)',
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0px', // Note: Letter spacing specified as 0px to match your specification,
    marginBottom: 30,
  };

  function parseDate(dateString) {
    if (!dateString) return 'Unknown date';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    const month = date.getUTCMonth() + 1; // getUTCMonth() is 0-based
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  function militaryToAMPM(time) {
    const [hours, minutes] = time.split(':');
    let formattedTime = '';
    let hoursNum = parseInt(hours);
    const ampm = hoursNum >= 12 ? 'PM' : 'AM';
    hoursNum = hoursNum % 12;
    hoursNum = hoursNum ? hoursNum : 12; // the hour '0' should be '12'
    formattedTime = `${hoursNum}:${minutes} ${ampm}`;
    return formattedTime;
  }

  function formatBusinessHours(inputHoursString) {
    if (!inputHoursString) return '';

    const inputHours = JSON.parse(inputHoursString);

    if (!inputHours) return '';

    const indentation = 20;
    let formattedHours = '';

    // Loop through each day of the week
    for (let day in inputHours) {
      if (inputHours.hasOwnProperty(day)) {
        const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
        formattedHours += `${capitalizedDay}: \n`;

        // Format and append the hours to the result string
        if (
          inputHours[day].length === 0 ||
          (inputHours[day].length === 1 &&
            inputHours[day][0][0] === '00:00' &&
            inputHours[day][0][1] === '00:00')
        ) {
          formattedHours += ' '.repeat(indentation) + 'Closed\n';
        } else if (
          inputHours[day].length === 1 &&
          inputHours[day][0][0] === '00:00' &&
          inputHours[day][0][1] === '23:59'
        ) {
          formattedHours += ' '.repeat(indentation) + 'Open 24 Hours\n';
        } else {
          inputHours[day].forEach((interval) => {
            const startTime = militaryToAMPM(interval[0]);
            const endTime = militaryToAMPM(interval[1]);
            formattedHours +=
              ' '.repeat(indentation) + `${startTime} - ${endTime}\n`;
          });
        }
      }
    }

    return formattedHours;
  }

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [cardData, setCardData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [locationAllowed, setLocationAllowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCardData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/resource/public?resourceId=${id}`); // single?
        const data = await response?.data;

        if (data?.errCode === '0' && data?.payload) {
          // const reviewData = JSON.parse(data.payload.reviewData);
          setCardData(data.payload);
        } else {
          console.error('Error fetching the resource:', data);
          // handle error based on errCode or lack of payload
        }
      } catch (error) {
        console.error('Error fetching the resource id:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // if (locationAllowed) {
    //   fetchCardData();
    // }

    fetchCardData();
  }, [id, locationAllowed]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);

  //     const fetchReviewData = async () => {
  //         setIsLoading(true);
  //         try {
  //             const response = await axios.get(`/resource/review?resourceId=${id}`);   // single?
  //             const data = await response?.data;

  //             if (data?.errCode === "0" && data?.payload) {
  //                 // const reviewData = JSON.parse(data.payload.reviewData);
  //                 setCardData(data.payload);
  //             } else {
  //                 console.error('Error fetching the resource:', data);
  //                 // handle error based on errCode or lack of payload
  //             }
  //         } catch (error) {
  //             console.error('Error fetching the resource id:', error);
  //         } finally {
  //             setIsLoading(false);
  //         }
  //     };

  //     // if (locationAllowed) {
  //     //   fetchCardData();
  //     // }

  //     fetchCardData();
  // }, [id, locationAllowed]);

  // Function to fetch all reviews

  const fetchReviewData = async () => {
    try {
      const response = await axiosPrivate.get(
        `/resource/review?resourceId=${id}`
      );
      if (response.status === 200 && response.data.errCode === '0') {
        console.log(response.data.payload); // Log the entire payload to check its structure
        setReviews(response.data.payload.reviews);

        // Check if the current user has already posted a review
        const userId = 'your-current-user-id'; // Replace with the actual user ID from your authentication logic
        const userReview = reviews.find((review) => review.userID === userId);

        if (userReview) {
          setIsReview(true);
        } else {
          setIsReview(false);
        }
      }
    } catch (error) {
      console.error('Failed to fetch reviews:', error);
    }
  };

  // Fetch reviews on component mount
  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/resource/review?resourceId=${id}`
        );
        if (response.status === 200 && response.data.errCode === '0') {
          setReviewData(response.data.payload);
          console.log('reviewData after fetch:', response.data.payload); // Log the data here
        }
      } catch (error) {
        console.error('Failed to fetch review data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviewData();
  }, [id]);

  useEffect(() => {
    const fetchCardData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(
          `/resource/public?resourceId=${id}`
        ); // Fetch data from single resource route
        const data = response?.data;

        if (data?.errCode === '0' && data?.payload) {
          setCardData(data.payload);

          // Set the averageStars from the payload if it exists
          setAverageStars(data.payload.averageStars || 0);
        } else {
          console.error('Error fetching the resource:', data);
        }
      } catch (error) {
        console.error('Error fetching the resource id:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCardData();
  }, [id]);

  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        const response = await axiosPrivate.get(
          `/resource/review?resourceId=${id}`
        );
        if (response.status === 200 && response.data.errCode === '0') {
          const fetchedReviewData = response.data.payload;
          setReviewData(fetchedReviewData);
          setReviews(fetchedReviewData.reviews || []); // Populate reviews with fetched data
          setAverageStars(fetchedReviewData.averageStars || 0); // Set average stars if applicable
        }
      } catch (error) {
        console.error('Failed to fetch review data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviewData();
  }, [id]);

  if (isLoading) return <div>Loading...</div>;

  const renderVerificationCheckmark = (verfierName) => {
    return (
      <Tooltip
        arrow
        title={
          <span
            style={{ fontSize: '14px', lineHeight: '1.5' }}
          >{`${t('Verified and brought to you by')} ${verfierName}`}</span>
        }
        placement="top"
      >
        <span
          style={{
            display: 'flex',
            marginLeft: '20px',
            padding: '8px 16px',
            'align-items': 'center',
            gap: '8px',
            'border-radius': '28px',
            background: '#BFF6B6',
          }}
        >
          <svg
            style={{
              width: '16px',
              height: '16px',
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M2.5 7L6.5 11L13.5 4" stroke="#29660D" strokeWidth="2" />
          </svg>
          <span
            style={{
              color: '#29660D',
              'text-align': 'center',
              'font-family': 'Nunito',
              'font-size': '16px',
              'font-style': 'normal',
              'font-weight': 700,
              'line-height': '24px' /* 150% */,
            }}
          >
            {t('Verified')}
          </span>
        </span>
      </Tooltip>
    );
  };

  // Helper function to format hours
  const formatHours = (hours) => {
    if (!hours) return;
    try {
      const hoursObj = JSON.parse(hours);
      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];

      // Map each day to its corresponding hours string
      const parsed = daysOfWeek.map((day) => {
        const dayHours = hoursObj[day.toLowerCase()];
        if (!dayHours || dayHours.length === 0) {
          return { day, hoursString: 'Closed' };
        } else if (
          dayHours.length === 1 &&
          dayHours[0][0] === '00:00' &&
          dayHours[0][1] === '23:59'
        ) {
          return { day, hoursString: 'Open 24 hours' };
        } else {
          const hoursString = dayHours
            .map((slot) => `${slot[0]} - ${slot[1]}`)
            .join(', ');
          return { day, hoursString };
        }
      });
      console.log(parsed);
      //return parsed;
    } catch (error) {
      console.error('Error parsing hours:', error);
      return 'Closed';
    }
  };

  const formatTags = (tags) => {
    if (!tags) return [];
    return tags.map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    });
  };

  const renderReviewForm = () => (
    <div className="resource-page__content">
      <div
        className="resource-page__left"
        style={{
          flex: 2,
          width: '100%',
          maxWidth: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Typography
            style={{
              ...boldText,
              fontSize: 33,
              marginRight: 30,
              lineHeight: '38px',
            }}
          >
            {cardData.name}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            marginTop: -20,
            marginLeft: -5,
          }}
        >
          <Rating
            name="average-stars"
            value={averageStars}
            readOnly
            precision={0.1}
            sx={{ fontSize: '3rem' }}
          />
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          {cardData.verifiedBy && cardData.verifiedBy !== '' && (
            <div style={{ display: 'flex' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="7.375"
                  stroke="#2067D2"
                  strokeWidth="1.25"
                />
                <path
                  d="M6.49976 10L8.99976 12.5L13.4998 8"
                  stroke="#2067D2"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                style={{
                  ...regText,
                  color: '#2067D2',
                  fontSize: 17,
                  marginLeft: 5,
                }}
              >
                Verified by {cardData.verifierName}
              </Typography>

              <svg
                style={{ marginTop: 7, marginLeft: 8, marginRight: 8 }}
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="4"
                viewBox="0 0 3 4"
                fill="none"
              >
                <circle cx="1.5" cy="2" r="1.5" fill="#696B72" />
              </svg>
            </div>
          )}
          <Typography style={{ ...regText, fontSize: 17, marginLeft: 5 }}>
            Last updated {parseDate(cardData.timeLastUpdate)}
          </Typography>
        </div>
        <button
          onClick={handleBackClick}
          style={{
            display: 'flex', // Use flexbox for layout
            alignItems: 'center', // Center align items vertically
            justifyContent: 'center', // Center align items horizontally
            width: '145px',
            height: '45px',
            borderRadius: '10px',
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '16px',
            marginBottom: '20px',
            padding: '0 10px', // Padding on the sides to ensure content is centered
            border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
          }}
        >
          <svg
            style={{ marginRight: 10 }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9 5L4 10M4 10L9 15M4 10H16"
              stroke="#2C2D30"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back
        </button>

        <div style={{ display: 'flex', marginBottom: 10 }}>
          {renderRatingOptions()}
        </div>

        <div>
          <Typography style={{ ...boldText }}>
            What would you like to say?
          </Typography>
          <textarea
            placeholder="Write your review here..."
            maxLength="500"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            style={{
              resize: 'none',
              width: '100%',
              maxWidth: '800px',
              height: '380px',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #D1D1D3',
              boxSizing: 'border-box',
            }}
          />
          <Typography
            style={{
              ...regText,
              alignSelf: 'flex-end', // Aligns the counter to the end of the flex-direction axis
              paddingRight: '10px', // Right padding for spacing from the container edge
              width: '100%', // Takes full width to allow flex-end to push it to the right
            }}
          >
            {reviewText.length} / 500
          </Typography>
        </div>

        {showRatingWarning && rating === 0 && (
          <Alert
            severity="warning"
            sx={{
              mb: 2,
              border: '1px solid #B71C1C', // Red border color
              borderRadius: '8px', // Rounded corners
              backgroundColor: '#FFFFFF', // White background color
              color: '#2C2D30', // Black text color
              fontWeight: 'bold', // Bold text
              fontSize: '16px', // Adjust font size
              fontFamily: 'Nunito, sans-serif', // Custom font family
              padding: '16px 24px', // Padding for spacing
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Shadow for depth
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
                sx={{
                  color: '#B71C1C', // Red color for the close icon
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Please select a star rating before submitting your review.
          </Alert>
        )}
        <button
          style={{
            border: 'none',
            borderRadius: '8px',
            backgroundColor: '#0052cc',
            color: '#FFFFFF',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '18px',
            display: 'flex', // Flex display as per .button class
            alignItems: 'center', // Center alignment as per .button class
            lineHeight: '2rem', // Line height as per .button class
            fontSize: '1.8rem', // Font size as per .button class
            padding: '1.7rem 2rem', // Padding as per .button class
            fontFamily:
              'Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif', // Font family as per .button class
            fontWeight: '700', // Font weight as per .button class
            width: '225px', // Set the width to 176 pixels
            height: '45px', // Set the height to 41 pixels
            justifyContent: 'center', // Ensure contents are centered within the button
          }}
          onClick={handlePostReview}
        >
          Post
        </button>
      </div>
      <div
        className="resource-page__right"
        style={{
          flex: 1,
          marginRight: '15rem',
          marginLeft: '10rem',
          marginTop: 20,
          maxWidth: 400,
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '-54px',
            paddingBottom: '6px',
            maxWidth: '500px',
            margin: '0 auto',
          }}
        >
          <button
            className="print-button"
            style={{
              display: 'flex',
              marginTop: -5,
              marginRight: 5,
              height: 40,
              width: '153px',
              padding: '0.5em 1em',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--FRM-White, #FFF)', // Background color
              border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
              borderRadius: '8px', // Border radius
              cursor: 'pointer', // Change cursor to pointer on hover
              marginBottom: 15,
              fontSize: '15px', // Set font size to 15px
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.5 7.5V4H13.5V7.5M6.5 7.5H13.5M6.5 7.5H5C3.89543 7.5 3 8.39543 3 9.5V14H6M13.5 7.5H15C16.1046 7.5 17 8.39543 17 9.5V14H14M6 14V12H14V14M6 14V16.5H14V14"
                stroke="#4B4C51"
                strokeWidth="1.25"
                strokeLinejoin="round"
              />
              <circle cx="14.9168" cy="9.4395" r="0.7" fill="#4B4C51" />
            </svg>
            <Typography
              style={{
                ...boldText,
                marginLeft: 2,
                marginBottom: 0,
                fontSize: 15,
                fontWeight: 'bold',
              }}
            >
              Donate
            </Typography>
          </button>

          <button
            className="print-button"
            style={{
              display: 'flex',
              marginTop: -5,
              marginRight: 5,
              height: 40,
              width: '153px',
              padding: '0.5em 1em',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--FRM-White, #FFF)', // Background color
              border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
              borderRadius: '8px', // Border radius
              cursor: 'pointer', // Change cursor to pointer on hover
              marginBottom: 15,
              fontSize: '15px', // Set font size to 15px
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.5 7.5V4H13.5V7.5M6.5 7.5H13.5M6.5 7.5H5C3.89543 7.5 3 8.39543 3 9.5V14H6M13.5 7.5H15C16.1046 7.5 17 8.39543 17 9.5V14H14M6 14V12H14V14M6 14V16.5H14V14"
                stroke="#4B4C51"
                strokeWidth="1.25"
                strokeLinejoin="round"
              />
              <circle cx="14.9168" cy="9.4395" r="0.7" fill="#4B4C51" />
            </svg>
            <Typography
              style={{
                ...boldText,
                marginLeft: 2,
                fontSize: 15,
                marginBottom: 0,
                minWidth: 80,
              }}
            >
              Volunteer
            </Typography>
          </button>
          <button
            className="print-button"
            style={{
              display: 'flex',
              marginTop: -5,
              marginRight: 5,
              height: 40,
              width: '153px',
              padding: '0.5em 1em',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'var(--FRM-White, #FFF)', // Background color
              border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
              borderRadius: '8px', // Border radius
              cursor: 'pointer', // Change cursor to pointer on hover
              marginBottom: 15,
              fontSize: '15px', // Set font size to 15px
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.5 7.5V4H13.5V7.5M6.5 7.5H13.5M6.5 7.5H5C3.89543 7.5 3 8.39543 3 9.5V14H6M13.5 7.5H15C16.1046 7.5 17 8.39543 17 9.5V14H14M6 14V12H14V14M6 14V16.5H14V14"
                stroke="#4B4C51"
                strokeWidth="1.25"
                strokeLinejoin="round"
              />
              <circle cx="14.9168" cy="9.4395" r="0.7" fill="#4B4C51" />
            </svg>
            <Typography
              style={{
                ...boldText,
                marginLeft: 2,
                fontSize: 15,
                marginBottom: 0,
                minWidth: 80,
              }}
            >
              Save
            </Typography>
          </button>
        </div>

        <div
          className="box-to-hide"
          style={{
            border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
            marginBottom: 50,
            padding: 20,
            borderRadius: 8,
            background: 'var(--FRM-White, #FFF)',
            boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <div style={{ display: 'flex' }}>
            <svg
              style={{ marginTop: 2, marginRight: 10 }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <circle
                cx="10"
                cy="10"
                r="7.375"
                stroke="#2067D2"
                strokeWidth="1.25"
              />
              <path
                d="M3 7.5H17M3 12.5H17M10.6673 16.7556C13.7785 12.8856 13.7776 6.63741 10.6646 3.1579C10.3136 2.76554 9.70589 2.78634 9.37104 3.1926C6.2233 7.01168 6.20971 12.9205 9.33029 16.7576C9.67875 17.1861 10.3213 17.1861 10.6673 16.7556Z"
                stroke="#2067D2"
                strokeWidth="1.25"
              />
            </svg>
            <Typography style={{ ...boldText, fontSize: 18 }}>
              Website
            </Typography>
          </div>
          <Link
            style={{
              ...regText,
              color: '#2067D2',
              maxWidth: 200,
              wordWrap: 'break-word',
            }}
            href={cardData.website}
            underline="always"
            target="_blank"
            rel="noopener noreferrer"
          >
            {cardData.website}
          </Link>

          {cardData?.phone?.number && (
            <div>
              <div style={{ display: 'flex', marginTop: 20 }}>
                <svg
                  style={{ marginTop: 2, marginRight: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.97086 6.25873C7.77547 5.06431 6.96159 3.51138 6.20078 2.80071C6.10955 2.71549 5.97976 2.68795 5.86107 2.72664C4.60067 3.13751 3.30415 4.4223 2.85126 5.71973C2.8348 5.76689 2.82864 5.81675 2.8319 5.86659C3.02483 8.81548 4.04984 11.3921 6.33725 13.7533C8.62466 16.1146 11.2771 17.0658 14.226 17.2587C14.2759 17.262 14.3257 17.2558 14.3729 17.2393C15.6703 16.7864 16.9551 15.4899 17.366 14.2295C17.4046 14.1108 17.3771 13.981 17.2919 13.8898C16.5812 13.129 15.0283 12.3151 13.8339 12.1197C13.7382 12.1041 13.6407 12.1281 13.562 12.1847C12.7975 12.7343 12.6127 12.9875 11.8922 13.3758C11.8375 13.4053 11.7767 13.4203 11.7148 13.4152C10.978 13.3546 9.42876 12.9131 8.28125 11.7422C7.13374 10.5712 6.66885 9.04746 6.60823 8.31061C6.60314 8.24872 6.61818 8.18791 6.64764 8.13325C7.03595 7.41272 7.35632 7.29508 7.90591 6.53056C7.96248 6.45187 7.9865 6.35436 7.97086 6.25873Z"
                    stroke="#2067D2"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography style={{ ...boldText, fontSize: 18 }}>
                  Phone
                </Typography>
              </div>

              <Link
                style={{ ...regText, color: '#2067D2', wordWrap: 'break-word' }}
                href={cardData.website}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber(cardData?.phone?.number || '')}
              </Link>
            </div>
          )}

          {cardData?.email && (
            <div>
              <div style={{ display: 'flex', marginTop: 20 }}>
                <svg
                  style={{ marginTop: 2, marginRight: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M15 3H3C2.175 3 1.5075 3.675 1.5075 4.5L1.5 13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3ZM15 13.5H3V6L9 9.75L15 6V13.5ZM9 8.25L3 4.5H15L9 8.25Z"
                    fill="#2067D2"
                  />
                </svg>
                <Typography style={{ ...boldText, fontSize: 18 }}>
                  E-mail
                </Typography>
              </div>

              <Link
                style={{ ...regText, color: '#2067D2' }}
                href={`mailto:${cardData.email}`}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                {cardData.email}
              </Link>
            </div>
          )}
        </div>

        {(cardData?.address?.street != 'Unknown' ||
          cardData?.hours ||
          cardData?.hours_string) && (
          <div
            className="box-to-hide"
            style={{
              border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
              marginBottom: 50,
              padding: 20,
              borderRadius: 8,
              background: 'var(--FRM-White, #FFF)',
              boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
            }}
          >
            {cardData?.address?.street === 'Online' ||
            cardData?.address?.street === 'Unknown' ||
            cardData?.address?.street === '' ? (
              <Typography style={{ ...boldText, fontSize: 22 }}>
                Hours
              </Typography>
            ) : (
              <div>
                <Typography style={{ ...boldText, fontSize: 22 }}>
                  Hours and Location
                </Typography>

                <div style={{ display: 'flex', marginTop: 20 }}>
                  <div style={{ flex: 1 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.375 9C16.375 10.9954 15.109 12.9852 13.5832 14.6371C12.1884 16.1471 10.6666 17.2783 10 17.7447C9.33337 17.2783 7.81158 16.1471 6.41682 14.6371C4.89098 12.9852 3.625 10.9954 3.625 9C3.625 5.47918 6.47918 2.625 10 2.625C13.5208 2.625 16.375 5.47918 16.375 9Z"
                        stroke="#2067D2"
                        strokeWidth="1.25"
                        strokeLinejoin="round"
                      />
                      <circle
                        cx="10"
                        cy="9"
                        r="2"
                        stroke="#2067D2"
                        strokeWidth="1.25"
                      />
                    </svg>
                  </div>
                  <div style={{ flex: 8 }}>
                    {/* If there is an hours string print that instead */}

                    <Typography
                      style={{ ...regText, color: '#2C2D30', marginBottom: 0 }}
                    >
                      {cardData?.address?.street}
                    </Typography>
                    <Typography
                      style={{ ...regText, color: '#2C2D30', marginBottom: 5 }}
                    >
                      {cardData?.address?.city}, {cardData?.address?.state}{' '}
                      {cardData?.address?.zipCode}
                    </Typography>

                    <Typography style={{ ...regText, marginBottom: 15 }}>
                      {distance}
                    </Typography>
                  </div>
                </div>

                <button
                  style={{
                    display: 'flex',
                    // width: '153px',
                    padding: '5px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // gap: '8px',
                    backgroundColor: 'var(--FRM-White, #FFF)', // Background color
                    border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
                    borderRadius: '8px', // Border radius
                    cursor: 'pointer', // Change cursor to pointer on hover
                    marginBottom: 15,
                  }}
                  onClick={() => {
                    handleOpenInMaps(cardData?.address);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 10L10.5 2L18.5 10L10.5 18L2.5 10Z"
                      stroke="#4B4C51"
                      strokeWidth="1.25"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 12V10.5C8 9.94772 8.44772 9.5 9 9.5H13M13 9.5L11.5 8M13 9.5L11.5 11"
                      stroke="#4B4C51"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Typography
                    style={{
                      ...boldText,
                      marginLeft: 10,
                      fontSize: 15,
                      marginBottom: 0,
                    }}
                  >
                    Get Directions
                  </Typography>
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </div>
            )}

            <pre multiline style={{ ...regText }}>
              {formatBusinessHours(cardData?.hours)}
            </pre>
          </div>
        )}
        <div
          className="box-to-hide"
          style={{
            border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
            marginBottom: 50,
            padding: 20,
            borderRadius: 8,
            background: 'var(--FRM-White, #FFF)',
            boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography style={{ ...regText, marginBottom: 5 }}>
            Help us improve the Florida Resource Map!
          </Typography>
          <Typography style={{ ...boldText, fontSize: 15 }}>
            Please email us your questions or feedback at:
          </Typography>
          <Link
            style={{ ...boldText, fontSize: 17, color: '#2067D2' }}
            href={`mailto:${cardData.email}`}
            underline="always"
            target="_blank"
            rel="noopener noreferrer"
          >
            frm-support@floridainnovation.org
          </Link>
        </div>
      </div>
    </div>
  );

  const renderMobileReviewForm = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '25px',
      }}
    >
      <div
        className="resource-page__left"
        style={{ marginTop: 5, maxWidth: '88%' }}
      >
        <button
          className="print-button"
          style={{
            marginTop: -20,
            display: 'flex',
            height: 40,
            width: 100,
            padding: '5px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--FRM-White, #FFF)',
            border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
            borderRadius: '8px',
            cursor: 'pointer',
            marginBottom: 40,
          }}
          onClick={handleBackClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9 5L4 10M4 10L9 15M4 10H16"
              stroke="#2C2D30"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography
            style={{
              ...boldText,
              marginLeft: 10,
              fontSize: 15,
              marginBottom: 0,
            }}
          >
            Back
          </Typography>
        </button>

        <div style={{ display: 'flex', marginTop: -20 }}>
          <Typography
            style={{
              ...boldText,
              fontSize: 33,
              marginRight: 30,
              lineHeight: '38px',
            }}
          >
            {cardData.name}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            marginTop: -20,
            marginLeft: -5,
          }}
        >
          <Rating
            name="average-stars"
            value={averageStars}
            readOnly
            precision={0.1}
            sx={{ fontSize: '3rem' }}
          />
        </div>

        <div style={{ display: 'flex', marginBottom: 10 }}>
          {cardData.verifiedBy && cardData.verifiedBy !== '' && (
            <div style={{ display: 'flex' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle
                  cx="10"
                  cy="10"
                  r="7.375"
                  stroke="#2067D2"
                  strokeWidth="1.25"
                />
                <path
                  d="M6.49976 10L8.99976 12.5L13.4998 8"
                  stroke="#2067D2"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                style={{
                  ...regText,
                  color: '#2067D2',
                  fontSize: 17,
                  marginLeft: 5,
                }}
              >
                Verified by {cardData.verifierName}
              </Typography>
              <svg
                style={{ marginTop: 7, marginLeft: 8, marginRight: 8 }}
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="4"
                viewBox="0 0 3 4"
                fill="none"
              >
                <circle cx="1.5" cy="2" r="1.5" fill="#696B72" />
              </svg>
            </div>
          )}
          <Typography style={{ ...regText, fontSize: 17, marginLeft: 5 }}>
            Last updated {parseDate(cardData.timeLastUpdate)}
          </Typography>
        </div>

        <div style={{ display: 'flex', marginBottom: 10 }}>
          {renderRatingOptions()}
        </div>

        <div
          style={{
            maxWidth: '600px',
            padding: '0 10px',
          }}
        >
          <Typography style={{ ...boldText }}>
            What would you like to say?
          </Typography>
          <textarea
            placeholder="Write your review here..."
            maxLength="500"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
            style={{
              resize: 'none',
              width: '100%',
              maxWidth: '500px',
              height: '200px',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #D1D1D3',
            }}
          />
          <Typography
            style={{
              ...regText,
              alignSelf: 'flex-end', // Aligns the counter to the end of the flex-direction axis
              paddingRight: '10px', // Right padding for spacing from the container edge
              width: '100%', // Takes full width to allow flex-end to push it to the right
            }}
          >
            {reviewText.length} / 500
          </Typography>
        </div>
        {showRatingWarning && rating === 0 && (
          <Alert
            severity="warning"
            sx={{ mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Please select a star rating before submitting your review.
          </Alert>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <button
            style={{
              border: 'none',
              borderRadius: '8px',
              backgroundColor: '#0052cc',
              color: '#FFFFFF',
              cursor: 'pointer',
              fontWeight: 'bold',
              fontSize: '18px',
              display: 'flex', // Flex display as per .button class
              alignItems: 'center', // Center alignment as per .button class
              lineHeight: '2rem', // Line height as per .button class
              fontSize: '1.8rem', // Font size as per .button class
              padding: '1.7rem 2rem', // Padding as per .button class
              fontFamily:
                'Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif', // Font family as per .button class
              fontWeight: '700', // Font weight as per .button class
              width: '225px', // Set the width to 176 pixels
              height: '45px', // Set the height to 41 pixels
              justifyContent: 'center', // Ensure contents are centered within the button
            }}
            onClick={handlePostReview}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );

  const renderReviews = () => {
    return reviews.map((review, index) => (
      <div
        key={index}
        style={{ padding: '10px', borderRadius: '5px', margin: '10px 0' }}
      >
        <p>{review.text}</p>
        {/* <small>Posted on: {new Date(review.date).toLocaleDateString()}</small> */}
      </div>
    ));
  };

  return (
    <div className="resource-page">
      <Header landing={false} searchBar />
      {isLoading ? (
        <div className="resource-page__loading">
          {t('Please Wait Loading...')}
        </div>
      ) : isReview ? (
        isMobile ? (
          renderMobileReviewForm()
        ) : (
          renderReviewForm()
        )
      ) : cardData && isMobile ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '25px',
          }}
        >
          <div
            className="resource-page__left"
            style={{ marginTop: 5, maxWidth: '88%' }}
          >
            <button
              className="print-button"
              style={{
                marginTop: -20,
                display: 'flex',
                height: 40,
                // width: '153px',
                padding: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                // gap: '8px',
                backgroundColor: 'var(--FRM-White, #FFF)', // Background color
                border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
                borderRadius: '8px', // Border radius
                cursor: 'pointer', // Change cursor to pointer on hover
                marginBottom: 40,
              }}
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 5L4 10M4 10L9 15M4 10H16"
                  stroke="#2C2D30"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                style={{
                  ...boldText,
                  marginLeft: 10,
                  fontSize: 15,
                  marginBottom: 0,
                  minWidth: 140,
                }}
              >
                Back to search
              </Typography>
            </button>

            <div style={{ display: 'flex', marginTop: -20 }}>
              <Typography
                style={{
                  ...boldText,
                  fontSize: 33,
                  marginRight: 30,
                  lineHeight: '38px',
                }}
              >
                {cardData.name}
              </Typography>
            </div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              {cardData.verifiedBy && cardData.verifiedBy !== '' && (
                <div style={{ display: 'flex' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="7.375"
                      stroke="#2067D2"
                      strokeWidth="1.25"
                    />
                    <path
                      d="M6.49976 10L8.99976 12.5L13.4998 8"
                      stroke="#2067D2"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Typography
                    style={{
                      ...regText,
                      color: '#2067D2',
                      fontSize: 17,
                      marginLeft: 5,
                    }}
                  >
                    Verified by {cardData.verifierName}
                  </Typography>

                  <svg
                    style={{ marginTop: 7, marginLeft: 8, marginRight: 8 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="3"
                    height="4"
                    viewBox="0 0 3 4"
                    fill="none"
                  >
                    <circle cx="1.5" cy="2" r="1.5" fill="#696B72" />
                  </svg>
                </div>
              )}
              <Typography style={{ ...regText, fontSize: 17, marginLeft: 5 }}>
                Last updated {parseDate(cardData.timeLastUpdate)}
              </Typography>
            </div>

            {/* Show About if it exists */}
            {cardData.description &&
              cardData.description != '' &&
              cardData.description != ' ' && (
                <>
                  <Typography style={{ ...boldText }}>About</Typography>

                  <Typography style={{ ...regText }}>
                    {cardData.description}
                  </Typography>
                </>
              )}

            {cardData.tags.length > 0 &&
              cardData.tags[0] !== '' &&
              cardData.tags[0] !== ' ' && (
                <>
                  <Typography style={{ ...boldText, fontSize: 21 }}>
                    Tags
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {formatTags(cardData.tags).map((tag, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 30,
                        }}
                      >
                        {/* Optional Tag Icon */}
                        {/* <TagIcon tag={tag.toLowerCase()} /> */}
                        <Pills variant="general" text={tag} useBackground />
                      </div>
                    ))}
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="2"
                    viewBox="0 0 600 2"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                  </svg>
                </>
              )}

            {/* Show Services if they exist */}
            {cardData.services &&
              cardData.services[0].title &&
              cardData.services[0].title != '' &&
              cardData.services[0].title != ' ' && (
                <>
                  <Typography
                    style={{ ...boldText, marginTop: 20, marginBottom: 30 }}
                  >
                    Services
                  </Typography>

                  {cardData.services.map(
                    (service, index) =>
                      service.title != '' &&
                      service.title != ' ' && (
                        <div>
                          <Typography
                            style={{
                              ...regText,
                              fontWeight: 700,
                              marginBottom: 5,
                              marginTop: 25,
                            }}
                          >
                            {service.title}
                          </Typography>
                          <ul style={{ listStyleType: 'disc', marginLeft: 30 }}>
                            {service.details.map((detail, index) => (
                              <li>
                                {' '}
                                <Typography style={{ ...regText, margin: 3 }}>
                                  {detail}
                                </Typography>{' '}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                  )}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="2"
                    viewBox="0 0 600 2"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                  </svg>
                </>
              )}

            {/* Show Next Steps if they exist */}
            {cardData.nextSteps &&
            cardData.nextSteps.length > 0 &&
            cardData.nextSteps[0] !== '' &&
            cardData.nextSteps[0] !== ' ' ? (
              <>
                <Typography
                  style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
                >
                  Next Steps
                </Typography>
                <ul style={{ listStyleType: 'disc', marginLeft: 30 }}>
                  {cardData.nextSteps.map(
                    (nextStep, index) =>
                      nextStep !== '' &&
                      nextStep !== ' ' && (
                        <li key={index}>
                          <Typography
                            style={{
                              ...regText,
                              margin: 3,
                              wordWrap: 'break-word',
                            }}
                          >
                            {nextStep}
                          </Typography>
                        </li>
                      )
                  )}
                </ul>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </>
            ) : (
              <Typography
                style={{ ...regText, marginTop: 20, marginBottom: 20 }}
              >
                {' '}
              </Typography>
            )}

            {/* Show Languages if they exist */}
            {cardData.serviceLanguages &&
            cardData.serviceLanguages.length > 0 &&
            cardData.serviceLanguages[0] !== '' &&
            cardData.serviceLanguages[0] !== ' ' ? (
              <>
                <Typography
                  style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
                >
                  Languages Spoken
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Typography style={{ ...regText }}>
                    {cardData.serviceLanguages.length === 2
                      ? 'English, Spanish'
                      : cardData.serviceLanguages[0] === 'en'
                        ? 'English'
                        : 'Spanish'}
                  </Typography>
                </div>

                {/* Reviews Section */}
                <Typography
                  style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
                >
                  Reviews
                </Typography>
                {console.log('reviewData:', reviewData)}

                {reviewData && reviewData.length > 0 ? (
                  reviewData.map((review, index) => (
                    <React.Fragment key={review._id}>
                      <Reviews
                        reviewer={
                          `${review.firstName} ${review.lastName}` ||
                          'Anonymous'
                        }
                        reviewText={
                          review.reviewString || 'No review text available'
                        }
                        rating={review.stars || 0}
                        reviewTime={review.timeLastUpdated}
                      />
                      {index < reviewData.length - 1 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="2"
                          viewBox="0 0 600 2"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                        </svg>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p>No reviews available.</p>
                )}

                <svg
                  style={{ marginBottom: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </>
            ) : (
              <Typography
                style={{ ...regText, marginTop: 20, marginBottom: 20 }}
              >
                {' '}
              </Typography>
            )}

            {/* Show Website if it exists */}
            {cardData.website &&
              cardData.website != '' &&
              cardData.website != ' ' && (
                <>
                  <div style={{ display: 'flex' }}>
                    <svg
                      style={{ marginTop: 2, marginRight: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="7.375"
                        stroke="#2067D2"
                        strokeWidth="1.25"
                      />
                      <path
                        d="M3 7.5H17M3 12.5H17M10.6673 16.7556C13.7785 12.8856 13.7776 6.63741 10.6646 3.1579C10.3136 2.76554 9.70589 2.78634 9.37104 3.1926C6.2233 7.01168 6.20971 12.9205 9.33029 16.7576C9.67875 17.1861 10.3213 17.1861 10.6673 16.7556Z"
                        stroke="#2067D2"
                        strokeWidth="1.25"
                      />
                    </svg>
                    <Typography
                      style={{ ...boldText, fontSize: 18, marginBottom: 5 }}
                    >
                      Website
                    </Typography>
                  </div>
                  <Link
                    style={{
                      ...regText,
                      color: '#2067D2',
                      maxWidth: 200,
                      wordWrap: 'break-word',
                    }}
                    href={cardData.website}
                    underline="always"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cardData.website}
                  </Link>
                </>
              )}

            {cardData?.phone?.number && (
              <div>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <svg
                    style={{ marginTop: 2, marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M7.97086 6.25873C7.77547 5.06431 6.96159 3.51138 6.20078 2.80071C6.10955 2.71549 5.97976 2.68795 5.86107 2.72664C4.60067 3.13751 3.30415 4.4223 2.85126 5.71973C2.8348 5.76689 2.82864 5.81675 2.8319 5.86659C3.02483 8.81548 4.04984 11.3921 6.33725 13.7533C8.62466 16.1146 11.2771 17.0658 14.226 17.2587C14.2759 17.262 14.3257 17.2558 14.3729 17.2393C15.6703 16.7864 16.9551 15.4899 17.366 14.2295C17.4046 14.1108 17.3771 13.981 17.2919 13.8898C16.5812 13.129 15.0283 12.3151 13.8339 12.1197C13.7382 12.1041 13.6407 12.1281 13.562 12.1847C12.7975 12.7343 12.6127 12.9875 11.8922 13.3758C11.8375 13.4053 11.7767 13.4203 11.7148 13.4152C10.978 13.3546 9.42876 12.9131 8.28125 11.7422C7.13374 10.5712 6.66885 9.04746 6.60823 8.31061C6.60314 8.24872 6.61818 8.18791 6.64764 8.13325C7.03595 7.41272 7.35632 7.29508 7.90591 6.53056C7.96248 6.45187 7.9865 6.35436 7.97086 6.25873Z"
                      stroke="#2067D2"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Typography
                    style={{ ...boldText, fontSize: 18, marginBottom: 5 }}
                  >
                    Phone
                  </Typography>
                </div>

                <Link
                  style={{
                    ...regText,
                    color: '#2067D2',
                    wordWrap: 'break-word',
                  }}
                  href={cardData.website}
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatPhoneNumber(cardData?.phone?.number || '')}
                </Link>
              </div>
            )}

            {cardData?.email && (
              <div>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <svg
                    style={{ marginTop: 2, marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15 3H3C2.175 3 1.5075 3.675 1.5075 4.5L1.5 13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3ZM15 13.5H3V6L9 9.75L15 6V13.5ZM9 8.25L3 4.5H15L9 8.25Z"
                      fill="#2067D2"
                    />
                  </svg>
                  <Typography
                    style={{ ...boldText, fontSize: 18, marginBottom: 5 }}
                  >
                    E-mail
                  </Typography>
                </div>

                <Link
                  style={{ ...regText, color: '#2067D2' }}
                  href={`mailto:${cardData.email}`}
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {cardData.email}
                </Link>
              </div>
            )}

            <svg
              style={{ marginTop: 25, marginBottom: 20 }}
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="2"
              viewBox="0 0 600 2"
              fill="none"
              preserveAspectRatio="none"
            >
              <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
            </svg>

            {(cardData?.address?.street != 'Unknown' ||
              cardData?.hours ||
              cardData?.hours_string) && (
              <div>
                {cardData?.address?.street === 'Online' ||
                cardData?.address?.street === 'Unknown' ||
                cardData?.address?.street === '' ? (
                  <Typography style={{ ...boldText, fontSize: 22 }}>
                    Hours
                  </Typography>
                ) : (
                  <div>
                    <Typography style={{ ...boldText, fontSize: 22 }}>
                      Hours and Location
                    </Typography>

                    <div style={{ display: 'flex', marginTop: 20 }}>
                      <div style={{ flex: 1 }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M16.375 9C16.375 10.9954 15.109 12.9852 13.5832 14.6371C12.1884 16.1471 10.6666 17.2783 10 17.7447C9.33337 17.2783 7.81158 16.1471 6.41682 14.6371C4.89098 12.9852 3.625 10.9954 3.625 9C3.625 5.47918 6.47918 2.625 10 2.625C13.5208 2.625 16.375 5.47918 16.375 9Z"
                            stroke="#2067D2"
                            strokeWidth="1.25"
                            strokeLinejoin="round"
                          />
                          <circle
                            cx="10"
                            cy="9"
                            r="2"
                            stroke="#2067D2"
                            strokeWidth="1.25"
                          />
                        </svg>
                      </div>
                      <div style={{ flex: 8 }}>
                        <Typography
                          style={{
                            ...regText,
                            color: '#2C2D30',
                            marginBottom: 0,
                          }}
                        >
                          {cardData?.address?.street}
                        </Typography>
                        <Typography
                          style={{
                            ...regText,
                            color: '#2C2D30',
                            marginBottom: 5,
                          }}
                        >
                          {cardData?.address?.city}, {cardData?.address?.state}{' '}
                          {cardData?.address?.zipCode}
                        </Typography>

                        <Typography style={{ ...regText, marginBottom: 15 }}>
                          {distance}
                        </Typography>
                      </div>

                      <button
                        style={{
                          display: 'flex',
                          // width: '153px',
                          padding: '5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // gap: '8px',
                          backgroundColor: 'var(--FRM-White, #FFF)', // Background color
                          border:
                            '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
                          borderRadius: '8px', // Border radius
                          cursor: 'pointer', // Change cursor to pointer on hover
                          marginBottom: 15,
                        }}
                        onClick={() => {
                          handleOpenInMaps(cardData?.address);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M2.5 10L10.5 2L18.5 10L10.5 18L2.5 10Z"
                            stroke="#4B4C51"
                            strokeWidth="1.25"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 12V10.5C8 9.94772 8.44772 9.5 9 9.5H13M13 9.5L11.5 8M13 9.5L11.5 11"
                            stroke="#4B4C51"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <Typography
                          style={{
                            ...boldText,
                            marginLeft: 10,
                            fontSize: 15,
                            marginBottom: 0,
                          }}
                        >
                          Get Directions
                        </Typography>
                      </button>
                    </div>
                  </div>
                )}

                {cardData?.hours ? (
                  <pre style={{ ...regText }}>
                    {formatBusinessHours(cardData?.hours)}
                  </pre>
                ) : (
                  cardData?.hours_string && (
                    <pre style={{ ...regText }}>{cardData?.hours_string}</pre>
                  )
                )}

                <svg
                  style={{ marginBottom: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </div>
            )}

            <Typography
              style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
            >
              Reviews
            </Typography>
            <div
              style={{
                backgroundColor: '#F1F3F6',
                padding: '20px',
                margin: '20px 0',
                borderRadius: '4px',
              }}
            >
              <button
                onClick={handleWriteReviewClick}
                style={{
                  width: '166px',
                  height: '40px',
                  padding: '8px 24px',
                  gap: '8px',
                  borderRadius: '8px',
                  border: '1px solid #D1D1D3',
                  backgroundColor: '#FFFFFF',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                {' '}
                Write a Review
              </button>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="2"
              viewBox="0 0 600 2"
              fill="none"
              preserveAspectRatio="none"
            >
              <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
            </svg>
            <Typography style={{ ...regText, marginBottom: 15 }}>
              Help us improve the Florida Resource Map!
            </Typography>
            <Typography style={{ ...boldText, fontSize: 15, marginBottom: 0 }}>
              Please email us your questions or feedback at:
            </Typography>
            <Link
              style={{
                ...boldText,
                fontSize: 17,
                color: '#2067D2',
                marginBottom: 20,
              }}
              href={`mailto:${cardData.email}`}
              underline="always"
              target="_blank"
              rel="noopener noreferrer"
            >
              frm-support@floridainnovation.org
            </Link>
          </div>
        </div>
      ) : cardData ? (
        <div className="resource-page__content">
          <div className="resource-page__left" style={{ flex: 2 }}>
            <button
              className="print-button"
              style={{
                marginTop: -20,
                display: 'flex',
                height: 40,
                // width: '153px',
                padding: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                // gap: '8px',
                backgroundColor: 'var(--FRM-White, #FFF)', // Background color
                border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
                borderRadius: '8px', // Border radius
                cursor: 'pointer', // Change cursor to pointer on hover
                marginBottom: 40,
              }}
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9 5L4 10M4 10L9 15M4 10H16"
                  stroke="#2C2D30"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                style={{
                  ...boldText,
                  marginLeft: 10,
                  fontSize: 15,
                  marginBottom: 0,
                  minWidth: 50,
                }}
              >
                Back
              </Typography>
            </button>

            <div style={{ display: 'flex' }}>
              <Typography
                style={{
                  ...boldText,
                  fontSize: 33,
                  marginRight: 30,
                  lineHeight: '38px',
                }}
              >
                {cardData.name}
              </Typography>
              <button
                className="print-button"
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  marginTop: -5,
                  marginRight: 10,
                  height: 40,
                  // width: '153px',
                  padding: '5px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // gap: '8px',
                  backgroundColor: 'var(--FRM-White, #FFF)', // Background color
                  border: '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)', // Border
                  borderRadius: '8px', // Border radius
                  cursor: 'pointer', // Change cursor to pointer on hover
                  marginBottom: 15,
                }}
                onClick={() => {
                  window.print();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M6.5 7.5V4H13.5V7.5M6.5 7.5H13.5M6.5 7.5H5C3.89543 7.5 3 8.39543 3 9.5V14H6M13.5 7.5H15C16.1046 7.5 17 8.39543 17 9.5V14H14M6 14V12H14V14M6 14V16.5H14V14"
                    stroke="#4B4C51"
                    strokeWidth="1.25"
                    strokeLinejoin="round"
                  />
                  <circle cx="14.9168" cy="9.4395" r="0.7" fill="#4B4C51" />
                </svg>
                <Typography
                  style={{
                    ...boldText,
                    marginLeft: 10,
                    fontSize: 15,
                    marginBottom: 0,
                    minWidth: 80,
                  }}
                >
                  Print
                </Typography>
              </button>
            </div>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              {cardData.verifiedBy && cardData.verifiedBy !== '' && (
                <div style={{ display: 'flex' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="7.375"
                      stroke="#2067D2"
                      strokeWidth="1.25"
                    />
                    <path
                      d="M6.49976 10L8.99976 12.5L13.4998 8"
                      stroke="#2067D2"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <Typography
                    style={{
                      ...regText,
                      color: '#2067D2',
                      fontSize: 17,
                      marginLeft: 5,
                    }}
                  >
                    Verified by {cardData.verifierName}
                  </Typography>

                  <svg
                    style={{ marginTop: 7, marginLeft: 8, marginRight: 8 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="3"
                    height="4"
                    viewBox="0 0 3 4"
                    fill="none"
                  >
                    <circle cx="1.5" cy="2" r="1.5" fill="#696B72" />
                  </svg>
                </div>
              )}
              <Typography style={{ ...regText, fontSize: 17, marginLeft: 5 }}>
                Last updated {parseDate(cardData.timeLastUpdate)}
              </Typography>
            </div>
            {/* Show About if it exists */}
            {cardData.description &&
              cardData.description != '' &&
              cardData.description != ' ' && (
                <>
                  <Typography style={{ ...boldText }}>About</Typography>

                  <Typography style={{ ...regText }}>
                    {cardData.description}
                  </Typography>
                </>
              )}

            {/* Show Tags if they exist */}
            {cardData.tags.length > 0 &&
              cardData.tags[0] !== '' &&
              cardData.tags[0] !== ' ' && (
                <>
                  <Typography style={{ ...boldText, fontSize: 21 }}>
                    Tags
                  </Typography>

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    {formatTags(cardData.tags).map((tag, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: 30,
                        }}
                      >
                        {/* <TagIcon tag={tag.toLowerCase()} /> */}
                        <Pills variant="general" text={tag} useBackground />
                      </div>
                    ))}
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="2"
                    viewBox="0 0 600 2"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                  </svg>
                </>
              )}

            {/* Show Services if they exist */}
            {cardData.services &&
              cardData.services.length > 0 &&
              cardData.services[0].title &&
              cardData.services[0].title != '' &&
              cardData.services[0].title != ' ' && (
                <>
                  <Typography
                    style={{ ...boldText, marginTop: 20, marginBottom: 30 }}
                  >
                    Services
                  </Typography>

                  {cardData.services.map(
                    (service, index) =>
                      service.title != '' &&
                      service.title != ' ' && (
                        <div>
                          <Typography
                            style={{
                              ...regText,
                              fontWeight: 700,
                              marginBottom: 5,
                              marginTop: 25,
                            }}
                          >
                            {service.title}
                          </Typography>
                          <ul style={{ listStyleType: 'disc', marginLeft: 30 }}>
                            {service.details.map((detail, index) => (
                              <li>
                                {' '}
                                <Typography style={{ ...regText, margin: 3 }}>
                                  {detail}
                                </Typography>{' '}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                  )}

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="2"
                    viewBox="0 0 600 2"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                  </svg>
                </>
              )}

            {/* Show Next Steps if they exist */}
            {/* Show Next Steps if they exist */}
            {cardData.nextSteps &&
            cardData.nextSteps.length > 0 &&
            cardData.nextSteps[0] !== '' &&
            cardData.nextSteps[0] !== ' ' ? (
              <>
                <Typography
                  style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
                >
                  Next Steps
                </Typography>
                <ul style={{ listStyleType: 'disc', marginLeft: 30 }}>
                  {cardData.nextSteps.map(
                    (nextStep, index) =>
                      nextStep !== '' &&
                      nextStep !== ' ' && (
                        <li key={index}>
                          <Typography
                            style={{
                              ...regText,
                              margin: 3,
                              wordWrap: 'break-word',
                            }}
                          >
                            {nextStep}
                          </Typography>
                        </li>
                      )
                  )}
                </ul>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </>
            ) : (
              <Typography
                style={{ ...regText, marginTop: 20, marginBottom: 20 }}
              >
                {' '}
              </Typography>
            )}

            {/* Show Languages if they exist */}
            {cardData.serviceLanguages &&
            cardData.serviceLanguages.length > 0 &&
            cardData.serviceLanguages[0] !== '' &&
            cardData.serviceLanguages[0] !== ' ' ? (
              <>
                <Typography
                  style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
                >
                  Languages Spoken
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Typography style={{ ...regText }}>
                    {cardData.serviceLanguages.length === 2
                      ? 'English, Spanish'
                      : cardData.serviceLanguages[0] === 'en'
                        ? 'English'
                        : 'Spanish'}
                  </Typography>
                </div>
                {console.log('reviewData:', reviewData)}
                <svg
                  style={{ marginBottom: 10 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </>
            ) : (
              <Typography
                style={{ ...regText, marginTop: 20, marginBottom: 20 }}
              >
                {' '}
              </Typography>
            )}

            <Typography
              style={{ ...boldText, marginTop: 20, marginBottom: 20 }}
            >
              Reviews
            </Typography>
            <div
              style={{
                backgroundColor: '#F1F3F6',
                padding: '20px',
                margin: '20px 0',
                borderRadius: '4px',
              }}
            >
              <button
                onClick={handleWriteReviewClick}
                style={{
                  width: '166px',
                  height: '40px',
                  padding: '8px 24px',
                  gap: '8px',
                  borderRadius: '8px',
                  border: '1px solid #D1D1D3',
                  backgroundColor: '#FFFFFF',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  fontSize: '16px',
                }}
              >
                {' '}
                Write a Review
              </button>
            </div>

            {reviewData && reviewData.length > 0 ? (
              reviewData.map((review, index) => (
                <React.Fragment key={review._id}>
                  <Reviews
                    reviewer={
                      `${review.firstName} ${review.lastName}` || 'Anonymous'
                    }
                    reviewText={
                      review.reviewString || 'No review text available'
                    }
                    reviewTime={review.timeLastUpdated}
                    rating={review.stars || 0}
                  />
                  {index < reviewData.length - 1 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="2"
                      viewBox="0 0 600 2"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                    </svg>
                  )}
                </React.Fragment>
              ))
            ) : (
              <p>No reviews available.</p>
            )}
          </div>

          <div
            className="resource-page__right"
            style={{
              flex: 1.3,
              marginRight: '20rem',
              marginLeft: '1rem',
              marginTop: 70,
              maxWidth: 350,
            }}
          >
            {(cardData.website || cardData.phone || cardData.email) && (
              <div
                className="box-to-hide"
                style={{
                  border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
                  marginBottom: 50,
                  padding: 20,
                  borderRadius: 8,
                  background: 'var(--FRM-White, #FFF)',
                  boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
                }}
              >
                {/* Show Website if it exists */}
                {cardData.website &&
                  cardData.website != '' &&
                  cardData.website != ' ' && (
                    <>
                      <div style={{ display: 'flex' }}>
                        <svg
                          style={{ marginTop: 2, marginRight: 10 }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="7.375"
                            stroke="#2067D2"
                            strokeWidth="1.25"
                          />
                          <path
                            d="M3 7.5H17M3 12.5H17M10.6673 16.7556C13.7785 12.8856 13.7776 6.63741 10.6646 3.1579C10.3136 2.76554 9.70589 2.78634 9.37104 3.1926C6.2233 7.01168 6.20971 12.9205 9.33029 16.7576C9.67875 17.1861 10.3213 17.1861 10.6673 16.7556Z"
                            stroke="#2067D2"
                            strokeWidth="1.25"
                          />
                        </svg>
                        <Typography style={{ ...boldText, fontSize: 18 }}>
                          Website
                        </Typography>
                      </div>
                      <Link
                        style={{
                          ...regText,
                          color: '#2067D2',
                          maxWidth: 200,
                          wordWrap: 'break-word',
                        }}
                        href={cardData.website}
                        underline="always"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {cardData.website}
                      </Link>
                    </>
                  )}

                {cardData?.phone?.number && (
                  <div>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                      <svg
                        style={{ marginTop: 2, marginRight: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M7.97086 6.25873C7.77547 5.06431 6.96159 3.51138 6.20078 2.80071C6.10955 2.71549 5.97976 2.68795 5.86107 2.72664C4.60067 3.13751 3.30415 4.4223 2.85126 5.71973C2.8348 5.76689 2.82864 5.81675 2.8319 5.86659C3.02483 8.81548 4.04984 11.3921 6.33725 13.7533C8.62466 16.1146 11.2771 17.0658 14.226 17.2587C14.2759 17.262 14.3257 17.2558 14.3729 17.2393C15.6703 16.7864 16.9551 15.4899 17.366 14.2295C17.4046 14.1108 17.3771 13.981 17.2919 13.8898C16.5812 13.129 15.0283 12.3151 13.8339 12.1197C13.7382 12.1041 13.6407 12.1281 13.562 12.1847C12.7975 12.7343 12.6127 12.9875 11.8922 13.3758C11.8375 13.4053 11.7767 13.4203 11.7148 13.4152C10.978 13.3546 9.42876 12.9131 8.28125 11.7422C7.13374 10.5712 6.66885 9.04746 6.60823 8.31061C6.60314 8.24872 6.61818 8.18791 6.64764 8.13325C7.03595 7.41272 7.35632 7.29508 7.90591 6.53056C7.96248 6.45187 7.9865 6.35436 7.97086 6.25873Z"
                          stroke="#2067D2"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <Typography style={{ ...boldText, fontSize: 18 }}>
                        Phone
                      </Typography>
                    </div>

                    <Link
                      style={{
                        ...regText,
                        color: '#2067D2',
                        wordWrap: 'break-word',
                      }}
                      href={cardData.website}
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatPhoneNumber(cardData?.phone?.number || '')}
                    </Link>
                  </div>
                )}

                {cardData?.email && (
                  <div>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                      <svg
                        style={{ marginTop: 2, marginRight: 10 }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M15 3H3C2.175 3 1.5075 3.675 1.5075 4.5L1.5 13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3ZM15 13.5H3V6L9 9.75L15 6V13.5ZM9 8.25L3 4.5H15L9 8.25Z"
                          fill="#2067D2"
                        />
                      </svg>
                      <Typography style={{ ...boldText, fontSize: 18 }}>
                        E-mail
                      </Typography>
                    </div>

                    <Link
                      style={{ ...regText, color: '#2067D2' }}
                      href={`mailto:${cardData.email}`}
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {cardData.email}
                    </Link>
                  </div>
                )}
              </div>
            )}

            {/* Conditionally render the Hours and Location section if the data exists */}
            {(cardData?.address?.street != 'Unknown' ||
              cardData?.hours ||
              cardData?.hours_string) && (
              <div
                className="box-to-hide"
                style={{
                  border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
                  marginBottom: 50,
                  padding: 20,
                  borderRadius: 8,
                  background: 'var(--FRM-White, #FFF)',
                  boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
                }}
              >
                {cardData?.address?.street === 'Online' ||
                cardData?.address?.street === 'Unknown' ||
                cardData?.address?.street === '' ? (
                  <Typography style={{ ...boldText, fontSize: 22 }}>
                    Hours
                  </Typography>
                ) : (
                  <div>
                    <Typography style={{ ...boldText, fontSize: 22 }}>
                      Hours and Location
                    </Typography>

                    {cardData?.address && (
                      <div style={{ display: 'flex', marginTop: 20 }}>
                        <div style={{ flex: 1 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M16.375 9C16.375 10.9954 15.109 12.9852 13.5832 14.6371C12.1884 16.1471 10.6666 17.2783 10 17.7447C9.33337 17.2783 7.81158 16.1471 6.41682 14.6371C4.89098 12.9852 3.625 10.9954 3.625 9C3.625 5.47918 6.47918 2.625 10 2.625C13.5208 2.625 16.375 5.47918 16.375 9Z"
                              stroke="#2067D2"
                              strokeWidth="1.25"
                              strokeLinejoin="round"
                            />
                            <circle
                              cx="10"
                              cy="9"
                              r="2"
                              stroke="#2067D2"
                              strokeWidth="1.25"
                            />
                          </svg>
                        </div>
                        <div style={{ flex: 8 }}>
                          <Typography
                            style={{
                              ...regText,
                              color: '#2C2D30',
                              marginBottom: 0,
                            }}
                          >
                            {cardData?.address?.street}
                          </Typography>
                          <Typography
                            style={{
                              ...regText,
                              color: '#2C2D30',
                              marginBottom: 5,
                            }}
                          >
                            {cardData?.address?.city},{' '}
                            {cardData?.address?.state}{' '}
                            {cardData?.address?.zipCode}
                          </Typography>
                          <Typography style={{ ...regText, marginBottom: 15 }}>
                            {distance}
                          </Typography>
                        </div>
                      </div>
                    )}

                    {cardData?.address && (
                      <button
                        style={{
                          display: 'flex',
                          padding: '5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'var(--FRM-White, #FFF)',
                          border:
                            '2px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          marginBottom: 15,
                        }}
                        onClick={() => {
                          handleOpenInMaps(cardData?.address);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M2.5 10L10.5 2L18.5 10L10.5 18L2.5 10Z"
                            stroke="#4B4C51"
                            strokeWidth="1.25"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 12V10.5C8 9.94772 8.44772 9.5 9 9.5H13M13 9.5L11.5 8M13 9.5L11.5 11"
                            stroke="#4B4C51"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <Typography
                          style={{
                            ...boldText,
                            marginLeft: 10,
                            fontSize: 15,
                            marginBottom: 0,
                          }}
                        >
                          Get Directions
                        </Typography>
                      </button>
                    )}
                  </div>
                )}

                {cardData?.hours ? (
                  <pre style={{ ...regText }}>
                    {formatBusinessHours(cardData?.hours)}
                  </pre>
                ) : (
                  cardData?.hours_string && (
                    <pre style={{ ...regText }}>{cardData?.hours_string}</pre>
                  )
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="2"
                  viewBox="0 0 600 2"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path d="M0 1L600 1.00005" stroke="#D1D1D3" />
                </svg>
              </div>
            )}
            <div
              className="box-to-hide"
              style={{
                border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
                marginBottom: 50,
                padding: 20,
                borderRadius: 8,
                background: 'var(--FRM-White, #FFF)',
                boxShadow: '4px 4px 32px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              <Typography style={{ ...regText, marginBottom: 5 }}>
                Help us improve the Florida Resource Map!
              </Typography>
              <Typography style={{ ...boldText, fontSize: 15 }}>
                Please email us your questions or feedback at:
              </Typography>
              <Link
                style={{ ...boldText, fontSize: 17, color: '#2067D2' }}
                href={`mailto:${cardData.email}`}
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
              >
                frm-support@floridainnovation.org
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="resource-page__loading">
          {t('No such resource found')}
        </div>
      )}

      <Dialog
        open={login}
        onClose={handleCloseDialog}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            overflow: 'hidden',
          },
        }}
      >
        <LoginPrompt
          handleNav={handleNavigate}
          close={handleCloseDialog}
          save={false}
        />
      </Dialog>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thank you for your review!</DialogTitle>
        <DialogContent>
          <Typography>
            Your review has been submitted and is under review by our team. It
            will be published shortly if it meets our guidelines.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Footer className="footer" />
    </div>
  );
}
