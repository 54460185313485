import React, { useState } from 'react';
import PropTypes from 'prop-types';

// TODO: We have got to get better names that valueOne and valueTwo
export const Toggle = ({ valueOne, valueTwo, onMap }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="toggle">
      <div
        className={`toggle-button ${toggle ? '' : 'toggle-button--selected'}`}
        onClick={() => {
          setToggle(false);
          onMap(false);
        }}
      >
        {valueOne}
      </div>
      <div className="toggle-separator">&nbsp;</div>
      <div
        className={`toggle-button ${toggle ? 'toggle-button--selected' : ''}`}
        onClick={() => {
          setToggle(true);
          onMap(true);
        }}
      >
        {valueTwo}
      </div>
    </div>
  );
};

Toggle.propTypes = {
  /**
   * The text value for the left button.
   */
  valueOne: PropTypes.string,
  /**
   * The text value for the right button.
   */
  valueTwo: PropTypes.string,
  onMap: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  valueOne: 'List',
  valueTwo: 'Map',
};
