import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const locationPermissionGranted = cookies.get('locationPermissionGranted');

const distanceSlice = createSlice({
  name: 'distance',
  initialState: {
    showDist: locationPermissionGranted === 'true' ? true : false,
  },
  reducers: {
    toggle: (state, action) => {
      state.showDist = action.payload;
    },
  },
});
export const distanceActions = distanceSlice.actions;
export default distanceSlice.reducer;
