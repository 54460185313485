import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, TextField, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { axiosPrivate } from '../../apis/backend';

export const PartnerDropdown = ({
  partners,
  selectedPartner,
  setSelectedPartner,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleSelectPartner = (partner) => {
    setSelectedPartner(partner);
    handleDropdownClose();
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Button
        variant="outlined"
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ mb: 2, textTransform: 'none' }}
      >
        {selectedPartner ? selectedPartner.name : 'Select Partner'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        {partners.length > 0 ? (
          partners.map((partner) => (
            <MenuItem
              key={partner._id}
              onClick={() => handleSelectPartner(partner)}
            >
              {partner.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Partners Available</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

PartnerDropdown.propTypes = {
  partners: PropTypes.array.isRequired,
  selectedPartner: PropTypes.object,
  setSelectedPartner: PropTypes.func.isRequired,
};

export const ImportListDropdown = ({
  importLists,
  selectedImportList,
  setSelectedImportList,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleSelectImportList = (importList) => {
    setSelectedImportList(importList);
    handleDropdownClose();
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none' }}
      >
        {selectedImportList ? selectedImportList.name : 'Select Import List'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        {importLists.length > 0 ? (
          importLists.map((importList) => (
            <MenuItem
              key={importList._id}
              onClick={() => handleSelectImportList(importList)}
            >
              {importList.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Import Lists Available</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

ImportListDropdown.propTypes = {
  importLists: PropTypes.array.isRequired,
  selectedImportList: PropTypes.object,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add and Remove Partner Buttons
const AddPartner = ({ partners, setPartners }) => {
  const [newPartnerName, setNewPartnerName] = useState('');

  const addNewPartner = async () => {
    if (!newPartnerName.trim())
      return window.alert('Please enter a name for the new partner');
    try {
      const response = await axiosPrivate.post(
        `/partner/single?name=${encodeURIComponent(newPartnerName.trim())}`
      );
      setPartners([...partners, response.data.payload.partner]);
      setNewPartnerName('');
      window.alert(
        `New partner "${response.data.payload.partner.name}" added successfully.`
      );
    } catch (error) {
      console.error(
        'Error adding partner:',
        error.response?.data?.errMsg || error.message
      );
      window.alert(
        `Error adding partner: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        label="New Partner Name"
        variant="outlined"
        value={newPartnerName}
        onChange={(e) => setNewPartnerName(e.target.value)}
        sx={{ mb: 2, width: '100%' }}
      />
      <Button variant="contained" onClick={addNewPartner}>
        Add Partner
      </Button>
    </Box>
  );
};

AddPartner.propTypes = {
  partners: PropTypes.array.isRequired,
  setPartners: PropTypes.func.isRequired,
};

const RemovePartnerButton = ({
  selectedPartner,
  setPartners,
  setSelectedPartner,
  setSelectedImportList,
}) => {
  const removePartner = async () => {
    if (!selectedPartner)
      return window.alert('Please select a partner to remove.');
    try {
      const response = await axiosPrivate.delete(
        `/partner/single?id=${selectedPartner._id}`
      );
      if (response.data.errCode === '0') {
        setPartners((prev) =>
          prev.filter((p) => p._id !== selectedPartner._id)
        );
        window.alert(`Partner "${selectedPartner.name}" removed successfully.`);
        setSelectedPartner(null);
        setSelectedImportList(null);
      } else {
        window.alert(`Error removing partner: ${response.data.errMsg}`);
      }
    } catch (error) {
      window.alert(
        `Error removing partner: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      onClick={removePartner}
      disabled={!selectedPartner}
    >
      Remove Partner
    </Button>
  );
};

RemovePartnerButton.propTypes = {
  selectedPartner: PropTypes.object,
  setPartners: PropTypes.func.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  setImportLists: PropTypes.func.isRequired,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add and Remove Import List Buttons
export const AddImportListButton = ({
  selectedPartner,
  importLists,
  setImportLists,
}) => {
  const [newImportListName, setNewImportListName] = useState('');

  const createImportListAndAdd = async () => {
    if (!selectedPartner) return window.alert('Please select a partner first.');
    if (!newImportListName.trim())
      return window.alert('Please enter a name for the import list.');

    try {
      const response = await axiosPrivate.post(
        `/partner/importList?id=${selectedPartner._id}&name=${encodeURIComponent(newImportListName.trim())}`
      );
      setImportLists([...importLists, response.data.payload.importList]);
      setNewImportListName('');
      window.alert(
        `Import list "${newImportListName.trim()}" added to ${selectedPartner.name}.`
      );
    } catch (error) {
      console.error(
        'Error creating and adding import list:',
        error.response?.data?.errMsg || error.message
      );
      window.alert(
        `Error creating import list: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <TextField
        label="New Import List Name"
        variant="outlined"
        value={newImportListName}
        onChange={(e) => setNewImportListName(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={createImportListAndAdd}
        disabled={!selectedPartner}
      >
        Add Import List
      </Button>
    </Box>
  );
};

AddImportListButton.propTypes = {
  selectedPartner: PropTypes.object,
  importLists: PropTypes.array.isRequired,
  setImportLists: PropTypes.func.isRequired,
};

const RemoveImportListButton = ({
  selectedPartner,
  selectedImportList,
  setImportLists,
  setSelectedImportList,
}) => {
  const removeImportList = async () => {
    if (!selectedPartner || !selectedImportList)
      return window.alert(
        'Please select a partner and an import list to remove.'
      );
    try {
      await axiosPrivate.delete(
        `/partner/importList?id=${selectedPartner._id}&importListId=${selectedImportList._id}`
      );
      setImportLists((prev) =>
        prev.filter((list) => list._id !== selectedImportList._id)
      );
      setSelectedImportList(null);
      window.alert(
        `Import list removed from partner "${selectedPartner.name}".`
      );
    } catch (error) {
      window.alert(
        `Error removing import list: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Button
      sx={{ height: '100%' }}
      variant="contained"
      color="error"
      onClick={removeImportList}
      disabled={!selectedPartner || !selectedImportList}
    >
      Remove Import List
    </Button>
  );
};

RemoveImportListButton.propTypes = {
  selectedPartner: PropTypes.object,
  selectedImportList: PropTypes.object,
  setImportLists: PropTypes.func.isRequired,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add and Remove User to/from Import List
const AddUserToImportListButton = ({ selectedPartner, selectedImportList }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');

  // Fetch all users from the database
  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedPartner) {
        try {
          const response = await axiosPrivate.get(
            `/partner/single?id=${selectedPartner._id}`
          );
          console.log('response:', response.data.payload.partner.users);
          setUsers(response.data.payload.partner.users || []);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      }
    };
    fetchUsers();
  }, [selectedPartner]);

  const addUserToPartner = async () => {
    if (!selectedPartner || !selectedUserId) {
      return window.alert('Please select a partner and a user.');
    }

    try {
      const response = await axiosPrivate.post(
        `/partner/${selectedPartner._id}/addUser`,
        { userId: selectedUserId }
      );
      window.alert(`User added to partner "${selectedPartner.name}".`);
      setSelectedUserId('');
    } catch (error) {
      console.error(
        'Error adding user:',
        error.response?.data?.errMsg || error.message
      );
      window.alert(
        `Error adding user: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <TextField
        select
        label="Select User"
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
        sx={{ minWidth: 200 }}
      >
        {users.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {`${user.first || ''} ${user.last || ''}`.trim() || 'Unnamed User'}{' '}
            {/* Display full name with fallback */}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        onClick={addUserToPartner}
        disabled={!selectedPartner || !selectedUserId}
      >
        Add User
      </Button>
    </Box>
  );
};

AddUserToImportListButton.propTypes = {
  selectedPartner: PropTypes.object,
  selectedImportList: PropTypes.object,
};

export function DevButtons() {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [importLists, setImportLists] = useState([]);
  const [selectedImportList, setSelectedImportList] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axiosPrivate.get('/partner/all');
        if (response.data.errCode === '0') {
          setPartners(response.data.payload.partners || []);
        } else {
          console.error('Error fetching partners:', response.data.errMsg);
          window.alert(`Error fetching partners: ${response.data.errMsg}`);
        }
      } catch (error) {
        console.error(
          'Error fetching partners:',
          error.response?.data?.errMsg || error.message
        );
        window.alert(
          `Error fetching partners: ${error.response?.data?.errMsg || error.message}`
        );
      }
    };
    const fetchImportLists = async () => {
      try {
        const response = await axiosPrivate.get(
          `/partner/single?id=${selectedPartner._id}`
        );
        console.log(
          'response:',
          response.data.payload.partner.resourceImportLists
        );
        setImportLists(response.data.payload.partner.resourceImportLists || []);
      } catch (error) {
        console.error('Error fetching import lists:', error);
      }
    };
    fetchPartners();
    if (selectedPartner) fetchImportLists();
  }, [selectedPartner]);

  return (
    <Box sx={{ p: 2 }}>
      <PartnerDropdown
        partners={partners}
        selectedPartner={selectedPartner}
        setSelectedPartner={setSelectedPartner}
      />
      <AddPartner partners={partners} setPartners={setPartners} />
      <RemovePartnerButton
        selectedPartner={selectedPartner}
        setPartners={setPartners}
        setSelectedPartner={setSelectedPartner}
        setImportLists={setImportLists}
        setSelectedImportList={setSelectedImportList}
      />
      <AddImportListButton
        selectedPartner={selectedPartner}
        importLists={importLists}
        setImportLists={setImportLists}
      />
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <ImportListDropdown
          importLists={importLists}
          selectedImportList={selectedImportList}
          setSelectedImportList={setSelectedImportList}
        />
        <RemoveImportListButton
          selectedPartner={selectedPartner}
          selectedImportList={selectedImportList}
          setImportLists={setImportLists}
          setSelectedImportList={setSelectedImportList}
        />
      </Box>
      <AddUserToImportListButton selectedPartner={selectedPartner} />
    </Box>
  );
}
