import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/main.css';

export function AccordionItem({ item, index, clicked, toggle }) {
  return (
    <div className="section-box">
      <button
        type="button"
        className="section-title button"
        onClick={() => toggle(index)}
        key={index}
      >
        <h2 className="heading-secondary">{item.title}</h2>
        <div>{clicked === index ? '-' : '+'}</div>
      </button>
      {clicked === index && (
        <div className="section-description">{item.content}</div>
      )}
    </div>
  );
}

AccordionItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  clicked: PropTypes.bool,
  toggle: PropTypes.func,
};

AccordionItem.defaultProps = {
  item: 'Accordion Title',
  index: 1,
  clicked: true,
  toggle: false,
};
