import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import '../../styles/main.css';
import IonIcon from '@reacticons/ionicons';
import { coordsActions } from '../../store/coords-slice';
import Cookies from 'universal-cookie';
import { distanceActions } from '../../store/distance-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';

SearchSuggestions.propTypes = {
  location: PropTypes.bool,
  term: PropTypes.string,
  searchTerm: PropTypes.array,
  submitHandler: PropTypes.func,
  setLocationFocus: PropTypes.func,
  onTermClick: PropTypes.func,
};

// TODO: width never assigned?
export function SearchSuggestions(props) {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const currentCoords = cookies.get('location');

  // This is the screenwidth state for breakpoints
  const [setWidth] = useState(window.innerWidth);
  const handleCurrentLocation = () => {
    if (currentCoords) {
      dispatch(enteredLocationActions.handleLocationInput('Current Location'));
      dispatch(coordsActions.logCoords(currentCoords));
      if (window.location.pathname === '/search') {
        props.submitHandler(undefined, false);
      }
      props.setLocationFocus(false);
    } else {
      const successCallback = (position) => {
        const cookies = new Cookies();
        cookies.set('locationPermissionGranted', 'true', { path: '/' });
        const location = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        cookies.set('location', JSON.stringify(location), { path: '/' });
        dispatch(
          enteredLocationActions.handleLocationInput('Current Location')
        );
        dispatch(coordsActions.logCoords(location));
        dispatch(distanceActions.toggle(true));
        if (window.location.pathname === '/search') {
          props.submitHandler(undefined, false);
        }
        props.setLocationFocus(false);
      };
      const errorCallback = (error) => {
        if (error.code === 1) {
          window.alert('Please allow your location access');
        }
      };
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
        enableHighAccuracy: true,
        timeout: 5000,
      });
    }
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <div className="search-suggestions" onClick={(e) => e.stopPropagation()}>
      <div className={`search-results search-results__currentlocation`}>
        {props.location && (
          <span
            onClick={handleCurrentLocation}
            className="search-item search-item__location"
            id="list__0"
          >
            <IonIcon className="search-item__icon" name="navigate-outline" />
            <p>Current Location</p>
          </span>
        )}
        {/* {props.term && (
                    <span
                        className="search-item"
                        id="list__0"
                        onClick={() => props.setLocationFocus(false)}
                    >
                        <p>"{props.term}"</p>
                    </span>
                )} */}
      </div>

      {props.searchTerm.length !== 0 && (
        <div
          className={`search-results ${props.location ? 'search-results__location' : ''}`}
        >
          {props.searchTerm.slice(0, 4).map((value, index) => (
            <span
              key={index}
              className="search-item"
              id={props.location ? `list__${index + 1}` : `list__${index}`}
              onClick={() => {
                return props.location
                  ? (() => {
                      dispatch(
                        coordsActions.logCoords({
                          lat: Number(value.lat),
                          lng: Number(value.lon),
                        })
                      );
                      dispatch(
                        enteredLocationActions.handleLocationInput(
                          value.display_name
                        )
                      );
                      if (window.location.pathname === '/search') {
                        props.submitHandler(undefined, false);
                      }
                      props.setLocationFocus(false);
                    })()
                  : (() => props.onTermClick(value.title))();
              }}
            >
              <p>{value.display_name} </p>
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
