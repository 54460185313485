// To disconnect test data and connect backend, follow the comments flagged with CONNECT.

import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Popover,
  Select,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Include in previous import?
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Menu from '@mui/material/Menu'; // Corrected import
import MenuItem from '@mui/material/MenuItem'; // There must be a faster way to do this
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Input from '@mui/material/Input';

import Papa from 'papaparse';

import {
  Button as FCIButton,
  Footer,
  Header,
  MyResourcesList,
  StyledDropzone,
  HoursModal,
  PendingResourcesList,
  UsersList,
  ApprovedResourcesList,
  Map,
  Pagination1,
} from '../../components';

import { axiosPrivate } from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

const ManageResources = () => {
  const { t } = useTranslation();
  const fileInputRef = React.useRef();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  // Sam's attempt to get the list of resources
  const [approvedResources, setApprovedResources] = useState('loading');
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [active, setActive] = useState(-1);
  const currentPage = useSelector((state) => state.currentPage.page);

  // useEffect(() => {
  //   if (currentPage !== searchParams.get('page')) {
  //     dispatch(currentPageActions.pageHandler(searchParams.get('page')));
  //   }
  // }, [searchParams.get('page')]);

  const getApprovedResources = async (page = undefined) => {
    try {
      const response = await axiosPrivate.get('/user/search', {
        params: {
          limit: 10,
          search: searchText,
          page: isNaN(page) ? 0 : page - 1,
        },
      });
      const resources = response?.data?.payload?.results;

      console.log('Approved Resources:', resources); // Log the fetched resources

      setApprovedResources(resources);
      if (resources) {
        setTotalPages(response?.data?.payload?.totalPages);
        console.log('total pages', totalPages);
      } else {
        setTotalPages(0);
      }
    } catch (error) {
      console.error(
        'Error during authentication:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  useEffect(() => {
    getApprovedResources();
  }, []);

  // Copy of attempt for pending resources
  const [pendingResources, setPendingResources] = useState('loading');
  const getPendingResources = async () => {
    try {
      const response = await axiosPrivate.get('/admin/pendingResources');
      const response2 = await axiosPrivate.get('/admin/otherResources');

      const pendingResources = response.data.payload.pendingResources.concat(
        response2.data.payload.otherResources
      );

      console.log('Pending Resources:', pendingResources); // Log the pending resources

      setPendingResources(pendingResources);
    } catch (error) {
      console.error(
        'Error during authentication:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  useEffect(() => {
    getPendingResources();
  }, []);

  const [deleteConfirmation, setDeleteConfirmationOpen] = React.useState(false);
  const [resourceToDelete, setResourceToDelete] = React.useState(null);

  const handleDeleteConfirmationOpen = (id) => {
    console.log('Deleting resource with ID:', id); // Verify this ID is correct
    setResourceToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const deleteResource = async (_id) => {
    try {
      await axiosPrivate.delete(`/resource/single?id=${_id}`);
      console.log(`Resource with ID ${_id} deleted`);
      getApprovedResources();
      getPendingResources();
    } catch (error) {
      console.error(
        'Error during deleting resource:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  const changeResourceStatus = async (_id, status) => {
    try {
      await axiosPrivate.put(`/resource/status?_id=${_id}&newStatus=${status}`);
      console.log(`Successfully updated resource ${_id} status to ${status}`);
      getApprovedResources();
      getPendingResources();
    } catch (error) {
      console.error(
        'Error during changing resource status:',
        error.response?.data?.errMsg || error.message
      );
    }
  };

  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  const testResources = [
    {
      name: 'Frozen yogurt',
      role: 'owner',
      status: 'approved',
      id: '1',
    },
    {
      name: 'Ice cream sandwich',
      role: 'editor',
      status: 'pending',
      id: '2',
    },
    {
      name: 'Eclair',
      role: 'owner',
      status: 'declined',
      id: '3',
    },
    {
      name: 'Muffin',
      role: 'editor', // What does the role do?
      status: 'declined',
      id: '4',
    },
  ];

  const buttonTextStyle = {
    fontFamily: 'Nunito',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    // lineHeight: 4
    textTransform: 'none',
  };

  // Dropdown menu controls

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [menuAnchors, setMenuAnchors] = useState({});

  const handleMenuClick2 = (event, id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleMenuClose2 = (id) => {
    setMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const [buttonMenuAnchors, setButtonMenuAnchors] = useState({});

  const handleButtonMenuClick = (event, id) => {
    setButtonMenuAnchors((prev) => ({
      ...prev,
      [id]: event.currentTarget,
    }));
  };

  const handleButtonMenuClose = (id) => {
    setButtonMenuAnchors((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Box sx={{ py: '30px' }}>
        <Container
          component="main"
          maxWidth="lg"
          style={{
            border: '1px solid #CACCD3',
            borderRadius: '10px',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              mb: '2rem',
              marginTop: '2rem',
            }}
          >
            <Box>
              {' '}
              {/*What does this one even do?*/}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between', // This spreads out the child elements
                  alignItems: 'center', // This vertically centers the items in the container
                  width: '100%', // Ensures the container takes full width
                }}
              >
                <div
                  style={{
                    marginLeft: '10px',
                    'font-family': 'Nunito',
                    'font-size': '28px',
                    'font-style': 'normal',
                    'font-weight': '700',
                    'line-height': '44px',
                  }}
                >
                  {t('Manage')} {t('Resource.Plural')}
                </div>
              </div>
              <br />
              <Container>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    getApprovedResources(0);
                    // setActive(0);
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    {/* <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />   How to get this inside the search? */}
                    <TextField
                      // label="Search"
                      variant="outlined"
                      fullWidth
                      value={searchText}
                      onChange={handleSearchChange}
                      placeholder={t('Search for a listing here...')}
                      //"Search for a listing here..."
                      // style={{ textAlign: 'right' }}

                      InputProps={{
                        style: {
                          // inputComponent: <Input style={{ textAlign: 'right' }} />,
                          borderRadius: '3rem',
                          fontSize: '1.5rem',
                          height: '4.5rem',
                          // marginLeft: '',
                          // paddingLeft: "15px",
                          // textAlign: "right"
                        },
                        startAdornment: (
                          <Box
                            style={{ marginRight: '10px', marginTop: '5px' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_4633_3614)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.5 2C9.1446 2.00012 7.80887 2.32436 6.60427 2.94569C5.39966 3.56702 4.3611 4.46742 3.57525 5.57175C2.78939 6.67609 2.27902 7.95235 2.08672 9.29404C1.89442 10.6357 2.02576 12.004 2.46979 13.2846C2.91382 14.5652 3.65766 15.7211 4.63925 16.6557C5.62084 17.5904 6.81171 18.2768 8.11252 18.6576C9.41333 19.0384 10.7864 19.1026 12.117 18.8449C13.4477 18.5872 14.6975 18.015 15.762 17.176L19.414 20.828C19.6026 21.0102 19.8552 21.111 20.1174 21.1087C20.3796 21.1064 20.6304 21.0012 20.8158 20.8158C21.0012 20.6304 21.1064 20.3796 21.1087 20.1174C21.111 19.8552 21.0102 19.6026 20.828 19.414L17.176 15.762C18.164 14.5086 18.7792 13.0024 18.9511 11.4157C19.123 9.82905 18.8448 8.22602 18.1482 6.79009C17.4517 5.35417 16.3649 4.14336 15.0123 3.29623C13.6597 2.44911 12.096 1.99989 10.5 2ZM4.00001 10.5C4.00001 8.77609 4.68483 7.12279 5.90382 5.90381C7.1228 4.68482 8.7761 4 10.5 4C12.2239 4 13.8772 4.68482 15.0962 5.90381C16.3152 7.12279 17 8.77609 17 10.5C17 12.2239 16.3152 13.8772 15.0962 15.0962C13.8772 16.3152 12.2239 17 10.5 17C8.7761 17 7.1228 16.3152 5.90382 15.0962C4.68483 13.8772 4.00001 12.2239 4.00001 10.5Z"
                                  fill="#666E87"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4633_3614">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Box>
                        ),
                      }}
                    />
                  </Box>
                </form>
              </Container>
              {/* Would a virtualized table be better? */}
              <div style={{ height: '20px' }} />
              {/* First table: Approved resources only */}
              {approvedResources === 'loading' ? (
                <></>
              ) : approvedResources.length > 0 ? ( // Check if there are resources
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width="420px">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Resource.Singular').toUpperCase()}{' '}
                          {t('Name').toUpperCase()} <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div style={{ ...buttonTextStyle }}>
                          {t('Status').toUpperCase()} <ArrowDropUpIcon />
                        </div>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {approvedResources.map(
                      (
                        row,
                        index // CONNECT: Change testResources to approvedResources to connect to backend
                      ) => (
                        <TableRow
                          key={row._id || row.id}
                          sx={{
                            ...buttonTextStyle,
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                ...buttonTextStyle,
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              {row.name}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            {row.status === 'pending' ? (
                              <Button
                                onClick={(event) =>
                                  handleButtonMenuClick(event, row._id)
                                }
                                style={{
                                  width: '120px',
                                  backgroundColor: '#E2EAFD',
                                  padding: '6px',
                                  marginLeft: 'auto',
                                  borderRadius: '4px',
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    style={{
                                      paddingTop: '2px',
                                      marginLeft: '-20px',
                                    }}
                                  >
                                    <path
                                      d="M8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8.00004C1.33334 4.31804 4.31801 1.33337 8.00001 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00001 14.6667ZM8.00001 13.3334C9.4145 13.3334 10.7711 12.7715 11.7712 11.7713C12.7714 10.7711 13.3333 9.41453 13.3333 8.00004C13.3333 6.58555 12.7714 5.229 11.7712 4.2288C10.7711 3.22861 9.4145 2.66671 8.00001 2.66671C6.58552 2.66671 5.22897 3.22861 4.22877 4.2288C3.22858 5.229 2.66668 6.58555 2.66668 8.00004C2.66668 9.41453 3.22858 10.7711 4.22877 11.7713C5.22897 12.7715 6.58552 13.3334 8.00001 13.3334ZM8.66668 8.00004H10.6667C11.0349 8.00004 11.3333 8.29852 11.3333 8.66671C11.3333 9.0349 11.0349 9.33337 10.6667 9.33337H7.92594C7.59866 9.33337 7.33334 9.06806 7.33334 8.74078V7.00004V5.33337C7.33334 4.96518 7.63182 4.66671 8.00001 4.66671C8.3682 4.66671 8.66668 4.96518 8.66668 5.33337V8.00004Z"
                                      fill="#1A53D0"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#1A53D0' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Pending')}
                                  </Typography>
                                </Stack>
                              </Button>
                            ) : (
                              <Button
                                onClick={(event) =>
                                  handleButtonMenuClick(event, row._id)
                                }
                                style={{
                                  width: '120px',
                                  backgroundColor: '#DCFCDB',
                                  padding: '6px',
                                  borderRadius: '4px',
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  spacing={
                                    2
                                  } /* This feels like way too many things */
                                >
                                  {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    style={{
                                      paddingTop: '2px',
                                      marginLeft: '-9px',
                                    }}
                                  >
                                    <path
                                      d="M7.15899 11.7076C6.99755 11.8691 6.73581 11.8691 6.57437 11.7076L3.54168 8.67494C3.27935 8.41261 3.27935 7.98728 3.54168 7.72494C3.80402 7.46261 4.22935 7.46261 4.49168 7.72494L6.86668 10.0999L12.5083 4.45828C12.7707 4.19594 13.196 4.19594 13.4583 4.45828C13.7207 4.72061 13.7207 5.14594 13.4583 5.40828L7.15899 11.7076Z"
                                      fill="#39A837"
                                    />
                                  </svg>
                                  <Typography
                                    sx={{
                                      ...buttonTextStyle,
                                      color:
                                        '#39A837' /* How to get the actual font from the internet? */,
                                    }}
                                  >
                                    {t('Approved')}
                                  </Typography>
                                </Stack>
                              </Button>
                            )}

                            <Menu
                              anchorEl={buttonMenuAnchors[row?._id]}
                              open={Boolean(buttonMenuAnchors[row?._id])}
                              onClose={() => handleButtonMenuClose(row._id)}
                            >
                              <Typography
                                sx={{
                                  ...buttonTextStyle,
                                  fontSize: '15px',
                                  paddingTop: '5px',
                                  paddingLeft:
                                    '10px' /* How to get the actual font from the internet? */,
                                }}
                              >
                                {t('Change')} {t('Status')}
                              </Typography>

                              <MenuItem
                                onClick={() =>
                                  changeResourceStatus(row._id, 'approved')
                                } /* Not actually necessary but makes things consistent and easy */
                              >
                                <div
                                  style={{
                                    width: '120px',
                                    backgroundColor: '#DCFCDB',
                                    padding: '6px',
                                    marginLeft: 'auto',
                                    borderRadius: '4px',
                                    margin: '7px',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={
                                      2
                                    } /* This feels like way too many things */
                                  >
                                    {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      style={{ paddingTop: '2px' }}
                                    >
                                      <path
                                        d="M7.15899 11.7076C6.99755 11.8691 6.73581 11.8691 6.57437 11.7076L3.54168 8.67494C3.27935 8.41261 3.27935 7.98728 3.54168 7.72494C3.80402 7.46261 4.22935 7.46261 4.49168 7.72494L6.86668 10.0999L12.5083 4.45828C12.7707 4.19594 13.196 4.19594 13.4583 4.45828C13.7207 4.72061 13.7207 5.14594 13.4583 5.40828L7.15899 11.7076Z"
                                        fill="#39A837"
                                      />
                                    </svg>
                                    <Typography
                                      sx={{
                                        ...buttonTextStyle,
                                        color:
                                          '#39A837' /* How to get the actual font from the internet? */,
                                      }}
                                    >
                                      {t('Approved')}
                                    </Typography>
                                  </Stack>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  changeResourceStatus(
                                    row.resourcedId,
                                    'pending'
                                  )
                                }
                              >
                                <div
                                  style={{
                                    width: '120px',
                                    backgroundColor: '#E2EAFD',
                                    padding: '6px',
                                    marginLeft: 'auto',
                                    borderRadius: '4px',
                                    margin: '7px',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={
                                      2
                                    } /* This feels like way too many things */
                                  >
                                    {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}

                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      style={{ paddingTop: '2px' }}
                                    >
                                      <path
                                        d="M8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8.00004C1.33334 4.31804 4.31801 1.33337 8.00001 1.33337C11.682 1.33337 14.6667 4.31804 14.6667 8.00004C14.6667 11.682 11.682 14.6667 8.00001 14.6667ZM8.00001 13.3334C9.4145 13.3334 10.7711 12.7715 11.7712 11.7713C12.7714 10.7711 13.3333 9.41453 13.3333 8.00004C13.3333 6.58555 12.7714 5.229 11.7712 4.2288C10.7711 3.22861 9.4145 2.66671 8.00001 2.66671C6.58552 2.66671 5.22897 3.22861 4.22877 4.2288C3.22858 5.229 2.66668 6.58555 2.66668 8.00004C2.66668 9.41453 3.22858 10.7711 4.22877 11.7713C5.22897 12.7715 6.58552 13.3334 8.00001 13.3334ZM8.66668 8.00004H10.6667C11.0349 8.00004 11.3333 8.29852 11.3333 8.66671C11.3333 9.0349 11.0349 9.33337 10.6667 9.33337H7.92594C7.59866 9.33337 7.33334 9.06806 7.33334 8.74078V7.00004V5.33337C7.33334 4.96518 7.63182 4.66671 8.00001 4.66671C8.3682 4.66671 8.66668 4.96518 8.66668 5.33337V8.00004Z"
                                        fill="#1A53D0"
                                      />
                                    </svg>
                                    <Typography
                                      sx={{
                                        ...buttonTextStyle,
                                        color:
                                          '#1A53D0' /* How to get the actual font from the internet? */,
                                      }}
                                    >
                                      {t('Pending')}
                                    </Typography>
                                  </Stack>
                                </div>
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  changeResourceStatus(
                                    row.resourcedId,
                                    'declined'
                                  )
                                }
                              >
                                <div
                                  style={{
                                    width: '120px',
                                    backgroundColor: '#FDE2ED',
                                    padding: '6px',
                                    marginLeft: 'auto',
                                    borderRadius: '4px',
                                    margin: '7px',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={
                                      2
                                    } /* This feels like way too many things */
                                  >
                                    {/* <AccessTimeIcon sx={{...buttonTextStyle, fontFamily: "'Inter', sans-serif", color: "#1A53D0"  }}></AccessTimeIcon> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      style={{ paddingTop: '2px' }}
                                    >
                                      <path
                                        d="M4.73334 12.2C4.47561 12.4578 4.05774 12.4578 3.80001 12.2C3.54228 11.9423 3.54228 11.5244 3.80001 11.2667L7.06668 8.00004L3.80001 4.73337C3.54228 4.47564 3.54228 4.05777 3.80001 3.80004C4.05774 3.54231 4.47561 3.54231 4.73334 3.80004L8.00001 7.06671L11.2667 3.80004C11.5244 3.54231 11.9423 3.54231 12.2 3.80004C12.4577 4.05777 12.4577 4.47564 12.2 4.73337L8.93334 8.00004L12.2 11.2667C12.4577 11.5244 12.4577 11.9423 12.2 12.2C11.9423 12.4578 11.5244 12.4578 11.2667 12.2L8.00001 8.93337L4.73334 12.2Z"
                                        fill="#C7504F"
                                      />
                                    </svg>
                                    <Typography
                                      sx={{
                                        ...buttonTextStyle,
                                        color:
                                          '#A43837' /* How to get the actual font from the internet? */,
                                      }}
                                    >
                                      {t('Declined')}
                                    </Typography>
                                  </Stack>
                                </div>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={(event) =>
                                handleMenuClick2(event, row._id)
                              }
                            >
                              <MoreVertIcon style={{ fontSize: '18px' }} />
                            </IconButton>
                            {/* How to get rid of this earlier? Probably need a big logic update (or logic understanding update lol) */}
                            <Menu
                              anchorEl={menuAnchors[row?._id]}
                              open={Boolean(menuAnchors[row?._id])}
                              onClose={() => handleMenuClose2(row._id)}
                            >
                              <MenuItem
                                onClick={() =>
                                  window.open(
                                    `/editResource?id=${row._id}`,
                                    '_blank'
                                  )
                                }
                              >
                                <ListItemIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6.414 15.89L16.556 5.74798L15.142 4.33398L5 14.476V15.89H6.414ZM7.243 17.89H3V13.647L14.435 2.21198C14.6225 2.0245 14.8768 1.91919 15.142 1.91919C15.4072 1.91919 15.6615 2.0245 15.849 2.21198L18.678 5.04098C18.8655 5.2285 18.9708 5.48281 18.9708 5.74798C18.9708 6.01314 18.8655 6.26745 18.678 6.45498L7.243 17.89ZM3 19.89H21V21.89H3V19.89Z"
                                      fill="#0052CC"
                                    />
                                  </svg>
                                </ListItemIcon>
                                <Typography
                                  sx={{ ...buttonTextStyle, color: '#0052CC' }}
                                >
                                  {t('Edit')} {t('Resource.Singular')}
                                </Typography>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteConfirmationOpen(row._id);
                                }}
                              >
                                <ListItemIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M14 11V17M10 11V17M6 7V19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7M4 7H20M7 7L9 3H15L17 7"
                                      stroke="#EE1313"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </ListItemIcon>
                                <Typography
                                  sx={{ ...buttonTextStyle, color: '#EE1313' }}
                                >
                                  {t('Delete')}
                                </Typography>
                              </MenuItem>
                            </Menu>
                            {/* </Popover> */}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                  <Pagination1
                    totalPages={totalPages}
                    refetch={getApprovedResources}
                    setActive={setActive}
                  />
                </Table>
              ) : (
                <Typography
                  sx={{
                    ...buttonTextStyle,
                    color: '#000000',
                    textAlign: 'center',
                    fontSize: '1.8rem',
                    marginTop: 2,
                  }}
                >
                  {t('There are no resources which match your search.')}
                </Typography>
              )}
              <div style={{ height: '40px' }} />
              <br />
              <br />
            </Box>
          </Box>
        </Container>
      </Box>
      <Dialog open={deleteConfirmation} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>
          <div
            style={{
              marginLeft: '10px',
              'font-family': 'Nunito',
              'font-size': '28px',
              'font-style': 'normal',
              'font-weight': '700', // linked in wrong place
              display: 'flex',
            }}
          >
            {t('Are you sure?')}
            <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
              <IconButton
                onClick={handleDeleteConfirmationClose}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon style={{ fontSize: '38px', color: 'black' }} />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <Typography
          sx={{
            ...buttonTextStyle,
            paddingLeft: '30px',
            paddingRight: '30px',
            fontWeight: '350',
            fontSize: '18px',
            color: 'black',
          }}
        >
          {t(
            'This will permanently delete the selected resource and its data.'
          )}
        </Typography>

        <br />
        <br />
        <Button
          onClick={() => {
            deleteResource(resourceToDelete);
            handleDeleteConfirmationClose();
          }}
          type="submit"
          variant="contained"
          size="large"
          sx={{
            ...buttonTextStyle,
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '25px',
            'border-radius': '8px',
            background: '#0052CC',
          }} /* Needs an onClick to actually promote the user */
        >
          {t('Delete')} {t('Resource.Singular')}
        </Button>
      </Dialog>
    </>
  );
};

export { ManageResources };
