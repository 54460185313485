import React from 'react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { Header } from '../Header/Header';
import Alert from '../Alert/Alert';
import ErrorPage from '../../views/ErrorPage/ErrorPage';
import { useTranslation } from 'react-i18next';

const RequireAuth = ({ allowedRoles }) => {
  const { t } = useTranslation();

  const userInfo = useSelector((state) => state.auth.user);
  // TODO: Any auth based on location? Idk maybe discriminatory
  // const location = useLocation();

  const goToSignIn = async () => {
    if (!userInfo?.roles || userInfo.roles?.includes('100001')) return;

    return (window.location.href = '/auth/signin');
  };

  useState(goToSignIn, []);

  return userInfo?.roles ? (
    userInfo.roles?.find((role) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : userInfo.roles.includes('100001') ? (
      <ErrorPage error="403" />
    ) : (
      <Header loading />
    )
  ) : (
    <Fragment>
      <Header loading />
      <Container maxWidth="lg" sx={{ marginTop: '36px' }}>
        <Alert
          data={{
            severity: 'error',
            title: `${t('Unable to fetch user authentication status')}`,
            message: `${t('We are sorry, but we are unable to process your request at this time')}`,
            actions: [
              {
                name: `${t('Retry')}`,
                onClick: () => {
                  window.location.reload();
                },
              },
              {
                name: `${t('Return Home')}`,
                onClick: () => {
                  window.location.href = '/';
                },
              },
            ],
          }}
        />
      </Container>
    </Fragment>
  );
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
};

export default RequireAuth;
