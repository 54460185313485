import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './_Reviews.scss';
import { Rating, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { axiosPrivate } from '../../apis/backend';
Reviews.propTypes = {
  reviewer: PropTypes.string.isRequired, // Reviewer ID (opid)
  reviewText: PropTypes.string.isRequired,
  reviewTime: PropTypes.string.isRequired, // Assuming reviewTime is passed as a string
  rating: PropTypes.number.isRequired,
};

function Reviews({ reviewer, reviewText, reviewTime, rating }) {
  const [reviewerName, setReviewerName] = useState(''); // State for storing the reviewer's name
  const formattedReviewTime = reviewTime
    ? formatDistanceToNow(new Date(reviewTime), { addSuffix: true })
    : 'Date not available';

  useEffect(() => {
    // Function to fetch the revivewer's name using their ID
    async function fetchReviewerName(opid) {
      try {
        // Make a GET request with the opid as a query parameter
        const response = await axiosPrivate.get(`/user/name?userId=${opid}`);
        // Return the firstName and lastName
        return `${response.data.payload.firstName} ${response.data.payload.lastName}`;
      } catch (error) {
        console.error('Error fetching reviewer name:', error);
        return 'Unknown Reviewer'; // Fallback if an error occurs
      }
    }

    if (reviewer) {
      fetchReviewerName(reviewer).then((name) => setReviewerName(name)); // Call the function with the reviewer ID
    }
  }, [reviewer]);

  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="content">
          <div className="box">
            <Typography
              style={{ fontWeight: 'bold', fontSize: 18, color: '#2C2D30' }}
            >
              {reviewerName} {/* Display the name or a fallback */}
            </Typography>
          </div>
        </div>
      </div>
      <div className="rating-time-wrapper">
        <Rating name="read-only" value={rating} readOnly />
        <div className="review-time">{formattedReviewTime}</div>
      </div>
      <div className="review-text">{reviewText}</div>
      <div className="button-group">
        {/* Uncomment and implement button actions if needed */}
        {/* 
        <Button
          variant="primary"
          label="Helpful"
          // onClick={handleClick}
        />
        <Button
          variant="secondary"
          label="Report"
          // onClick={handleClick}
        />
        */}
      </div>
    </div>
  );
}

export default Reviews;
