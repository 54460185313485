import React from 'react';
import PropTypes from 'prop-types';
import './LoginPrompt.css';

export function LoginPrompt({ handleNav, close, save }) {
  return (
    <div className="loginPopup">
      {save ? (
        <p>Log in to save this resource!</p>
      ) : (
        <p>Log in to review this resource!</p>
      )}

      <div className="buttonBox">
        <button className="logButton" onClick={() => handleNav(`/auth/signin`)}>
          Login
        </button>
        <button className="logButton" onClick={() => handleNav(`/auth/signin`)}>
          Sign Up
        </button>
      </div>
      <svg
        className="closeBox"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 15 16"
        fill="none"
        onClick={() => close()}
      >
        <path
          d="M4.5 5L7.5 8M10.5 11L7.5 8M7.5 8L4.5 11M7.5 8L10.5 5"
          stroke="#2C2D30"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

LoginPrompt.propTypes = {
  handleNav: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.bool.isRequired,
};
