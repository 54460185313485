import React from 'react';
import FRMLogo from '../../resources/FRM-logo.svg';

export function Logo() {
  return (
    <div style={{ display: 'flex' }}>
      <img src={FRMLogo} alt="Florida Resource Map primary logo"></img>
    </div>
  );
}
