import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Chip,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Footer, Header, ServiceCard } from '../../components';
import Alert from '../../components/Alert/Alert';
//import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { axiosPrivate } from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const ResourceEditor = ({ edit }) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.user);

  const addTagTextStyle = {
    color: 'var(--FRM-Neutral-neutral-900, #2C2D30)',
    textTransform: 'none',
    /* Label Medium */
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px' /* 150% */,
    letterSpacing: '0.32px',
  };

  const whiteTextStyle = {
    color: 'var(--FRM-White, #FFF)',
    textTransform: 'none',
    /* Label Medium */
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px' /* 150% */,
    letterSpacing: '0.32px',
  };

  const inputTitleStyle = {
    color: '#00233D',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: 'normal',
  };

  const headerStyle = {
    color: '#000000',
    fontFamily: 'Nunito',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '34px',
  };

  const boxStyle = {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '24px',
    flexWrap: 'wrap',
    width: '100%',
  };

  const selectStyle = {
    flex: 2,
    color: '#00233D',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '26px',
    borderRadius: '8px',
    // border: '1px solid #666E87',
    height: '50px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#666E87', // Change this to your desired border color
    },
  };

  const inputDescriptionStyle = {
    color: '#2C2D30',
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px' /* 157.143% */,
    flexDirection: 'column',
    width: '100%',
    marginBottom: '30px',
  };

  const inputFieldStyle = {
    margin: '.5rem ',
    '& .MuiTextField-root': {
      borderRadius: '8px',
      border: '1px solid var(--FRM-Neutral-neutral-500, #696B72)',
      background: 'var(--FRM-White, #FFF)',
      height: '40px',
    },

    '& .MuiOutlinedInput-root': {
      color: 'var(--FRM-Neutral-neutral-700, #4B4C51)',

      /* Body Medium */
      fontFamily: 'Atkinson Hyperlegible',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px' /* 150% */,
    },
    marginTop: '4px',
    marginBottom: '16px',
  };

  const menuItemStyle = {
    color: '#00233D',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    // lineHeight: '26px',
    // flexDirection: 'column',
    // width: '100%',
    // marginBottom: "30px"
  };

  const asteriskStyle = {
    color: '#F00',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '26px',
  };

  const InputProps = {
    style: {
      borderRadius: '1.2rem',
      fontSize: '1.7rem',
      paddingLeft: '4px',
    },
  };

  const addTagStyle = {
    marginTop: '8px',
    display: 'flex',
    height: '45px',
    padding: '17px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
    marginLeft: '16px',
    background: 'var(--FRM-White, #FFF)',
  };

  const blueButtonStyle = {
    marginTop: '8px',
    display: 'flex',
    height: '45px',
    padding: '17px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '8px',
    marginLeft: '16px',
    background: 'var(--FRM-Primary-primary-500, #2067D2)',
  };

  const containerStyle = {
    display: 'flex',
    width: '720px',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '8px',
    border: '1px solid #CACCD3',
  };

  const sx = {
    margin: '.5rem ',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '1px solid var(--FRM-Neutral-neutral-500, #696B72)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#666E87',
        borderWidth: '2px',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '1.4rem',
      padding: '01.4rem 1rem',
      color: 'black',
      fontWeight: '400',
    },
    padding: '0 0 0.8rem 0',
  };

  const tagStyle = {
    margin: '.5rem ',
    color: 'var(--FRM-Primary-primary-900, #0D2B58)',
    fontFamily: 'Atkinson Hyperlegible',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px' /* 150% */,
    padding: '4px 8px',
    borderRadius: '4px',
    background: 'var(--FRM-Primary-primary-100, #E2EBFB)',
  };

  const closeTagStyle = {
    '&.MuiButton-root': {
      margin: '0px',
      marginBottom: '2px',
      minWidth: '0px',
      padding: '0px',
      marginLeft: '6px',
    },
  };

  const detailsSx = {
    margin: '.5rem ',
    '& .MuiTextField-root': {
      borderRadius: '8px',
      border: '1px solid var(--FRM-Neutral-neutral-500, #696B72)',
      padding: '8px 12px',
      gap: '8px',
    },
    marginBottom: '16px',
    '& .MuiOutlinedInput-input': {
      color: 'var(--FRM-Neutral-neutral-700, #4B4C51)',
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px',
      flex: '1 0 0',
      height: '155px !important',
      /* Body Medium */
      fontFamily: 'Atkinson Hyperlegible',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px' /* 150% */,
      marginLeft: '12px',
    },
  };

  const pickStateSx = {
    margin: '.5rem ',
    borderColor: '#666E87',
    'fieldset.MuiOutlinedInputNotchedOutline': {
      border: '2px solid #666E87',
      borderRadius: '10px',
    },
    marginBottom: '13px',
  };

  const timeSx = {
    margin: '.15rem ',
    borderColor: '#666E87',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #666E87',
      borderRadius: '10px',
    },
    marginBottom: '13px',
    height: '40px',
    width: '90px',
  };

  const dropdownSx = {
    margin: '.5rem ',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #233D',
      borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#888888',
        borderWidth: '2px',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '1.4rem',
      padding: '01.4rem 1rem',
    },
    padding: '0 0 0.8rem 0',
  };

  const labelStyle = {
    fontWeight: 700,
    color: '#00233D',
    fontSize: '16px', // Adjust the font size as needed
    fontFamily: 'Nunito', // Change 'YourFont' to the desired font family
    // Add any other styles you want to customize for the label
  };

  const checkboxStyle = {
    '& .MuiSvgIcon-root': {
      width: '30px', // Adjust the width to make the checkbox bigger
      height: '30px', // Adjust the height to make the checkbox bigger
      // Add any other styles you want to customize for the checkbox icon
    },
    color: '#00233D',
  };

  const missingStyle = {
    display: 'flex',
    maxWidth: '760px',
    padding: '50px 100px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '44px 253px',
    flexWrap: 'wrap',
    borderRadius: '8px',
    border: '1px solid var(--FRM-Neutral-neutral-100, #D1D1D3)',
    boxShadow: '4px 8px 32px 0px rgba(0, 0, 0, 0.16)',
  };

  const location = useLocation();
  const [verifyChecked, setVerifyChecked] = useState(false);
  const [resourceData, setResourceData] = useState();
  const [errorData, setErrorData] = useState('');
  const [hoursErrorData, setHoursErrorData] = useState('');
  const [successData, setSuccessData] = useState('');
  const allFields = [
    'name',
    'name_es',
    'phone',
    'phone_string',
    'email',
    'website',
    'address',
    'address_string',
    'longitude',
    'latitude',
    'description',
    'description_es',
    'tags',
    'hours',
    'hours_string',
  ];
  const [currentTags, setCurrentTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const fieldsToUpdate = [];
  const [missingFields, setMissingFields] = useState(false);
  let missingCount = 0;

  // these fields are required and will be added to resource data before being pushed to backend (not now, since autopopulation uses resourceData and would be a lot of empty strings)
  const requiredFields = {
    name: '',
    website: '',
    email: '',
    phone_string: '',
    phone: {
      areaCode: '',
      number: '',
    },
    description: '',
    address_string: '',
    address: {
      street: '',
      city: '',
      county: '',
      state: '',
      zip: '',
    },
    latitude: 0,
    longitude: 0,
    //hours_string: '',
    //hours: {},
    tags: [],
  };

  const horizontalLine = {
    marginTop: '42px',
    marginBottom: '33px',
  };

  useEffect(() => {
    localStorage.setItem('resourceData', JSON.stringify(resourceData));
  }, [resourceData]);

  const handleVerifyCheckbox = (event) => {
    setVerifyChecked(event.target.checked);
  };

  const addTag = (tag) => {
    const tagToAdd = tag.toLowerCase().trim();
    if (tagToAdd.replace(/\s/g, '').length > 0 && currentTags.length === 0) {
      setCurrentTags([tagToAdd]);
      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        tags: [tagToAdd],
      }));
    } else if (
      tagToAdd.replace(/\s/g, '').length > 0 &&
      !currentTags.includes(tagToAdd)
    ) {
      setCurrentTags((prev) => [...prev, tagToAdd]);

      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        tags: [...prevResourceData.tags, tagToAdd],
      }));
    }
  };

  const removeTag = (tag) => {
    const tagToRemove = tag.toLowerCase();
    setCurrentTags(currentTags.filter((t) => t !== tagToRemove));
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      tags: prevResourceData.tags.filter((t) => t !== tagToRemove),
    }));
  };

  const addStep = () => {
    if (!resourceData.nextSteps) {
      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        nextSteps: [''],
      }));
    } else {
      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        nextSteps: [...prevResourceData.nextSteps, ''],
      }));
    }
  };

  const handleStepChange = (index) => (event) => {
    const newSteps = [...resourceData.nextSteps];
    newSteps[index] = event.target.value;
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      nextSteps: newSteps,
    }));
  };

  const removeStep = (index) => {
    const newSteps = resourceData.nextSteps.filter((_, i) => i !== index);
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      nextSteps: newSteps,
    }));
  };

  //Resource Data Services
  const addService = () => {
    if (!resourceData.services) {
      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        services: [{}],
      }));
    } else {
      setResourceData((prevResourceData) => ({
        ...prevResourceData,
        services: [...prevResourceData.services, {}],
      }));
    }
  };
  const handleServiceName = (index) => (event) => {
    const newServices = [...resourceData.services];
    newServices[index].title = event.target.value;
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      services: newServices,
    }));
  };

  const addServiceDetail = (index, detail) => {
    const newServices = [...resourceData.services];
    if (!resourceData.services[index].details) {
      newServices[index].details = [detail];
    } else {
      newServices[index].details = [...newServices[index].details, detail];
    }
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      services: newServices,
    }));
  };

  const removeServiceDetail = (index, detailIndex) => {
    const newServices = [...resourceData.services];
    newServices[index].details = newServices[index].details.filter(
      (_, i) => i !== detailIndex
    );
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      services: newServices,
    }));
  };

  const removeService = (index) => {
    console.log('Deleting ' + index);
    const newServices = resourceData.services.filter((_, i) => i !== index);
    setResourceData((prevResourceData) => ({
      ...prevResourceData,
      services: newServices,
    }));
    console.log('After deletion: ', resourceData);
  };

  const loadResourceData = async () => {
    if (edit) {
      try {
        const query = new URLSearchParams(location.search);
        const response = await axiosPrivate.get(
          `/resource/single?id=${query.get('id')}`
        );
        if (response?.data?.payload) {
          const resourceObj = response.data.payload;
          // Add the serviceLanguages field to resourceData if it doesn't exist
          if (!resourceObj.serviceLanguages) {
            resourceObj.serviceLanguages = []; // Initialize it as an empty array
          }
          // for (const field in requiredFields) {
          //   if (!resourceObj[field]) fieldsToUpdate.push(field);
          // }
          // console.log(response.data.payload);
          const { timeLastUpdate, resourceId, status, ...updatedResourceData } =
            resourceObj;
          console.log('Updated resource data', updatedResourceData);

          setResourceData(updatedResourceData);
          console.log('Initial resource data', resourceData);

          // Handle tags
          if (resourceObj.tags) setCurrentTags(resourceObj.tags);
          // Handle verified
          setVerifyChecked(resourceObj?.verifiedBy);
        } else {
          setErrorData('Error fetching resource data');
        }
      } catch (err) {
        setErrorData(
          err.response?.data?.errMsg || 'Error fetching resource data'
        );
      }
    } else {
      const savedData = localStorage.getItem('resourceData');
      var toSave = {};
      if (savedData != 'undefined') {
        toSave = JSON.parse(savedData);
      }
      // Add the serviceLanguages field to resourceData if it doesn't exist
      if (!toSave.serviceLanguages) {
        toSave.serviceLanguages = []; // Initialize it as an empty array
      }
      if (!toSave.nextSteps) {
        toSave.nextSteps = [''];
      }
      // Add default empty service field
      if (!toSave.services) {
        toSave.services = [{}];
      }
      console.log('to save: ', toSave);
      setResourceData(toSave);
    }
  };

  useEffect(() => {
    if (errorData === 'Error fetching resource data') {
      setErrorData('');
    }
    setSuccessData('');
    loadResourceData();
  }, []);

  const handleSimpleInputChange = (fieldName) => (event) => {
    setResourceData({
      ...resourceData,
      [fieldName]: event.target.value,
    });
  };

  const handleNumInputChange = (fieldName) => (event) => {
    //If blank set to blank
    if (event.target.value == '') {
      setResourceData({
        ...resourceData,
        [fieldName]: '',
      });
    }
    // If starting a negative number allow "-"
    if (
      event.target.value.indexOf('-') == 0 &&
      event.target.value.length == 1
    ) {
      setResourceData({
        ...resourceData,
        [fieldName]: event.target.value,
      });
      return;
    }
    // If NaN ignore
    if (isNaN(parseFloat(event.target.value))) {
      event.target.value = '';
      return;
    }
    if (event.target.value.indexOf('.') == event.target.value.length - 1) {
      setResourceData({
        ...resourceData,
        [fieldName]: event.target.value,
      });
      return;
    }
    // If writing decimal allow for zeroes
    if (
      event.target.value.indexOf('.') != -1 &&
      event.target.value[event.target.value.length - 1] == 0
    ) {
      setResourceData({
        ...resourceData,
        [fieldName]: event.target.value,
      });
      return;
    }

    console.log('Parsed float: ' + parseFloat(event.target.value));
    setResourceData({
      ...resourceData,
      [fieldName]: parseFloat(event.target.value),
    });
  };

  const handleNestedInputChange = (parentField, nestedField) => (event) => {
    setResourceData({
      ...resourceData,
      [parentField]: {
        ...resourceData[parentField],
        [nestedField]: event.target.value,
      },
    });
  };

  const addRequired = (target, req) => {
    for (const key in req) {
      if (req[key] instanceof Object && key in target) {
        addRequired(target[key], req[key]);
      } else {
        if (!(key in target) || typeof target[key] === 'undefined') {
          target[key] = req[key];
        }
      }
    }
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([field, value]) => {
        console.log(field);
        if (typeof value === 'object' && value !== null) {
          const cleanedValue = removeEmptyFields(value);
          return Object.keys(cleanedValue).length > 0; // Only keep if the object is not empty
        }
        if (field == 'hours' && value.length == 2) {
          // Ignore empty hours
          return;
        }
        if ((field == 'latitude' || field == 'longitude') && isNaN(value)) {
          // Ignore empty longs and lats
          return;
        }
        return (
          value?.length > 0 && value !== '' && value != ' ' && value !== null
        ); // Keep non-empty strings
      })
    );
  };

  // Make sure no empty fields are submitted
  const cleanData = () => {
    // Clear out empty Services
    let cleanedServices =
      resourceData.services?.filter((service) => service.title) || [];
    console.log('CLEAN: ', cleanedServices);

    // Clear out empty details
    const cleanServiceDetails = (services) => {
      return services.map((service) => {
        // Filter out empty strings from details
        const filteredDetails = service?.details?.filter(
          (item) => item.trim() !== ''
        );

        // Return the service object, conditionally including the details field
        return {
          title: service.title,
          ...(filteredDetails?.length > 0 && { details: filteredDetails }), // Include details only if not empty
        };
      });
    };

    cleanedServices = cleanServiceDetails(cleanedServices);

    // Clear out empty Next Steps
    const cleanedSteps =
      resourceData.nextSteps?.filter((step) => step != '') || [];

    // Fix latitude and longitude
    const finalLat = parseFloat(resourceData.latitude);
    const finalLong = parseFloat(resourceData.longitude);

    // Remove empty fields
    const updatedData = removeEmptyFields({
      ...resourceData,
      services: cleanedServices,
      nextSteps: cleanedSteps,
      hours: JSON.stringify(verifiedHours),
      verifiedBy:
        verifyChecked && resourceData.managePartner
          ? resourceData.managePartner
          : undefined,
      latitude: finalLat,
      longitude: finalLong,
    });

    return updatedData;
  };

  const handleSubmit = async () => {
    const cleanedData = cleanData();
    setResourceData(cleanedData);
    await submitResource(cleanedData);
  };

  const submitResource = async (cleanedData) => {
    setErrorData('');
    setSuccessData('');
    // addRequired(resourceData, requiredFields);

    console.log('ResourceData is now ', cleanedData);

    try {
      const query = new URLSearchParams(location.search);
      let response;
      if (edit) {
        response = await axiosPrivate.put(
          `/resource/single?id=${query.get('id')}`,
          cleanedData
        );
        setSuccessData(
          'Your changes were submitted, and they are under review from our team.'
        );
      } else {
        response = await axiosPrivate.post('/resource/single', cleanedData);
        setSuccessData(
          'Your resource was submitted, and it is under review from our team.'
        );
      }
      window.scrollTo(0, 0);
    } catch (err) {
      if (err?.response?.data?.errMsg) {
        var error = err?.response.data.errMsg;
        err?.response?.data?.payload?.validationErrors?.map((errs) => {
          error += '\n\t\u2022 ' + errs.errMsg;
        });
      }
      window.scrollTo(0, 0);
      setErrorData(error);
    }
  };

  // Add to this array to add more default tags
  const tagsAvailable = [
    'Food',
    'Shelter',
    'Health',
    'Education',
    'Work',
    'Finance',
    'Mental Health',
    'Substance Abuse',
    'Other',
    'Transportation',
    'Legal',
  ];
  const formattedTagsAvailable = tagsAvailable.map((option) => ({
    label: option,
  }));

  const unitedStates = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MP',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UM',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];

  // Control the hour page's dialogs

  const daysOfTheWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  // This will probably change to connect with real hours
  const [testHours, setTestHours] = React.useState({
    monday: [[':', ':']],
    tuesday: [[':', ':']],
    wednesday: [[':', ':']],
    thursday: [[':', ':']],
    friday: [[':', ':']],
    saturday: [[':', ':']],
    sunday: [[':', ':']],
  }); // To format

  // const [tempHours, setTempHours] = React.useState(testHours);

  const [tempHours, setTempHours] = React.useState(
    resourceData?.hours ? resourceData.hours : testHours
  ); // Not really tested because none of the resources have hours.

  const addAutoElementIfNeeded = (day) => {
    if (tempHours[day].length === 0) {
      // Add your auto element here
      setTempHours((prevTempHours) => ({
        ...prevTempHours,
        [day]: [[':', ':']], // Adjust this to your default element
      }));
    }
  };

  useEffect(() => {
    // Function to add auto element if the array is empty
    // Loop through each day and add auto element if needed
    // Disfunctional, I think--what is the "day"??
    console.log('inside initial useEffect');
    Object.keys(tempHours).forEach((day) => {
      addAutoElementIfNeeded(day);
    });
  }, []);

  const [hoursOpen, setHoursOpen] = React.useState(false);

  const handleHoursOpen = () => {
    setHoursOpen(true);
    console.log(user);
    console.log(resourceData);
  };

  const handleHoursClose = () => {
    console.log('Resource data: ', resourceData);

    setHoursOpen(false);
  };

  const [selectedDay, setSelectedDay] = React.useState('monday');
  function calculateCheckboxState() {
    // Replace this with your actual logic to determine the checked state
    // based on tempHours and selectedDay
    return (
      tempHours && tempHours[selectedDay] && tempHours[selectedDay].length > 0
    );
  }

  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(
    calculateCheckboxState()
  );

  useEffect(() => {
    const initialCheckedState = calculateCheckboxState();
    setIsCheckboxChecked(initialCheckedState);
  }, [tempHours, selectedDay]);

  const getTimeFromJSON = (index) => {
    const time = new Date();
    time.setHours(parseInt(tempHours[selectedDay][index]?.slice(0, 2)));
    time.setMinutes(parseInt(tempHours[selectedDay][index]?.slice(2, 4)));
    return time;
  };

  const [hour, setHour] = React.useState('');
  const [minute, setMinute] = React.useState('');
  const [ampm, setAmPm] = React.useState('');

  const handleHourChange = (event) => {
    const inputHour = event.target.value;
    setHour(inputHour);
  };

  const handleMinuteChange = (event) => {
    const inputMinute = event.target.value;
    setMinute(inputMinute);
  };

  const handleAmPmChange = (event) => {
    const inputAmPm = event.target.value;
    setAmPm(inputAmPm);
  };

  const hoursForMenu = []; // Array of ints   (so no leading 0's)

  for (let i = 1; i <= 12; i++) {
    // const formattedHour = i < 10 ? `0${i}` : `${i}`;
    // hoursForMenu.push(formattedHour);
    hoursForMenu.push(i);
  }

  const minutesForMenu = []; // Array of strings (so leading 0's)

  for (let i = 0; i < 60; i += 5) {
    const formattedMinute = i < 10 ? `0${i}` : `${i}`;
    minutesForMenu.push(formattedMinute);
  }

  const [addHour, setAddHour] = useState([':', ':']); // To format

  const verifyHours = async () => {
    console.log(tempHours);
    setHoursErrorData('');
    // addRequired(resourceData, requiredFields);

    try {
      const query = new URLSearchParams(location.search);
      // const response = await axiosPrivate.put(`/resource/single/${query.get('id')}`, resourceData);

      const request = { hours: JSON.stringify(tempHours) };
      const response = await axiosPrivate.post(
        `/resource/validation/hours`,
        request
      ); // the /api may not be necessary
      console.log('response: ', response);

      if (response?.data?.payload?.result?.errMsg) {
        console.log('error recieved');
        setHoursErrorData(response.data.payload.result.errMsg);
      } else {
        setHoursOpen(false);
        setVerifiedHours(tempHours);

        const updatedResourceData = {
          ...resourceData,
          hours: JSON.stringify(tempHours),
        };
        setResourceData(updatedResourceData);

        console.log('resource data set in verifyhours: ', resourceData);

        // Set overall state right here??
      }
    } catch (err) {
      setHoursErrorData(err.response?.data?.errMsg || 'Error updating hours');
    }
  };

  // useEffect(() => {

  //     setTempHours((prevTempHours) => {
  //       // const newTempHours = { ...prevTempHours }; // Assuming tempHours is an object
  //       // const selectedDayArray = JSON.parse(newTempHours[selectedDay] || '[]');

  //       // // Check if the array is empty
  //       // if (newTempHours[selectedDay].length === 0) {
  //       //   // Add a certain element to the array
  //       //   selectedDayArray.push([":", ":"]);
  //       // }

  //       // newTempHours[selectedDay] = JSON.stringify(selectedDayArray);
  //       // return newTempHours;

  //   const newTempHours = JSON.parse(JSON.stringify(tempHours));
  //   newTempHours[selectedDay].push([":", ":"])
  //   return newTempHours;

  //     });

  //   // const newTempHours = JSON.parse(JSON.stringify(tempHours));
  //   // newTempHours[selectedDay].push([":", ":"])
  //   // setTempHours(newTempHours);
  // }, [tempHours, selectedDay]);

  const getFormattedHours = () => {
    let result = '';
    // tempHours.map((day, hours) => {
    //   result += day;
    // })
    for (const day in tempHours) {
      result += day;
      result += tempHours.day;
    }

    return result;
  };

  const [verifiedHours, setVerifiedHours] = useState('');

  function formatBusinessHours() {
    // Define constant for the number of spaces to indent hours
    const indentation = 20;

    // Initialize an empty string to store formatted business hours
    let formattedHours = '';

    // Loop through each day of the week
    for (let day in verifiedHours) {
      if (verifiedHours.hasOwnProperty(day)) {
        // Capitalize the day of the week
        const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);

        // Append the day to the result string with a colon
        formattedHours += `${capitalizedDay}: \n`;

        // Format and append the hours to the result string
        if (
          verifiedHours[day].length === 0 ||
          (verifiedHours[day].length === 1 &&
            verifiedHours[day][0][0] === '00:00' &&
            verifiedHours[day][0][1] === '00:00')
        ) {
          formattedHours += ' '.repeat(indentation) + 'Closed\n';
        } else if (
          verifiedHours[day].length === 1 &&
          verifiedHours[day][0][0] === '00:00' &&
          verifiedHours[day][0][1] === '23:59'
        ) {
          formattedHours += ' '.repeat(indentation) + 'Open 24 Hours\n';
        } else {
          verifiedHours[day].forEach((interval) => {
            // Convert military time to AM/PM format
            const startTime = militaryToAMPM(interval[0]);
            const endTime = militaryToAMPM(interval[1]);
            formattedHours +=
              ' '.repeat(indentation) + `${startTime} - ${endTime}\n`;
          });
        }
      }
    }

    return formattedHours;
  }

  function militaryToAMPM(time) {
    const [hours, minutes] = time.split(':');
    let formattedTime = '';
    let hoursNum = parseInt(hours);
    const ampm = hoursNum >= 12 ? 'PM' : 'AM';
    hoursNum = hoursNum % 12;
    hoursNum = hoursNum ? hoursNum : 12; // the hour '0' should be '12'
    formattedTime = `${hoursNum}:${minutes} ${ampm}`;
    return formattedTime;
  }

  // place to add stuff

  return (
    <>
      <Header dashboard />
      <Container
        style={{
          display: 'flex',
          alignItems: 'left',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        <Box style={{ ...boxStyle, margin: 40 }}>
          <span style={{ ...headerStyle, fontSize: 40 }}>
            {edit ? t('Edit Resource') : t('Add a Resource')}
          </span>
        </Box>

        {errorData && (
          <Alert
            data={{
              severity: 'warning',
              title: 'Warning',
              message: (
                <pre style={{ whiteSpace: 'pre-wrap' }}>{errorData}</pre>
              ),
            }}
          />
        )}

        {successData && (
          <Alert
            data={{
              severity: 'success',
              title: 'Success!',
              message: (
                <pre style={{ whiteSpace: 'pre-wrap' }}>{successData}</pre>
              ),
              actions: [
                {
                  name: 'Return to Dashboard',
                  onClick: () => {
                    window.close();
                  },
                },
              ],
            }}
          />
        )}

        {resourceData &&
        successData !=
          'Your resource was submitted, and it is under review from our team.' ? (
          <>
            <Box
              style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {fieldsToUpdate.length > 0 && (
                <Alert
                  data={{
                    severity: 'warning',
                    title: `${t('Warning: Please reformat resource profile')}`,
                    message: `${t('To update this resource, you must correct the following fields to the proper format before you are able to submit:')}\n ${t(fieldsToUpdate).join(', ')}`,
                  }}
                />
              )}

              {/* // onChange={(event) => {
                  //   setResourceData(prev => ({
                  //     ...prev, name: event.target.value}))
                  // }} */}

              {/* Verify Section */}
              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>1. {t('Verification')}</span>
                </div>

                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={checkboxStyle}
                        checked={verifyChecked}
                        onChange={handleVerifyCheckbox}
                        disabled={
                          !user.managePartner ||
                          (resourceData.verifiedBy &&
                            user.managePartner !== resourceData.verifiedBy)
                        }
                      />
                    }
                    label={
                      <Typography sx={labelStyle}>
                        {(user.managePartner &&
                          user.managePartner === resourceData.verifiedBy) ||
                        (user.managePartner && !resourceData.verifiedBy)
                          ? `${t('Verify this resource on behalf of')} ${user.managePartnerName || user.managePartner}`
                          : resourceData.verifiedBy
                            ? `${t('This resource is verified by')} ${resourceData.verifiedBy}`
                            : `${t('You are not currently affiliated with a verification partner')}`}
                      </Typography>
                    }
                  />
                </div>
              </Box>

              {/* Name section */}
              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>2. {t('Overview')}</span>
                </div>

                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  {/* Name, Description, Tags, Admin */}
                  {t('Organization Name')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={resourceData?.name}
                    onChange={handleSimpleInputChange('name')}
                    placeholder="Florida Community Innovation"
                    fullWidth
                    InputProps={InputProps}
                  />

                  {t('Spanish Name')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={resourceData?.name_es}
                    onChange={handleSimpleInputChange('name_es')}
                    // placeholder={t('Placeholder Name (60 characters)')}
                    fullWidth
                    InputProps={InputProps}
                  />

                  {t('Description')}
                  <TextField
                    multiline
                    rows={2}
                    sx={detailsSx}
                    variant="outlined"
                    value={resourceData?.description}
                    onChange={handleSimpleInputChange('description')}
                    placeholder="Florida Community Innovation empowers young innovators to..."
                    fullWidth
                    InputProps={{ ...InputProps }}
                  />

                  {t('Spanish Description')}
                  <TextField
                    multiline
                    rows={2}
                    sx={detailsSx}
                    variant="outlined"
                    value={resourceData?.description_es}
                    onChange={handleSimpleInputChange('description_es')}
                    placeholder="Florida Community Innovation capacita a innovadores para..."
                    fullWidth
                    InputProps={{ ...InputProps }}
                  />

                  {t('Tags')}
                  <br />
                  {/* <Typography style={{ fontSize: "14px", fontFamily: "Nunito" }} >Tags</Typography> */}

                  <Box
                    style={{ display: 'flex', width: '100%', marginBottom: 10 }}
                  >
                    <Autocomplete
                      sx={sx}
                      style={selectStyle}
                      value={`${t(selectedValue)}`}
                      onInputChange={(event, value) => {
                        setSelectedValue(value);
                      }}
                      onKeyDown={(event) => {
                        // Prevent's default 'Enter' behavior.
                        event.defaultMuiPrevented = false;
                      }}
                      InputProps={menuItemStyle}
                      freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      options={formattedTagsAvailable}
                      renderInput={(params) => (
                        <TextField style={menuItemStyle} {...params} />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          {' '}
                          <div style={menuItemStyle}>
                            {t(option.label)}
                          </div>{' '}
                        </MenuItem>
                      )}
                    />

                    <Button
                      variant="contained"
                      style={{ ...addTagStyle, flex: 1 }}
                      onClick={() => {
                        //Only allow tags available
                        if (
                          tagsAvailable.find((value) => {
                            return (
                              value.toLowerCase().trim() ==
                              selectedValue.toLowerCase().trim()
                            );
                          }) != null
                        ) {
                          addTag(selectedValue);
                        }
                      }}
                    >
                      <span style={addTagTextStyle}>{t('Add Tag')}</span>
                    </Button>
                  </Box>
                  <Box
                    style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
                  >
                    {currentTags.map((tag, index) => (
                      <div key={index} label={tag} style={tagStyle}>
                        {' '}
                        {t(tag.charAt(0).toUpperCase() + tag.slice(1))}
                        <Button
                          onClick={() => removeTag(tag)}
                          sx={closeTagStyle}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M4 4L10 10M16 16L10 10M10 10L4 16M10 10L16 4"
                              stroke="#0D2B58"
                              strokeWidth="1.25"
                            />
                          </svg>
                        </Button>
                      </div>
                    ))}
                  </Box>

                  <hr style={horizontalLine} />
                </div>
              </Box>

              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>3. {t('Contact Info')}</span>
                </div>

                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  {t('Program Website')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={resourceData?.website}
                    onChange={handleSimpleInputChange('website')}
                    placeholder="floridaresourcemap.org"
                    fullWidth
                    InputProps={InputProps}
                  />

                  {t('Phone Number')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={
                      resourceData?.phone?.number || resourceData?.phone_string
                    }
                    onChange={handleNestedInputChange('phone', 'number')}
                    placeholder="1234567890"
                    fullWidth
                    InputProps={InputProps}
                  />

                  {t('Email Address')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={resourceData?.email}
                    onChange={handleSimpleInputChange('email')}
                    placeholder="info@floridainnovation.org"
                    fullWidth
                    InputProps={InputProps}
                  />
                </div>
              </Box>

              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>4. {t('Location and Hours')}</span>
                </div>

                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  {t('Street Address')}
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={
                      resourceData?.address?.street ||
                      resourceData?.address_string
                    }
                    onChange={handleNestedInputChange('address', 'street')}
                    placeholder={t('1234 University Ave')}
                    fullWidth
                    InputProps={InputProps}
                  />
                  <div>{t('City')}</div>
                  <TextField
                    sx={inputFieldStyle}
                    variant="outlined"
                    value={resourceData?.address?.city}
                    onChange={handleNestedInputChange('address', 'city')}
                    placeholder="Gainesville"
                    fullWidth
                    InputProps={InputProps}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{ flex: 1, display: 'flex', flexDirection: 'row' }}
                    >
                      <div style={{ marginRight: '16px', width: '50%' }}>
                        {t('Zip Code')}
                        <TextField
                          sx={inputFieldStyle}
                          variant="outlined"
                          value={resourceData?.address?.zipCode}
                          onChange={handleNestedInputChange(
                            'address',
                            'zipCode'
                          )}
                          placeholder="12345"
                          fullWidth
                          InputProps={InputProps}
                        />
                      </div>

                      <div style={{ width: '50%' }}>
                        {t('County')}
                        <TextField
                          sx={inputFieldStyle}
                          variant="outlined"
                          value={resourceData?.address?.county}
                          onChange={handleNestedInputChange(
                            'address',
                            'county'
                          )}
                          placeholder="Alachua"
                          fullWidth
                          InputProps={InputProps}
                        />
                      </div>
                    </div>
                    <div
                      style={{ flex: 1, display: 'flex', flexDirection: 'row' }}
                    >
                      {/* <div>
                        {t('State')}  
                      </div>
                      <Select sx={{ ...pickStateSx }}
                        value={resourceData?.address?.state}
                        placeholder={t('select a state')}
                        onChange={handleNestedInputChange('address', 'state')}>
                        {unitedStates.map((state) => (
                          <MenuItem value={state}><div style={menuItemStyle}>{state}</div></MenuItem>
                        ))}
                      </Select> */}

                      <div style={{ marginRight: '16px', width: '50%' }}>
                        {t('Latitude')}
                        <TextField
                          sx={inputFieldStyle}
                          variant="outlined"
                          value={
                            resourceData?.latitude ||
                            resourceData?.coords?.coordinates?.[0]
                          }
                          onChange={handleNumInputChange('latitude')}
                          placeholder="12.3456789"
                          fullWidth
                          InputProps={InputProps}
                        />
                      </div>

                      <div style={{ width: '50%' }}>
                        {t('Longitude')}
                        <TextField
                          sx={inputFieldStyle}
                          variant="outlined"
                          value={
                            resourceData?.longitude ||
                            resourceData?.coords?.coordinates?.[1]
                          }
                          onChange={handleNumInputChange('longitude')}
                          placeholder="12.3456789"
                          fullWidth
                          InputProps={InputProps}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '15px' }} />
                  Hours of Operation
                  <Box
                    style={{ display: 'flex', width: '100%', marginBottom: 10 }}
                  >
                    <TextField
                      multiline
                      disabled
                      sx={{ color: 'black', ...sx }}
                      style={{ flex: 2 }}
                      variant="outlined"
                      value={
                        // resourceData?.hours || resourceData?.hours_string ||
                        formatBusinessHours()
                      }
                      //onChange={handleNestedInputChange(hours_string)}
                      placeholder="Hours will display here"
                      fullWidth
                      InputProps={InputProps}
                    />

                    <Button
                      variant="contained"
                      style={{ ...addTagStyle, flex: 1 }}
                      onClick={handleHoursOpen}
                    >
                      <span style={addTagTextStyle}>Set Hours</span>
                    </Button>
                  </Box>
                  {/* End of horizontal div */}
                </div>
              </Box>

              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>
                    5. {t('Services and Languages')}
                  </span>
                </div>

                {/* All service objects are displayed in a card, and edits to a card will edit the DB*/}
                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  {resourceData?.services?.map((service, index) => (
                    <ServiceCard
                      name={service.title}
                      services={service.details}
                      index={index}
                      deleteService={removeService}
                      handleName={handleServiceName}
                      addDetail={addServiceDetail}
                      removeDetail={removeServiceDetail}
                    />
                  ))}
                  {/* On click, button adds empty service object to DB */}
                  <Button
                    variant="contained"
                    style={{
                      ...blueButtonStyle,
                      marginLeft: '3px',
                      width: '30%',
                    }}
                    onClick={addService}
                  >
                    <span style={whiteTextStyle}>{t('Add Service')}</span>
                  </Button>
                  <hr style={horizontalLine} />
                  {t('Service Languages')} <br />
                  <FormControlLabel
                    control={<Checkbox sx={checkboxStyle} />}
                    label={
                      <Typography sx={labelStyle}>{t('English')}</Typography>
                    }
                    checked={resourceData?.serviceLanguages?.includes('en')}
                    onChange={() => {
                      // Check if "en" exists in serviceLanguages
                      const index =
                        resourceData?.serviceLanguages?.indexOf('en');

                      if (index !== -1) {
                        // If "en" exists, remove it
                        const updatedLanguages = [
                          ...resourceData.serviceLanguages,
                        ];
                        updatedLanguages.splice(index, 1);
                        setResourceData({
                          ...resourceData,
                          serviceLanguages: updatedLanguages,
                        });
                      } else {
                        // If "en" doesn't exist, add it
                        setResourceData({
                          ...resourceData,
                          serviceLanguages: [
                            ...resourceData.serviceLanguages,
                            'en',
                          ],
                        });
                      }
                    }}
                  />
                  <br />
                  <FormControlLabel
                    control={<Checkbox sx={checkboxStyle} />}
                    label={
                      <Typography sx={labelStyle}>{t('Spanish')}</Typography>
                    }
                    checked={resourceData?.serviceLanguages?.includes('es')}
                    onChange={() => {
                      // Check if "en" exists in serviceLanguages
                      const index =
                        resourceData?.serviceLanguages?.indexOf('es');

                      if (index !== -1) {
                        // If "en" exists, remove it
                        const updatedLanguages = [
                          ...resourceData.serviceLanguages,
                        ];
                        updatedLanguages.splice(index, 1);
                        setResourceData({
                          ...resourceData,
                          serviceLanguages: updatedLanguages,
                        });
                      } else {
                        // If "en" doesn't exist, add it
                        setResourceData({
                          ...resourceData,
                          serviceLanguages: [
                            ...resourceData.serviceLanguages,
                            'es',
                          ],
                        });
                      }
                    }}
                  />
                </div>
              </Box>

              <Box style={boxStyle}>
                <div style={{ width: '208px' }}>
                  <span style={headerStyle}>6. {t('Next Steps')}</span>
                </div>

                <div style={{ ...inputDescriptionStyle, flex: 1 }}>
                  {resourceData?.nextSteps?.map((step, i) => (
                    <div key={i} style={{ ...inputDescriptionStyle, flex: 1 }}>
                      {t(`Step ${i + 1}`)}
                      <Box
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: 10,
                        }}
                      >
                        <TextField
                          sx={sx}
                          style={{ flex: '95%', marginBottom: '-10px' }}
                          variant="outlined"
                          value={step}
                          onChange={handleStepChange(i)}
                          placeholder={t(
                            'Visit the FCI food pantry website to...'
                          )}
                          fullWidth
                          InputProps={InputProps}
                        />
                        <Button
                          onClick={() => removeStep(i)}
                          style={{
                            position: 'relative',
                            top: 0,
                            right: 0,
                            flex: '5%',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g opacity="0.4">
                              <path
                                d="M8 8L12 12M16 16L12 12M12 12L8 16M12 12L16 8"
                                stroke="#00233D"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                            </g>
                          </svg>
                        </Button>
                      </Box>
                    </div>
                  ))}

                  <Button
                    variant="contained"
                    style={{
                      ...blueButtonStyle,
                      marginLeft: '3px',
                      width: '30%',
                    }}
                    onClick={addStep}
                  >
                    <span style={whiteTextStyle}>{t('Add Step')}</span>
                  </Button>
                </div>
              </Box>

              {/* User Access is not implemented yet. not formatted nicely yet */}
              {/* <Box style={boxStyle}>
              <Box style={boxStyle}>
                <Box style={{ width: '208px' }}>
                  <span style={headerStyle}>5. User Access</span>
                </Box>
                <Box>
                  <Container style={containerStyle}>
                    <Box style={{ display: 'flex' }}>
                      <div>Admin user name</div>
                      <Select>
                        <MenuItem value={'Pre existing tag 1'}>Can edit</MenuItem>
                        <MenuItem value={'Pre existing tag 1'}>Can view</MenuItem>
                      </Select>
                    </Box>


                    <Box style={{ display: 'flex' }}>
                      <div>Firstname Lastname</div>
                      <Select>
                        <MenuItem value={'Pre existing tag 1'}>Can edit</MenuItem>
                        <MenuItem value={'Pre existing tag 1'}>Can view</MenuItem>
                      </Select>
                    </Box>


                    <Box style={{ display: 'flex' }}>
                      <div>Firstname Lastname</div>
                      <Select>
                        <MenuItem value={'Pre existing tag 1'}>Can edit</MenuItem>
                        <MenuItem value={'Pre existing tag 1'}>Can view</MenuItem>
                      </Select>
                    </Box>


                    <Box style={{ display: 'flex' }}>
                      <div>Firstname Lastname</div>
                      <Select>
                        <MenuItem value={'Pre existing tag 1'}>Can edit</MenuItem>
                        <MenuItem value={'Pre existing tag 1'}>Can view</MenuItem>
                      </Select>
                    </Box>


                    <Box style={{ display: 'flex' }}>
                      <div>Firstname Lastname</div>
                      <Select>
                        <MenuItem value={'Pre existing tag 1'}>Can edit</MenuItem>
                        <MenuItem value={'Pre existing tag 1'}>Can view</MenuItem>
                      </Select>
                    </Box>


                  </Container>
                </Box>
                <Box style={boxStyle}>
                  <Button variant="contained">Submit</Button>
                </Box>
              </Box>
            </Box> */}
            </Box>
            <Box
              style={{
                display: 'flex',
                width: '40%',
                marginBottom: 10,
                marginLeft: 'auto',
              }}
            >
              <Button
                variant="contained"
                style={{ ...blueButtonStyle, flex: 1 }}
                onClick={() => {
                  //Find missing fields
                  if (!resourceData.name || resourceData.name == '') {
                    missingCount++;
                  }
                  if (!resourceData.website || resourceData.website == '') {
                    missingCount++;
                  }
                  if (!resourceData.email || resourceData.email == '') {
                    missingCount++;
                  }
                  if (
                    !resourceData.phone?.number ||
                    resourceData.phone?.number == ''
                  ) {
                    missingCount++;
                  }
                  if (
                    !resourceData.description ||
                    resourceData.description == ''
                  ) {
                    missingCount++;
                  }
                  if (
                    !resourceData.address?.street ||
                    resourceData.address?.street == ''
                  ) {
                    missingCount++;
                  }
                  if (
                    !resourceData.address?.city ||
                    resourceData.address?.city == ''
                  ) {
                    missingCount++;
                  }
                  if (
                    !resourceData.address?.zipCode ||
                    resourceData.address?.zipCode == ''
                  ) {
                    missingCount++;
                  }
                  if (
                    !resourceData.address?.county ||
                    resourceData.address?.county == ''
                  ) {
                    missingCount++;
                  }
                  if (!resourceData.latitude || resourceData.latitude == '') {
                    if (
                      resourceData.coords?.coordinates?.length == 0 ||
                      resourceData.coords?.coordinates?.[0] == ''
                    ) {
                      missingCount++;
                    }
                  }
                  if (!resourceData.longitude || resourceData.longitude == '') {
                    if (
                      resourceData.coords?.coordinates?.length < 2 ||
                      resourceData.coords?.coordinates?.[1] == ''
                    ) {
                      missingCount++;
                    }
                  }
                  if (!resourceData.hours || resourceData.hours == '') {
                    missingCount++;
                  }
                  if (
                    !resourceData.serviceLanguages ||
                    resourceData.serviceLanguages.length == 0
                  ) {
                    missingCount++;
                  }
                  console.log(missingCount);
                  if (missingCount == 0) {
                    handleSubmit();
                  }

                  if (missingCount > 0) {
                    setMissingFields(missingCount);
                    missingCount = 0;
                  }
                }}
              >
                <span style={whiteTextStyle}>{t('Submit')}</span>
              </Button>
            </Box>

            <div style={{ height: '40px' }} />

            {/* Missing Fields alert */}
            <Dialog open={missingFields} PaperProps={{ style: missingStyle }}>
              <div
                style={{
                  color: 'var(--FRM-Warning-warning-800, #745311)',
                  textAlign: 'center',
                  fontFamily: 'Atkinson Hyperlegible',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '24px',
                  letterSpacing: '0.32px',
                }}
              >
                You have {missingFields} missing fields. Do you still want to
                submit this resource?
              </div>

              <div
                style={{
                  width: '546px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  style={addTagStyle}
                  onClick={() => {
                    setMissingFields(false);
                    handleSubmit();
                  }}
                >
                  <span style={{ ...addTagTextStyle, width: '160px' }}>
                    {t('Submit Anyways')}
                  </span>
                </Button>
                <Button
                  style={blueButtonStyle}
                  onClick={() => {
                    setMissingFields(false);
                  }}
                >
                  <span style={{ ...whiteTextStyle, width: '133px' }}>
                    {t('Cancel')}
                  </span>
                </Button>
              </div>
            </Dialog>

            <Dialog maxWidth="md" open={hoursOpen} onClose={handleHoursClose}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column', // Change to column for vertical layout
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '680px',
                  marginBottom: '30px',
                  // padding: '30px',
                  // paddingTop: '5px',
                }}
              >
                {hoursErrorData && (
                  // <Alert severity='warning' style={{ ...inputDescriptionStyle,marginBottom: '10px' }}>
                  //   {errorData}
                  // </Alert>
                  <div>
                    <div style={{ marginTop: '20px' }}>
                      <Alert
                        data={{
                          severity: 'warning',
                          title: 'Warning',
                          message: `${hoursErrorData}`,
                        }}
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    margin: '10px',
                    marginTop: '30px',
                    fontFamily: 'Nunito',
                    marginLeft: '10px',
                    fontSize: '28px',
                    fontWeight: '700',
                  }}
                >
                  Select Hours
                </div>

                <div>
                  {daysOfTheWeek.map((day, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      style={{
                        border: '3px solid #00233D',
                        width: '10px',
                        height: '60px',
                        margin: '8px',
                        borderRadius: '100%',
                        fontSize: '15px',
                        borderWidth: '2px',
                        // backgroundColor: selectedDay === day ? '#0052CC' : 'white',

                        // color: selectedDay === day ? 'white' : '#00233D',

                        backgroundColor:
                          selectedDay === day
                            ? '#0052CC'
                            : tempHours?.[day].length === 0 ||
                                (tempHours?.[day].length === 1 &&
                                  tempHours[day][0][0] === ':' &&
                                  tempHours[day][0][1] === ':')
                              ? 'white'
                              : '#D0EEFF',

                        color:
                          selectedDay === day
                            ? 'white'
                            : tempHours?.[day].length === 0 ||
                                (tempHours?.[day].length === 1 &&
                                  tempHours[day][0][0] === ':' &&
                                  tempHours[day][0][1] === ':')
                              ? 'black'
                              : 'black',

                        borderColor: 'black',
                      }}
                      onClick={() => {
                        const newTempHours = JSON.parse(
                          JSON.stringify(tempHours)
                        );

                        if (
                          tempHours[day].length === 1 &&
                          tempHours[day][0][0] === ':' &&
                          tempHours[day][0][1] === ':'
                        ) {
                          newTempHours[day] = newTempHours[selectedDay];
                        }

                        // Update the state with the new array
                        setTempHours(newTempHours);

                        setSelectedDay(day);
                        console.log('here');
                      }}
                    >
                      {day.charAt(0)}
                    </Button>
                  ))}
                </div>
                <Box style={{ width: '83%', textAlign: 'left' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={checkboxStyle}
                        checked={
                          tempHours[selectedDay].length === 1 &&
                          tempHours[selectedDay][0][0] === '00:00' &&
                          tempHours[selectedDay][0][1] === '23:59'
                        }
                        onChange={(event) => {
                          console.log(
                            'selected day before change: ',
                            tempHours[selectedDay]
                          );

                          const newTempHours = JSON.parse(
                            JSON.stringify(tempHours)
                          );

                          if (
                            tempHours[selectedDay].length === 1 &&
                            tempHours[selectedDay][0][0] === '00:00' &&
                            tempHours[selectedDay][0][1] === '23:59'
                          ) {
                            newTempHours[selectedDay] = [[':', ':']];
                          } else {
                            newTempHours[selectedDay] = [['00:00', '23:59']];
                          }

                          // Update the state with the new array
                          setTempHours(newTempHours);
                        }}
                      />
                    }
                    label={
                      <Typography sx={labelStyle}>Open 24 Hours</Typography>
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={checkboxStyle}
                        checked={
                          tempHours[selectedDay].length === 1 &&
                          tempHours[selectedDay][0][0] === '00:00' &&
                          tempHours[selectedDay][0][1] === '00:00'
                        }
                        onChange={(event) => {
                          console.log(
                            'selected day before change: ',
                            tempHours[selectedDay]
                          );
                          const newTempHours = JSON.parse(
                            JSON.stringify(tempHours)
                          );

                          if (
                            tempHours[selectedDay].length === 1 &&
                            tempHours[selectedDay][0][0] === '00:00' &&
                            tempHours[selectedDay][0][1] === '00:00'
                          ) {
                            newTempHours[selectedDay] = [[':', ':']];
                          } else {
                            newTempHours[selectedDay] = [['00:00', '00:00']];
                          }

                          // Update the state with the new array
                          setTempHours(newTempHours);
                        }}
                      />
                    }
                    label={<Typography sx={labelStyle}>Closed</Typography>}
                  />
                </Box>

                {/* <Box style={{ width: "85%", textAlign: "left", display: "flex", padding: "5px" }}>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker sx={{ ...sx, width: "100px" }} onChange={(time) => console.log(time.format('HH:mm'))} value={getTimeFromJSON(0)}
                    />
                  </LocalizationProvider>

                  <div style={{ width: "20px", height: "2px", marginTop: "30px", marginLeft: "20px", marginRight: "20px", backgroundColor: "gray" }}></div>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker sx={{ ...sx, width: "100px" }} />
                    </DemoContainer>
                  </LocalizationProvider>

                  <IconButton style={{ marginLeft: "10px", marginTop: "px" }}>
                    <CloseIcon style={{ fontSize: '30px', color: "gray" }} />
                  </IconButton>
                </Box> */}

                {(tempHours[selectedDay].length === 1 &&
                  tempHours[selectedDay][0][0] === '00:00' &&
                  tempHours[selectedDay][0][1] === '00:00') ||
                  (tempHours[selectedDay].length === 1 &&
                    tempHours[selectedDay][0][0] === '00:00' &&
                    tempHours[selectedDay][0][1] === '23:59') ||
                  tempHours[selectedDay].map((interval, i) => {
                    let openHoursParsed = interval[0].split(':');
                    let closedHoursParsed = interval[1].split(':');
                    console.log('running beginning code');

                    return (
                      <Box
                        style={{
                          width: '85%',
                          textAlign: 'left',
                          display: 'flex',
                          padding: '5px',
                        }}
                      >
                        <Select
                          label="Hour"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={
                            openHoursParsed[0].length === 0
                              ? ''
                              : parseInt(openHoursParsed[0], 10) % 12 === 0
                                ? 12
                                : parseInt(openHoursParsed[0], 10) % 12
                          }
                          onChange={(event) => {
                            let newHour = event.target.value % 12;
                            if (parseInt(openHoursParsed[0], 10) >= 12)
                              newHour += 12;
                            if (newHour < 10) newHour = `0${newHour}`;

                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][0] =
                              `${newHour}:${openHoursParsed[1]}`;
                            setTempHours(updatedTempHours);
                          }}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                        >
                          {hoursForMenu.map((hourForMenu) => (
                            <MenuItem style={menuItemStyle} value={hourForMenu}>
                              {hourForMenu}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* {Array.from({ length: 12 }, (_, index) => (
                              <MenuItem style={menuItemStyle} key={index} value={(index + 1).toString().padStart(2, '0')}>
                                {(index + 1).toString().padStart(2, '0')}
                              </MenuItem>
                            ))} */}

                        <Select
                          label="Minute"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={openHoursParsed[1]}
                          // onChange={handleHourChange}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                          onChange={(event) => {
                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][0] =
                              `${openHoursParsed[0]}:${event.target.value}`;
                            setTempHours(updatedTempHours);
                          }}
                        >
                          {minutesForMenu.map((minuteForMenu) => (
                            <MenuItem
                              style={menuItemStyle}
                              value={minuteForMenu}
                            >
                              {minuteForMenu}
                            </MenuItem>
                          ))}
                        </Select>

                        <Select
                          label="AM/PM"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={
                            openHoursParsed[0].length === 0
                              ? ''
                              : parseInt(openHoursParsed[0], 10) < 12
                                ? 'AM'
                                : 'PM'
                          }
                          // onChange={handleHourChange}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                          disabled={!openHoursParsed[0]}
                          onChange={(event) => {
                            let newHour = parseInt(openHoursParsed[0], 10);
                            if (newHour < 12) newHour += 12;
                            else newHour -= 12;
                            if (newHour < 10) newHour = `0${newHour}`;

                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][0] =
                              `${newHour}:${openHoursParsed[1]}`;
                            setTempHours(updatedTempHours);
                          }}
                        >
                          <MenuItem style={menuItemStyle} value={'AM'}>
                            AM
                          </MenuItem>
                          <MenuItem style={menuItemStyle} value={'PM'}>
                            PM
                          </MenuItem>
                        </Select>

                        <Box
                          id="2222"
                          style={{
                            width: '20px',
                            height: '2px',
                            marginTop: '24px',
                            marginLeft: '20px',
                            marginRight: '20px',
                            backgroundColor: 'gray',
                          }}
                        >
                          {/* <hr id={"3333"} style={{ border: '1px solid #ccc', margin: '20px 0' }} /> */}
                        </Box>

                        <Select
                          label="Hour"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={
                            closedHoursParsed[0].length === 0
                              ? ''
                              : parseInt(closedHoursParsed[0], 10) % 12 === 0
                                ? 12
                                : parseInt(closedHoursParsed[0], 10) % 12
                          }
                          onChange={(event) => {
                            let newHour = event.target.value % 12;
                            if (parseInt(closedHoursParsed[0], 10) >= 12)
                              newHour += 12;
                            if (newHour < 10) newHour = `0${newHour}`;

                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][1] =
                              `${newHour}:${closedHoursParsed[1]}`;
                            setTempHours(updatedTempHours);
                          }}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                        >
                          {hoursForMenu.map((hourForMenu) => (
                            <MenuItem style={menuItemStyle} value={hourForMenu}>
                              {hourForMenu}
                            </MenuItem>
                          ))}
                        </Select>

                        {/* {Array.from({ length: 12 }, (_, index) => (
                              <MenuItem style={menuItemStyle} key={index} value={(index + 1).toString().padStart(2, '0')}>
                                {(index + 1).toString().padStart(2, '0')}
                              </MenuItem>
                            ))} */}

                        <Select
                          label="Minute"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={closedHoursParsed[1]}
                          // onChange={handleHourChange}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                          onChange={(event) => {
                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][1] =
                              `${closedHoursParsed[0]}:${event.target.value}`;
                            setTempHours(updatedTempHours);
                          }}
                        >
                          {minutesForMenu.map((minuteForMenu) => (
                            <MenuItem
                              style={menuItemStyle}
                              value={minuteForMenu}
                            >
                              {minuteForMenu}
                            </MenuItem>
                          ))}
                        </Select>

                        <Select
                          label="AM/PM"
                          renderValue={(selected) => (
                            <div style={menuItemStyle}>{selected}</div>
                          )}
                          value={
                            closedHoursParsed[0].length === 0
                              ? ''
                              : parseInt(closedHoursParsed[0], 10) < 12
                                ? 'AM'
                                : 'PM'
                          }
                          // onChange={handleHourChange}
                          // variant="outlined"
                          sx={{ ...timeSx }}
                          disabled={!closedHoursParsed[0]}
                          onChange={(event) => {
                            let newHour = parseInt(closedHoursParsed[0], 10);
                            if (newHour < 12) newHour += 12;
                            else newHour -= 12;
                            if (newHour < 10) newHour = `0${newHour}`;

                            // Create a new array with the updated hour
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay][i][1] =
                              `${newHour}:${closedHoursParsed[1]}`;
                            setTempHours(updatedTempHours);
                          }}
                        >
                          <MenuItem style={menuItemStyle} value={'AM'}>
                            AM
                          </MenuItem>
                          <MenuItem style={menuItemStyle} value={'PM'}>
                            PM
                          </MenuItem>
                        </Select>

                        <IconButton
                          style={{
                            width: '40px',
                            height: '40px',
                            marginLeft: '10px',
                            borderRadius: '50%',
                          }}
                          onClick={() => {
                            const updatedTempHours = JSON.parse(
                              JSON.stringify(tempHours)
                            ); // Deep copy, so inefficient. However, there's not that much data anyways.
                            updatedTempHours[selectedDay].splice(i, 1);
                            setTempHours(updatedTempHours);
                          }}
                        >
                          <CloseIcon
                            style={{ fontSize: '30px', color: 'gray' }}
                          />
                        </IconButton>
                      </Box>
                    );
                  })}

                {/* Blank row begins here */}

                <Box
                  style={{
                    width: '85%',
                    textAlign: 'left',
                    display: 'flex',
                    padding: '5px',
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{ ...addTagStyle, height: '22px' }}
                    onClick={() => {
                      const newTempHours = JSON.parse(
                        JSON.stringify(tempHours)
                      );

                      if (
                        (tempHours[selectedDay].length === 1 &&
                          tempHours[selectedDay][0][0] === '00:00' &&
                          tempHours[selectedDay][0][1] === '00:00') ||
                        (tempHours[selectedDay].length === 1 &&
                          tempHours[selectedDay][0][0] === '00:00' &&
                          tempHours[selectedDay][0][1] === '23:59')
                      ) {
                        newTempHours[selectedDay] = [];
                      }
                      newTempHours[selectedDay].push([':', ':']);

                      setTempHours(newTempHours);

                      console.log(getTimeFromJSON(0));
                    }}
                  >
                    <span style={addTagTextStyle}>Add hours</span>
                  </Button>

                  <Button
                    variant="outlined"
                    style={{ ...addTagStyle, height: '22px' }}
                    onClick={() => {
                      handleHoursClose();
                      setTempHours(
                        verifiedHours === '' && resourceData.hours
                          ? resourceData.hours
                          : verifiedHours !== ''
                            ? verifiedHours
                            : testHours
                      );
                      // setTempHours(testHours);
                      setSelectedDay('monday');
                      console.log('tempHours cancelled to ', tempHours);
                      // for (const day in daysOfTheWeek) {
                      //   addAutoElementIfNeeded(day); // Access the value for each day
                      // }
                    }}
                  >
                    <span style={addTagTextStyle}>Cancel</span>
                  </Button>

                  <Button
                    variant="text"
                    style={{ ...addTagStyle, height: '22px' }}
                    onClick={verifyHours}
                  >
                    <span style={addTagTextStyle}>Done</span>
                  </Button>
                </Box>

                {/* Luke's Addition:
                  tempHours[day].map((hour, i) => {
                    return (
                      value=hour.split(':')[0]
                      id=`hour-interval-${i}`
                    )
                  }) */}

                {/* <Select sx={{ ...timeSx }}
                    value={resourceData?.address?.state}
                    placeholder={t('select a state')}
                    onChange={handleNestedInputChange('address', 'state')}>
                    {Array.from({ length: 12 }, (_, index) => (
                      <MenuItem style={menuItemStyle} key={index} value={(index + 1).toString().padStart(2, '0')}>
                        {(index + 1).toString().padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </Select>


                  <Select
                    label="Hour"
                    value={hour}
                    onChange={handleHourChange}
                    // variant="outlined"
                    sx={{ ...timeSx }}
                  >
                    {Array.from({ length: 12 }, (_, index) => (
                      <MenuItem style={menuItemStyle} key={index} value={(index + 1).toString().padStart(2, '0')}>
                        {(index + 1).toString().padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    label="Minute"
                    select
                    value={minute}
                    onChange={handleMinuteChange}
                    variant="outlined"
                    style={{ marginRight: 8 }}
                  >
                    {Array.from({ length: 60 }, (_, index) => (
                      <MenuItem key={index} value={index.toString().padStart(2, '0')}>
                        {index.toString().padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    label="AM/PM"
                    select
                    value={ampm}
                    onChange={handleAmPmChange}
                    variant="outlined"
                  >
                    <MenuItem value="AM">AM</MenuItem>
                    <MenuItem value="PM">PM</MenuItem>
                  </TextField> */}
              </Box>
            </Dialog>
          </>
        ) : (
          <Box style={{ height: '40px' }}>{/* <Skeleton /> */}</Box>
        )}
      </Container>
      <Footer />
    </>
  );
};

export { ResourceEditor };
