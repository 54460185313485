import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// TOCHECK: Icon?
export function Pills({
  useBackground,
  variant,
  text, // icon,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`pills ${useBackground || 'pills--no-background'} pills--${variant}`}
    >
      <div className="pills--icon-wrapper">
        {/* {icon ? <TagIcon tag={icon.toLowerCase()} />: ''} */}
      </div>
      {t(text)}
    </div>
  );
}

Pills.propTypes = {
  /**
   * Which variation of the pill do you wish to be displayed?
   */
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'general'])
    .isRequired,
  /**
   * What message do you want to display?
   */
  text: PropTypes.string.isRequired,
  /**
   * Do you want to display the background color?
   */
  useBackground: PropTypes.bool,

  icon: PropTypes.string,
};

Pills.defaultProps = {
  useBackground: true,
  icon: 'time-outline',
};
