import React from 'react';
import { useTranslation } from 'react-i18next';

export const PopupText = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="heading-secondary location-modal__heading-secondary">
        {t('Share your location')}
      </h2>
      <div className="location-modal__body">
        <p>{t('Welcome to the Florida Community Resource Map')}!</p>
        <p>
          {t(
            'By sharing your location, we can provide users with a better tailored experience, finding resources and services specific to your geographic area along with specific directions to each location.'
          )}
        </p>
      </div>
    </>
  );
};

export const SecondPopupText = () => {
  const { t } = useTranslation();

  return [
    <>
      <h2 className="heading-secondary welcome-modal__heading-secondary">
        {t('Welcome to the Florida Resource Map')}
      </h2>
      <div className="welcome-modal__body">
        <p>
          {t(
            'At the Florida Community Innovation Foundation, we prioritize the security and privacy of our users. Your location data is anonymized to enhance privacy and we obtain your explicit consent before collecting and using your location information.'
          )}
        </p>
      </div>
    </>,
    <>
      <h2 className="heading-secondary welcome-modal__heading-secondary">
        {t('Add a Resource')}
      </h2>
      <div className="welcome-modal__body">
        <p>
          {t(
            "Create an account to add resources that you'd like to share with the community."
          )}
        </p>
      </div>
    </>,
    <>
      <h2 className="heading-secondary welcome-modal__heading-secondary">
        {t('Welcome to the Florida Resource Map')}
      </h2>
      <div className="welcome-modal__body">
        <p>{t('This is the third page.')}</p>
        <p>{t('Take it for a spin and let us know what you think.')}</p>
      </div>
    </>,
  ];
};
