import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SecondPopupText } from '../../resources/text';
import { Button, IndicatorDots } from '../';
import modalImage from '../../resources/LocationModal-cover.png';
import chevronLeft from '../../resources/chevron-left-circle.svg';
import chevronRight from '../../resources/chevron-right-circle.svg';
import '../../styles/main.css';
import { useTranslation } from 'react-i18next';

export const WelcomeModal = ({ pages, image, onVisible }) => {
  const { t } = useTranslation();

  const [modalPage, setModalPage] = useState(0);
  const page = pages === 0 ? pages : pages - 1;

  // FUNCTION
  const modalRightHandler = () => {
    if (modalPage < page) {
      setModalPage(modalPage + 1);
    }
  };

  const modalLeftHandler = () => {
    if (modalPage > 0) {
      setModalPage(modalPage - 1);
    }
  };
  const visibleHandler = () => {
    onVisible();
    localStorage.setItem('welcomeModalSeen', '1');
  };

  return (
    <>
      <div className="welcome-modal__backdrop"></div>
      <div className="welcome-modal__card">
        <img alt="" className="welcome-modal__img" src={image} />

        <div className="welcome-modal__content">
          <>
            <span onClick={modalLeftHandler}>
              <img
                alt=""
                className="welcome-modal__chevron welcome-modal__chevron--left"
                src={chevronLeft}
              />
            </span>
            <span onClick={modalRightHandler}>
              <img
                alt=""
                className="welcome-modal__chevron welcome-modal__chevron--right"
                src={chevronRight}
              />
            </span>
            <div className="welcome-modal__main">
              {SecondPopupText()[modalPage]}
            </div>
            <span className="welcome-modal__indicator-dots">
              <IndicatorDots indicator={modalPage} numElements={pages} />
            </span>
            <div className="welcome-modal__buttons-wrapper--secondary">
              <Button
                variant="secondary"
                onClick={visibleHandler}
                size="small"
                label={t('Skip')}
              />
              {modalPage === page ? (
                <Button
                  size="medium"
                  label={t('Close')}
                  onClick={visibleHandler}
                />
              ) : (
                <Button
                  size="medium"
                  label={t('Next')}
                  onClick={modalRightHandler}
                />
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

WelcomeModal.propTypes = {
  primary: PropTypes.bool,
  pages: PropTypes.number,
  visible: PropTypes.bool,
  onVisible: PropTypes.func,
  /**
   * This takes an SVG or Image component
   */
  image: PropTypes.string,
};

WelcomeModal.defaultProps = {
  primary: true,
  pages: 3,
  visible: true,
  image: modalImage,
};
