import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { currentPageActions } from '../../store/currentPage-slice';
import { Pagination } from '@mui/material';

export const Pagination1 = ({ totalPages, refetch, setActive }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.currentPage.page);

  const handleChange = (event, page) => {
    dispatch(currentPageActions.pageHandler(page));
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', page);
    const newParams = searchParams.toString();
    window.history.pushState(null, null, '?' + newParams);
    setActive(-1);
    refetch(page);
  };
  return (
    <div className="search-page__pagination">
      <Pagination
        count={totalPages}
        page={currentPage ? parseInt(currentPage) : 1}
        color="primary"
        variant="outlined"
        shape="rounded"
        size="large"
        onChange={handleChange}
        sx={{
          'Button.MuiPaginationItem-rounded.Mui-selected': {
            bgcolor: '#00233D',
            color: 'white',
          },
          'Button.MuiPaginationItem-rounded': { borderRadius: '12px' },
          'Button.MuiPaginationItem-previousNext': { border: 'none' },
        }}
      />
    </div>
  );
};

Pagination1.propTypes = {
  totalPages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  setActive: PropTypes.func.isRequired,
};
