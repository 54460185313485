import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon/Icon';

/** Example Usage:
 *        Required Props: label, all other props can be defined as needed
 *        <Button
            variant={'tertiary'}
            label={'Button Label'}
            onClick={() => {alert('Hello')}}
            iconName={'External-Link'}
            hasLightIcon={false}
            hasIconStyle={true}
            iconSize={'large'}
            isDisabled={false}
          />
 */

export function Button({
  variant = 'primary',
  size = 'large',
  label,
  type = 'button',
  onClick,
  isDisabled = false,
  iconName,
  hasLightIcon = true,
  hasIconStyle = true,
  iconSize = 'small',
  ...props
}) {
  // Dynamic button styling via SASS classes and props
  const buttonStyleClasses = [
    'button',
    `button__${variant}`,
    `button__${size}`,
    isDisabled ? 'button__disabled' : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonStyleClasses}
      disabled={isDisabled}
      {...props}
    >
      {iconName && (
        <Icon
          name={iconName}
          isLightIcon={hasLightIcon}
          hasIconStyle={hasIconStyle}
          size={iconSize}
        />
      )}
      {label}
    </button>
  );
}

Button.propTypes = {
  /**
   * Style of button to display.
   */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'error',
    'circle',
    'square',
  ]).isRequired,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * The type of button you want
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Determines if the button should be set to disabled to prevent user interaction
   */
  isDisabled: PropTypes.bool,
  /**
   * Name of the Icon svg stored in /resources small or large
   */
  iconName: PropTypes.string,
  /**
   * Color of the Icon
   */
  hasLightIcon: PropTypes.bool,
  /**
   * Style of Icon present
   */
  hasIconStyle: PropTypes.bool,
  /**
   * Size of Icon to be used
   */
  iconSize: PropTypes.oneOf(['small', 'large']),
};
