import React, { useState } from 'react';
import {
  Accordion,
  Footer,
  Button,
  GraphicButton,
  Header,
  LocationModal,
  WelcomeModal,
  Icon,
} from '../../components';
import '../../styles/main.css';
import categories from './categories.json';
import accordion from './accordion.json';
import { useNavigate } from 'react-router-dom';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [locationModal, setLocationModal] = useState(true);
  const [visible, setvisible] = useState(
    !localStorage.getItem('welcomeModalSeen')
  );
  const [locationPermission, setlocationPermission] = useState(
    !!localStorage.getItem('locationPermissionGranted') ||
      !!localStorage.getItem('locationPermissionDenied')
  );

  const locationModalHandler = () => {
    setLocationModal(false);
  };
  const visibleHandler = () => {
    setvisible(false);
  };

  return (
    <>
      {!locationPermission && locationModal && (
        <LocationModal
          locationModalHandler={locationModalHandler}
          setlocationPermission={setlocationPermission}
        />
      )}
      {visible && (!locationModal || locationPermission) && (
        <WelcomeModal onVisible={visibleHandler} pages={2} />
      )}
      <div className="landing">
        <Header landing />
        <div className="heading-primary">{t('Or, start with a category')}</div>
        <div className="landing__categories">
          {categories.map((category, index) => (
            <GraphicButton
              key={index}
              onClick={() => {
                const tags =
                  category.title === 'Health'
                    ? ['health', 'mental health']
                    : [category.title];
                const encodedTags = tags
                  .map((tag) => encodeURIComponent(tag))
                  .join('&tag=');

                navigate(`search?page=1&distance=any&tag=${encodedTags}`, {
                  state: { selectedTags: tags },
                });
              }}
              title={t(category.title)}
              graphic={category.graphic}
              content={t(category.content)}
            />
          ))}
        </div>
        <div className="landing__accordion-container">
          <div className="landing__about-heading landing__accordion-container heading-primary">
            {t('About the Florida Resource Map')}
          </div>
          <Accordion data={accordion} />
        </div>
        <Footer />
      </div>
    </>
  );
}
