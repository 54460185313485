import { createSlice } from '@reduxjs/toolkit';
const filterInputsSlice = createSlice({
  name: 'filterInputs',
  initialState: { filters: {} },
  reducers: {
    handlefilters: (state, action) => {
      state.filters = action.payload;
    },
    changefilters: (state, action) => {
      for (let key in state.filters) {
        state.filters[key] = false;
      }
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});
export const filterInputsActions = filterInputsSlice.actions;
export default filterInputsSlice.reducer;
