import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/main.css';
import IonIcon from '@reacticons/ionicons';
import { Button } from '../Button/Button';
import { SearchSuggestions } from '../SearchSuggestions/SearchSuggestions';
// import DummyData from '../../resources/Dummy.json';
import { enteredTermActions } from '../../store/enteredTerm-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { currentPageActions } from '../../store/currentPage-slice';
import { useDebounce } from '@uidotdev/usehooks';
import { coordsActions } from '../../store/coords-slice';
import { useTranslation } from 'react-i18next';
import fetch from 'node-fetch';

SearchBar.propTypes = {
  setIsSearching: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
};
export function SearchBar({ setIsSearching, currentPage }) {
  const { t } = useTranslation();

  // STATES
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  // These states capture input text
  const enteredLocation = useSelector((state) => state.enteredLocation.input);
  const enteredTerm = useSelector((state) => state.enteredTerm.input);
  const locationCoords = useSelector((state) => state.coords.coordinates);

  // These states look at whether an input is focused
  const [termFocus, setTermFocus] = useState(false);
  const [locationFocus, setLocationFocus] = useState(false);
  const [termInput, setTermInput] = useState(enteredTerm ? enteredTerm : '');
  const [locationInput, setLocationInput] = useState(
    queryParams.has('address') ? queryParams.get('address') : ''
  );
  const [firstLoad, setFirstLoad] = useState(true);
  const debouncedEnteredLocation = useDebounce(locationInput, 1200);

  // This state stores data to be passed to suggestions
  const [suggestions, setSuggestions] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);

  // VARIABLES
  // Use these variables to specify what characters you'd like to filter in your inputs
  const termValidator = /[^a-zA-Z0-9\s]/gi;
  const locationValidator = /[^a-zA-Z0-9,-.\s]/gi;

  useEffect(() => {
    setLocationInput(enteredLocation);
  }, [enteredLocation]);

  // FUNCTIONS
  /*
   * These functions handle and filter data based on inputs and are passed
   * into their respective suggestion components as props
   */
  const fetchSuggestions = async (location) => {
    const isAllDigits = /^\d+$/.test(location);
    const url = isAllDigits
      ? `https://nominatim.openstreetmap.org/search?format=json&postalcode=${location}&country=us`
      : `https://nominatim.openstreetmap.org/search?format=json&countrycodes=us&q=${location}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.length !== 0) {
        setSuggestions(data);
        if (firstLoad && locationInput === data[0].display_name) {
          dispatch(
            coordsActions.logCoords({
              lat: Number(data[0].lat),
              lng: Number(data[0].lon),
            })
          );
        }
      } else {
        setSuggestions([]);
      }
      setFirstLoad(false);
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation:',
        error
      );
    }
  };

  useEffect(() => {
    if (debouncedEnteredLocation !== '') {
      fetchSuggestions(debouncedEnteredLocation);
    } else {
      setSuggestions([]);
      // dispatch(coordsActions.logCoords(undefined));
    }
  }, [debouncedEnteredLocation]);
  useEffect(() => {
    // Function to hide suggestions when clicking outside
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest('.search-suggestions') &&
        !event.target.classList.contains('no-close')
      ) {
        setTermFocus(false);
        setLocationFocus(false);
      }
    };

    // Adding the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // const filterFunc = (database, searchTerm) => {
  //     const newFilter = database.filter((value) =>
  //         value.title.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredData(newFilter);
  // };

  const termChangeHandler = (event) => {
    const searchTerm = event.target.value.replace(termValidator, '');
    setTermInput(searchTerm);
    // filterFunc(DummyData, searchTerm);
  };

  const locationChangeHandler = (event) => {
    const searchTerm = event.target.value.replace(locationValidator, '');
    setLocationInput(searchTerm);
    // filterFunc(DummyData, searchTerm);
  };

  /* This function prevents the suggestions from closing when
   *  clicked on by looking for a "no-close" class on them
   */
  // const showSuggestionHandler = (event) => {
  //     if (!event.target.classList.contains('no-close')) {
  //         setTermFocus(false);
  //         setLocationFocus(false);
  //     }
  // };

  // This function outputs inputted text in an object and clears the inputs
  const submitHandler = (event, isCurrent) => {
    console.log('here');
    if (event) {
      event.preventDefault();
      if (locationCoords)
        dispatch(enteredLocationActions.handleLocationInput(locationInput));
      dispatch(enteredTermActions.handleSearchInput(termInput));
    }
    if (isCurrent) {
      dispatch(coordsActions.logCoords(undefined));
    }

    if (
      window.location.pathname === '/' ||
      window.location.pathname.startsWith('/resource')
    ) {
      console.log('inside window.location print statement');

      console.log('iniside terminput if statement');
      navigate(
        `/search?page=1&distance=any${`&search=${termInput}`}${
          locationCoords && `&address=${JSON.stringify(locationCoords)}`
        }`,
        {
          state: 1,
        }
      );
    } else if (window.location.pathname === '/search') {
      console.log('here inside search case');
      dispatch(currentPageActions.pageHandler(1));
      setIsSearching(true);
    }
    // setTermFocus(false);
    // setLocationFocus(false);
  };

  return (
    <div className="u-relative">
      <form
        onSubmit={submitHandler}
        className={`${currentPage}-searchbar u-flexbox ${
          enteredTerm.length !== 0 && termFocus
            ? `${currentPage}-searchbar-focus`
            : ''
        }`}
      >
        <div className={`${currentPage}-searchbar-inputs u-flexbox`}>
          <div className={`${currentPage}-searchbar-inputs__left`}>
            <input
              type="text"
              className={`${currentPage}-searchbar-input no-close`}
              placeholder={t('Enter key phrase')}
              value={termInput}
              onChange={termChangeHandler}
              onFocus={() => {
                setTermFocus(true);
                setLocationFocus(false);
              }}
            />
            <label className={`${currentPage}-searchbar-text`}>
              {t('Find')}
            </label>
            {termInput && (
              <button
                type="button"
                className={`${currentPage}-searchbar-close`}
                onClick={() => {
                  setTermInput('');
                  dispatch(enteredTermActions.handleSearchInput(''));
                  if (window.location.pathname === '/search')
                    submitHandler(undefined, false);
                }}
              >
                <IonIcon name={t('close')} />
              </button>
            )}
            <IonIcon
              className={`${currentPage}-searchbar__icon`}
              name="search"
            />
          </div>
          <div className={`${currentPage}-searchbar-separator`}>&nbsp;</div>
          <div
            className={`${currentPage}-searchbar-inputs__right ${currentPage}-searchbar-inputs__visible`}
          >
            <input
              type="text"
              className={`${currentPage}-searchbar-input no-close`}
              placeholder={t('Enter zip code')}
              value={locationInput}
              onChange={locationChangeHandler}
              onFocus={() => {
                setLocationFocus(true);
                setTermFocus(false);
              }}
            />
            <label className={`${currentPage}-searchbar-text`}>
              {t('Near')}
            </label>
            {(locationInput || enteredLocation) && (
              <button
                type="button"
                className={`${currentPage}-searchbar-close`}
                onClick={() => {
                  setLocationInput('');
                  dispatch(enteredLocationActions.handleLocationInput(''));
                  dispatch(coordsActions.logCoords(undefined));
                  setSuggestions([]);
                  if (window.location.pathname === '/search')
                    submitHandler(undefined, false);
                }}
              >
                <IonIcon name={t('close')} />
              </button>
            )}
            <IonIcon
              className={`${currentPage}-searchbar__icon`}
              name="navigate-outline"
            />
          </div>
        </div>
        <div className={`${currentPage}-searchbar-button`}>
          <Button
            label={t('Search')}
            type="submit"
            icon="search"
            variant="primary"
          />
        </div>
      </form>
      {/* {enteredTerm.length !== 0 && termFocus && (
                  <SearchSuggestions
                      className="no-close"
                      term={enteredTerm}
                      searchTerm={filteredData}
                  />
              )} */}
      {locationFocus && (
        <SearchSuggestions
          className="no-close"
          location
          setLocationFocus={setLocationFocus}
          submitHandler={submitHandler}
          searchTerm={suggestions}
          term={enteredLocation}
        />
      )}
    </div>
  );
}
