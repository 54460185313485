import React, { useState } from 'react';
import { Grid, Paper, TextField, Box } from '@mui/material';
import { Button } from '../../components/Button/Button'; // Your custom button component
import { axiosPrivate } from '../../apis/backend';
import Alert from '../../components/Alert/Alert';

function VerificationPage() {
  const [errObject, setErrObject] = useState(undefined);
  const [formData, setFormData] = useState({
    verificationCode: '',
  });

  const handleConfirm = () => {
    const actualCode = sessionStorage.getItem('verificationCode');
    console.log(actualCode);
    console.log(formData.verificationCode);
    if (formData.verificationCode === actualCode) {
      sessionStorage.setItem('isVerified', true);
      window.location.href = '/dashboard';
    } else {
      setErrObject({
        title: 'An error occurred during form submission:',
        message: 'The provided verification code is invalid.',
      });
    }
  };
  const handleReturn = () => {
    window.location.href = '/auth/signin/';
  };
  const handleInputChange = (e) => {
    console.log('Before update:', formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      verificationCode: e.target.value,
    }));
    console.log('After update:', formData);
  };
  const handleResendCode = async () => {
    // Call the sendData function to make the POST request
    try {
      const email = window.sessionStorage.getItem('email');
      console.log(window.sessionStorage.getItem('email'));
      const newCode = await axiosPrivate.post('/auth/signIn/resendCode', {
        email,
      });
      console.log(newCode);
      if (!newCode.data.payload.nextCodeIn) {
        window.sessionStorage.setItem('verificationCode', newCode.data.code);
        setErrObject({
          title: 'Verification code has been resent',
          message:
            'A new verification code has been sent to ' +
            window.sessionStorage.getItem('email') +
            '.',
        });
      } else {
        setErrObject({
          title: 'An error occurred during form submission:',
          message:
            'You can request another code in ' +
            newCode.data.payload.nextCodeIn +
            ' seconds, please try again later.',
        });
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error.message);
      // Handle the error as needed
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={2}
        sx={{
          margin: '50px',
          padding: '32px',
          maxWidth: '500px',
          width: '100%',
          borderRadius: '1rem',
          boxSizing: 'border-box',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                fontSize: '2.4rem',
                fontWeight: 700,
                textAlign: 'center',
                marginBottom: '1rem',
              }}
            >
              Check for Verification Code
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                fontSize: '1.6rem',
                textAlign: 'center',
                marginBotton: '1.8rem',
              }}
            >
              A 6-digit code has been sent to your email.
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                {errObject && (
                  <Grid item xs={12} paddingBottom={3}>
                    <Alert data={errObject} />
                  </Grid>
                )}
                <TextField
                  label=""
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: '1.2rem',
                      fontSize: '1.8rem',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#00233D',
                        borderWidth: '2px',
                      },
                    },
                  }}
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="primary"
                onClick={handleConfirm}
                label="Confirm"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                variant="link"
                onClick={handleReturn}
                to="/"
                sx={{ minWidth: '150px' }}
                label="Back"
              />
              <Button
                variant="link"
                onClick={handleResendCode}
                to="/"
                sx={{ minWidth: '150px' }}
                label="Resend code"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default VerificationPage;
